import React, { useState, useEffect } from 'react'
import { Button } from 'antd'

import 'antd/lib/button/style/css'
import { APiOrderContainer } from './Api_orders/Styles/Styles'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchPageProps } from '../../../Redux'


export default function Api_orders() {

    const [id, setId] = useState(0)
    const dispatch = useDispatch()

    const sendId = () => {
        if (id !== 0 && id !== "") {
            document.getElementById("linkToApi").click();
        }
        else {
            alert("Introduzca su id")
            document.getElementById("inputId").focus()
        }
    }

    useEffect(() => {

        dispatch(fetchPageProps({ titlePage: "Api pedidos" }))


    }, [])

    return (
        <APiOrderContainer>
            <div>
                <h1>ID del pedido</h1>
            </div>
            <form onSubmit={(e)=>{e.preventDefault(); sendId()}}>
                <input name="id" id="inputId" type="text" placeholder="Indroduzca su ID" onChange={(e) => { setId(e.target.value) }} />
                <Button type="primary" id="btnConsultOrder" onClick={() => { sendId() }}>Consultar</Button>
                <Link to={`/admin/registro/${id}`} id="linkToApi" style={{ display: "none" }}></Link>
            </form>
        </APiOrderContainer>
    )
}
