import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { dir_api_name, host } from '../../../api_vars';
import { fetchBreadcumb } from '../../../Redux';
import BreadcrumCmnt from '../../BreadcrumCmnt';
import { checkRol } from '../../Util/checkRol';
import { TableOrdersHeader } from '../Purchases/List_orders/Style/Styles';
import TableRoutesBodyPrint from './DeliveryManRoutes/TableRoutesBodyPrint';


const DeliveryManRoutesContainer = styled.section`
    padding: 5vh 0px;
`

export default function DeliveryManRoutes() {

    const [routes, setRoutes] = useState([{
        id: ""
    }])
    const dispatch = useDispatch()
    
    const [rol, setRol] = useState(0)

    const { id } = useParams()

    const consultRoutes = () => {
        const data = `user_id=${id}`;
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);
        const miInit = {
            method: 'POST',
            headers: headers,
            body: data,
            mode: 'cors'
        };

        return fetch(host + dir_api_name + '/admin/components/delivery/api_consult_delivery_routes.php', miInit).then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw res
            }
        })

    }

    useEffect(() => {
        consultRoutes().then((res) => {
            setRoutes(res)
        }).catch((err) => {
            message.error(err)
        })

        const breadCrumb = [
            { title: "Domicilio", link: "/admin/delivery_man/"+id, disabled: true },
            { title: "Domiciliarios", link: rol > 2 ? "/admin/delivery_men/" : "/admin/delivery_men/"+id, disabled: false },
            { title: "Rutas", link: "/admin/delivery_man/"+id, disabled: true }
        ]

        dispatch(
         fetchBreadcumb(breadCrumb)
        )

        const token = JSON.parse(localStorage.getItem('session') ? localStorage.getItem('session') : '{"token": ""}').token

        checkRol(token).then(res => {

            setRol(Number(res))

        }).catch(err => console.error(err))

    }, [])

    const cellsProps = {
        id: {
            visible: true,
            width: "5%"
        },
        state: {
            visible: true,
            width: "30%"
        },
        date_creation: {
            visible: true,
            width: "12%"
        },
        date_delivered: {
            visible: true,
            width: "13%"
        }
    }
    return (
        <DeliveryManRoutesContainer>
            <BreadcrumCmnt />

            <TableOrdersHeader className="flex">
                    {
                        cellsProps.id.visible ?
                            <div style={{ width: cellsProps.id.width }}>
                                <p>ID</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.date_creation.visible ?
                            <div style={{ width: cellsProps.date_creation.width }}>
                                <p>Fecha de creación</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.date_delivered.visible ?
                            <div style={{ width: cellsProps.date_delivered.width }}>
                                <p>Fecha de entrega</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.state.visible ?
                            <div style={{ width: cellsProps.state.width }}>
                                <p>Estado</p>
                            </div>
                            : null
                    }
                </TableOrdersHeader>

            <TableRoutesBodyPrint routes={routes} cells={cellsProps} id={id}/>
        </DeliveryManRoutesContainer>
    )
}
