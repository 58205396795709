import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoadingStatus, fetchOrderFilter } from '../../../../../../../../Redux'
import { useEffect } from 'react'
import moment from 'moment'
import { DownOutlined } from '@ant-design/icons';
import {
    TreeOrientations
} from './../../../../Style/Styles'
import CitiesNode from './CitiesNode'
import { message, Button } from 'antd'
export default function OrientationsNode({ title, fetchOrders, openNotification, fetchTotal }) {




    const [collapse, setCollapse] = useState(true)
    const [total, setTotal] = useState(0)
    const [cities, setCities] = useState([])
    const orientation = title[0].replace("\"", "").replace("\"", "")
    const filters = useSelector(state => state.orders_filter)


    const setCitiesActions = (res) => {

        setCities(res)


        // dispatch(fetchLoadingStatus({}, "Set to 100%"))

        setTimeout(() => {

            // dispatch(fetchLoadingStatus({}, "Off"))

        }, 1000)
    }

    useEffect(() => {


        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_consult_city.php?date=${date_delivery}&orientation=${orientation}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=orientation&optionValue=${orientation}&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let reloadDataInterval

        clearInterval(reloadDataInterval !== undefined ? reloadDataInterval : null)

        reloadDataInterval = setInterval(() => {


            if (filters.reload && filters.viewType === "ordered_list" && filters.reloadTreeOrders) {


                fetchTotal(totalQuery).then((res) => {


                    if (res > total || res < total) {

                        fetchOrders(query).then((res) => {

                            setCitiesActions(res)

                        }).catch(err => {

                            openNotification(`Error cargando datos ref: ${err}`, "error")

                        })

                    }

                    setTotal(res)

                }).catch(err => {

                    openNotification(`Error cargando datos ref: ${err}`, "error")

                })

                // dispatch(fetchLoadingStatus({}, "On"))

            }

        }, 10000)


        return () => clearInterval(reloadDataInterval)

    }, [filters, orientation])

    useEffect(() => {

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_consult_city.php?date=${date_delivery}&orientation=${orientation}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=orientation&optionValue=${orientation}&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        if (filters.reload && filters.viewType === "ordered_list") {

            message.destroy()

            fetchOrders(query).then((res) => {

                setCitiesActions(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })

            fetchTotal(totalQuery).then((res) => {

                setTotal(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })


            // dispatch(fetchLoadingStatus({}, "On"))

        }

    }, [filters])

    const collapseOrientationsSection = (e) => {
        let className = e.target.className
        if (className === "Tree-header flex orientation") {
            setCollapse(!collapse)
        }
    }

    return (

        <TreeOrientations key={title} className={collapse ? "collapsed" : ""} onClick={(e) => collapseOrientationsSection(e)}>

            <div className="Tree-header flex orientation">
                <div className="Tree-header-title">
                    {orientation} | <b> Total : {total} </b>
                </div>
                <div>
                    <DownOutlined className="DownBtn" />
                </div>
            </div>

            <div className="Tree-body">

               {
                    cities.map((city, key) =>

                        <CitiesNode
                            title={city}
                            fetchOrders={fetchOrders}
                            openNotification={openNotification}
                            orientation={orientation}
                            fetchTotal={fetchTotal}
                        />

                    )

                }

            </div>

        </TreeOrientations>
    )
}