import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoadingStatus, fetchOrderFilter } from '../../../../../../../../Redux'
import { useEffect } from 'react'
import moment from 'moment'
import { DownOutlined,SwapLeftOutlined } from '@ant-design/icons';
import {
    TreeCity
} from './../../../../Style/Styles'
import NeighborhoodNode from './NeighborhoodNode'

import randomColor from 'randomcolor'
import { message, Button } from 'antd'


export default function CitiesNode({ title, fetchOrders, openNotification, orientation, fetchTotal }) {

    const [collapseCitie, setCollapseCitie] = useState(false)
    const [neighborhoods, setNeighborhoods] = useState([])
    const city = unescape(title[0].replace("\"", "").replace("\"", ""))
    const filters = useSelector(state => state.orders_filter)
    const [total, setTotal] = useState(0)

    const [sectionColor, setsectionColor] = useState(randomColor() + "5b")

    const dispatch = useDispatch()

    const setNeighborhoodsActions = (res) => {

        setNeighborhoods(res)


        // dispatch(fetchLoadingStatus({}, "Set to 100%"))

        setTimeout(() => {

            // dispatch(fetchLoadingStatus({}, "Off"))

        }, 1000)
    }


    const errorMessage = (text) => {
        message.error(text, 0);
    };

    useEffect(() => {

        let city = unescape(title[0].replace("\"", "").replace("\"", ""))

        // dispatch(fetchLoadingStatus({}, "On"))

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_consult_neighborhood.php?date=${date_delivery}&orientation=${orientation}&city=${((city))}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=city&optionValue=${(city)}&orientation=${orientation}&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let reloadDataInterval

        clearInterval(reloadDataInterval !== undefined ? reloadDataInterval : null)

        reloadDataInterval = setInterval(() => {

            if (filters.reload && filters.viewType === "ordered_list" && filters.reloadTreeOrders) {


                fetchTotal(totalQuery).then((res) => {

                    setTotal(res)

                    if (res > total || res < total) {

                        fetchOrders(query).then((res) => {

                            setNeighborhoodsActions(res)


                        }).catch(err => {

                            openNotification(`Error cargando datos ref: ${err}`, "error")

                        })

                    }

                }).catch(err => {

                    openNotification(`Error cargando datos ref: ${err}`, "error")

                })


                // dispatch(fetchLoadingStatus({}, "On"))

            }

        }, 10000)


        return () => clearInterval(reloadDataInterval)

    }, [filters, title, orientation])


    useEffect(()=>{
        let city = unescape(title[0].replace("\"", "").replace("\"", ""))

        // dispatch(fetchLoadingStatus({}, "On"))

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_consult_neighborhood.php?date=${date_delivery}&orientation=${orientation}&city=${((city))}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=city&optionValue=${((city))}&orientation=${orientation}&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        if (filters.reload && filters.viewType === "ordered_list") {

            message.destroy()

            fetchOrders(query).then((res) => {

                setNeighborhoodsActions(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })

            fetchTotal(totalQuery).then((res) => {

                setTotal(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })


            // dispatch(fetchLoadingStatus({}, "On"))

        }
    },[filters])

    const collapseCitysSection = (e) => {
        let className = e.target.className
        if (className === "Tree-header flex city") {
            setCollapseCitie(!collapseCitie)
        }
    }

    return (

        <TreeCity key={title} className={collapseCitie ? "collapsed" : ""} onClick={(e) => collapseCitysSection(e)}>

            <div className="Tree-header flex city" style={{ backgroundColor: sectionColor }}>
                <div className="Tree-header-title">
                    <SwapLeftOutlined className={collapseCitie ? "treeLine collapseIcon" : "treeLine"}/> {orientation} - {city} | <b>Total : {total}</b>
                </div>
                <div>
                    <DownOutlined className="DownBtn" />
                </div>
            </div>

            <div className="Tree-body">
                {
                    neighborhoods.map((neighborhood, key) =>
                        <NeighborhoodNode key={
                            neighborhood[key]
                        }
                            title={
                                neighborhood
                            }
                            fetchOrders={
                                fetchOrders
                            }
                            city={
                                city
                            }
                            orientation={
                                orientation
                            }
                            openNotification={
                                openNotification
                            }
                            sectionColor={
                                sectionColor
                            }
                            fetchTotal={
                                fetchTotal
                            }
                        />
                    )
                }
            </div>

        </TreeCity>
    )
}