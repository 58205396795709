import React, { useState, useEffect } from 'react'
import { message, TreeSelect } from 'antd';


import 'antd/lib/tree-select/style/css'
import { dir_api_name, host } from '../../../../../api_vars';


const { TreeNode } = TreeSelect;

export default function SearchItem({ setCart, Cart }) {

    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)

    const [productSelected, setProductSelected] = useState("")

    const onChange = value => {
        console.log(value);
    };


    const consultProducts = () => {
        const url = host + dir_api_name + "/admin/components/purchases/add_order/api_consult_products.php"

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).then((res) => {
            setProducts(res)
            setLoading(false)
        })
            .catch((err) => console.error(err))
    }

    useEffect(() => {
        consultProducts()
    }, [])



    return (
        <TreeSelect
            showSearch
            style={{ width: '100%', marginTop: "2vh" }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Buscar producto:"
            onSelect={onChange}
            id="userData-location"
            value={productSelected}
        >
            {
                loading ?
                    <TreeNode value="Default" title="Cargando..." selectable={false}></TreeNode>
                    :
                    products.map(
                        (item, key) =>
                            <TreeNode value={`${item.id} ${item.descripcion}`} title={<p onClick={() =>
                                setCart((prevState) => {
                                    return {
                                        ...prevState,
                                        [`${item.lineas}`]: [
                                            ...prevState[`${item.lineas}`],
                                            {
                                                TypeOfCut: "",
                                                TypesOfCut: item.presentacion,
                                                name: item.descripcion,
                                                price: item.offer === "1" ? item.offer_price : item.precio_publico,
                                                initial_price: item.precio_publico,
                                                quantity: 1,
                                                offer: item.offer,
                                                offer_price: item.offer_price,
                                                unit: item.unidad_medida,
                                                disabled: true
                                            }
                                        ]
                                    }
                                })
                            }>
                                {item.descripcion}
                            </p>} selectable={true}>
                            </TreeNode>
                    )
            }
        </TreeSelect>
    );

}