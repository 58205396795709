import React from 'react'

//compontent Ant.desing
import { Breadcrumb } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import 'antd/lib/breadcrumb/style/css'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 *
 * @const styles - default styles
 * @const breadcrumbList - list of items in breadcrumb loaded fron Redux (store.Ui)
 * @param stylesIncome - Styles depending on where the component loaded
 */

function BreadcrumCmnt({ stylesIncome }) {

    const styles = {
        ...stylesIncome,
        textAlign: "left"
    }
    const breadcrumbList = useSelector(state => state.breadCumb)

    return (

        <Breadcrumb style={styles}>

            <Breadcrumb.Item href="">
                <Link to="/"><HomeOutlined /></Link>
            </Breadcrumb.Item>

            {
                breadcrumbList.map((item, index) =>
                    <Breadcrumb.Item>
                        <Link to={item.link} style={item.disabled ? { cursor: "default" } : null}><span>{item.title}</span></Link>
                    </Breadcrumb.Item>
                )
            }

        </Breadcrumb>

    )
}


export default BreadcrumCmnt
