import React, { useState } from 'react'

//Style

import 'antd/lib/form/style/css'

//Assests

import { MailOutlined } from '@ant-design/icons';
import { Input, Space, AutoComplete, Button, Form, Select, message } from 'antd';
import { dir_api_name, host } from '../../../api_vars';
import { SignInContainer } from './AddUser/AddUserStyle';

const { Option } = Select;

export default function AddUser() {

    const [dataSource, setDataSource] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)


    const [form] = Form.useForm();
    const [inputValidate, setinputValidate] = useState({
        password: {
            status: null,
            msg: " "
        },
        email: {
            status: null,
            msg: " "
        },
        username: {
            status: null,
            msg: " "
        }
    })
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 4,
                offset: 0,
            },
        },
    };


    const handleChange = value => {

        setinputValidate({ ...inputValidate, email: { status: null, msg: " " } })

        setDataSource(
            !value || value.indexOf('@') >= 0
                ? []
                : [`${value}@gmail.com`, `${value}@hotmail.com`, `${value}@outlook.com`],

        );

    };

    const checkPassword = (e) => {
        let value = e.target.value

        if (value.length < 8) {
            setinputValidate({ ...inputValidate, password: { status: "error", msg: "Su contraseña debe de tener mínimo 8 caracteres" } })
        } else {
            setinputValidate({ ...inputValidate, password: { status: "success", msg: " " } })
        }
    }

    const consultUsername = async (username) => {

        let usernameValid
        const data = `username=${username}`;
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);
        const miInit = {
            method: 'POST',
            headers: headers,
            body: data,
            mode: 'cors'
        };

        setinputValidate({ ...inputValidate, username: { status: "validating" } })

        await fetch(host + dir_api_name + '/admin/components/users/signin/tools/checkUserAvailability.php', miInit).then((res) => {
            if (res.ok) {
                return res.text()
            } else {
                throw res
            }
        }).then((res) => {
            if (res === username) {
                setinputValidate({ ...inputValidate, username: { status: "error", msg: "Usuario no disponible" } })
                usernameValid = false
            } else {
                setinputValidate({ ...inputValidate, username: { status: "success", msg: " " } })
                usernameValid = true
            }
        }).catch((err) => console.log(err))

        return new Promise((resolve, reject) => {

            usernameValid ? resolve(usernameValid) : reject(usernameValid)

        })

    }

    const register = (value) => {

        const form = value


        const registerUser = async () => {
            
            const user_type = "admin";
            const data = `form=${encodeURI(JSON.stringify(form))}&user_type=${user_type}`;


            const headers = new Headers();

            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            headers.append('Origin', host);

            const miInit = {
                method: 'POST',
                headers: headers,
                body: data,
                mode: 'cors'
            };

            let user


            await fetch(host + dir_api_name + '/admin/components/users/signin/registerUser.php', miInit).then((res) => {

                if (res.ok)
                    return res.json()

                else
                    throw res

            }).then((res) => {

                user = res

                if(res.response){
                    message.success("Usuario añadido")
                }else{
                    message.error("Error: " + res.message);
                }
                setLoadingBtn(false)

                // setTimeout(()=>{
                //     window.location.reload()
                // }, 2000)

            }).catch((err) => {

                message.error("Error: " + err.message);

                setLoadingBtn(false)

            })

            return new Promise((resolve, reject) => {

                resolve(user)

            })

        }

        if (form.email !== undefined) {

            consultUsername(form.username).then(() => {

                // console.log("Username valido")

                setLoadingBtn(true)

                registerUser()

            }).catch((err) => {
                console.log("Error de consulta " + err)
            })

        }

    }

    const formInvalid = (err) => {

        err.errorFields.forEach((item, index) => {

            if (item.name[0] === "email") {

                setinputValidate((prevState) => {
                    return { ...prevState, email: { status: "error", msg: "Por favor ingrese su email" } }
                })

            } else if (item.name[0] === "username") {


                setinputValidate((prevState) => {
                    return { ...prevState, username: { status: "error", msg: "Por favor introduzca su nombre de usuario" } }
                })

            } else if (item.name[0] === "password") {
                setinputValidate((prevState) => {
                    return { ...prevState, password: { status: "error", msg: "Por favor introduzca su contraseña" } }
                })
            }
        })
    }

    return (


        <SignInContainer>

            <h1>Registrar Usuario</h1>

            <Space direction="vertical">

                <Form form={form} onFinishFailed={formInvalid} onFinish={register}>

                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor introduzca el nombre!',
                                whitespace: true,
                            },
                        ]}
                    >

                        <Input size="large" placeholder="Nombre *" />

                    </Form.Item>


                    <Form.Item
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor introduzca el apellido!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Apellido *" />
                    </Form.Item>

                    <Form.Item
                        name="cellphone"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor introduzca el número de celular!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Celular *" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor introduzca el email!',
                                whitespace: true,
                            },
                        ]}
                        help={inputValidate.email.msg}
                        validateStatus={inputValidate.email.status}
                    >
                        <AutoComplete
                            dataSource={dataSource}
                            onChange={handleChange}
                            style={{ width: 100 + "%" }}
                            placeholder="Email *"
                            size="large"
                            prefix={<MailOutlined />}
                            className="input_email_footer"
                        />
                    </Form.Item>


                    <Form.Item
                        name="access_level"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor introduzca el tipo de usuario!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Select placeholder="Tipo de usuario">

                            <Option value="0">Despacho</Option>
                            <Option value="1">Asesor</Option>
                            <Option value="2">Logistica</Option>
                            <Option value="3">Administrador</Option>

                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="dispatch_point_id"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor introduzca el punto de venta!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Select placeholder="Punto de venta">

                            <Option value="0">Placita de Flórez</Option>
                            <Option value="1">Copacabana</Option>

                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor introduzca el nombre de usuario!',
                                whitespace: true,
                            },
                        ]}
                        help={inputValidate.username.msg}
                        validateStatus={inputValidate.username.status}
                    >
                        <Input size="large" placeholder="Nombre de usuario *" onChange={() => {
                            setinputValidate({
                                ...inputValidate,
                                username: {
                                    status: null,
                                    msg: " "
                                }
                            })
                        }} />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor introduzca la contraseña',
                            },
                        ]}
                        help={inputValidate.password.msg}
                        validateStatus={inputValidate.password.status}
                        hasFeedback
                    >
                        <Input.Password placeholder="Contraseña" size="large" onChange={checkPassword} />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Por favor confirme la contraseña!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject('Las dos contraseñas no coinciden!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Confirma la contraseña" size="large" minLength="8" />
                    </Form.Item>


                    <Form.Item {...tailFormItemLayout}>
                        <Button className="signInBtn" htmlType="submit" loading={loadingBtn}>Registrar usuario</Button>
                    </Form.Item>

                </Form>

            </Space>



        </SignInContainer>

    )
}
