import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { dir_api_name, host } from '../../../api_vars'
import { StateBtn, StateCircle } from './DeliveryStyles/DeliveryStyles'
import { UserOutlined } from '@ant-design/icons';
import BreadcrumCmnt from '../../BreadcrumCmnt'
import { fetchBreadcumb, fetchPageProps } from '../../../Redux'
import { useDispatch } from 'react-redux'

//Components
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { Card, CardActionArea } from '@material-ui/core'

const DeliveryMenContainer = styled.section`
    padding: 5vh 0px;
    width: 88vw;
    margin-left: 1vw;
    .delivery_men{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
        &-cart{
            background-color: #fbe9e7;
            /* box-shadow: 0px 0px 2px rgba(0,0,0,0.2); */
            /* border: 1px #7777774b solid; */
            border-radius: 4px;
            text-align: center;
            width: 250px;
            margin-top: 20px;
            a{
                color: black;
            }
            &-header{
                text-align: center;
                margin-bottom: 10px;
                &-state_text{
                    padding-left: 4px;
                }
            }
            &-logo{
                border-bottom: 1px #7777772b solid;
                /* box-shadow: 0px 0px 1px rgba(0,0,0,0.2); */
                /* padding: 20px 20px; */
                margin-bottom: 20px;
                .delivery_man_logo{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height:150px;
                    font-size: 80px;
                }
            }
            &-name{
                font-size: 18px;
                font-style: italic;
                font-weight: 700;
                padding-top: 10px;
                p{
                    margin-bottom: 0.5em;
                }
            }
            &-options{
                button{
                    width: 100%;
                    padding: 5px;
                    color: white;
                    border-radius: 1px;
                    border: none;
                    font-size: 12px;
                    a{
                        color: white;
                    }
                }
                button:last-child{
                    margin-top: 5px;
                    cursor: pointer;
                }
                &-look_routes{
                    background-color: #40A9FF;
                }
            }
        }
    }

`

export default function DeliveryMen() {

    const [delivery_men, setDelivery_men] = useState([{
        id: "",
        user_data: {
            name: "Cargando ..."
        },
        img: "",
        state: "En Ruta"
    }])

    const dispatch = useDispatch()

    const fetchDeliveryMen = () => {

        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);

        const miInit = {
            method: 'POST',
            headers: headers,
            mode: 'cors'
        };

        return fetch(host + dir_api_name + '/admin/components/delivery/api_consult_delivery_men_data.php', miInit).then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw res
            }
        })

    }

    useEffect(() => {

        fetchDeliveryMen().then((res) => {

            setDelivery_men([...res])

            console.log(res)

        }).catch((err) => {

            setDelivery_men((prevState => { return [...prevState, { name: "Error: " + err }] }))

        })

    }, []);


    const DeliveryManCart = ({ item, index }) => {

        const [state, setState] = useState("Disponible")


        const ConsultUserState = () => {
            const data = `user_id=${item.id}`;
            const headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            headers.append('Origin', host);
            const miInit = {
                method: 'POST',
                headers: headers,
                body: data,
                mode: 'cors'
            };
            return fetch(host + dir_api_name + '/admin/components/delivery/api_consult_delivery_man_state.php', miInit).then((res) => {
                if (res.ok) {
                    return res.text()
                } else {
                    throw res
                }
            })

        }

        useEffect(() => {

            if (item.id !== "") {

                ConsultUserState().then((res) => {

                    setState(res)

                }).catch((err) => {

                    message.error("Error " + err)

                })

            }

        }, [])


        return (
            <Card className="delivery_men-cart" key={item.id}>
                <Link to={"/admin/delivery_man/" + item.id}>
                    <CardActionArea>
                        <div className="delivery_men-cart-logo">

                            <UserOutlined className="delivery_man_logo" />

                        </div>

                        <div className="delivery_men-cart-name">

                            <p>
                                {item.user_data.name}
                            </p>

                        </div>
                        <div className="delivery_men-cart-header">
                            <StateCircle state={state}>
                            </StateCircle>
                            <span className="delivery_men-cart-header-state_text">{state}</span>
                        </div>
                        <div className="delivery_men-cart-options">




                            <Button startIcon={<SearchIcon />} className="delivery_men-cart-options-look_routes">


                                Ver rutas


                        </Button>

                        </div>
                    </CardActionArea>
                </Link>
            </Card>
        )
    }

    useEffect(() => {

        dispatch(fetchPageProps({ titlePage: "Añadir Compra" }))
        const breadCrumb = [
            { title: "Domicilio", link: "/admin/product_list/", disabled: true },
            { title: "Domiciliarios", link: "/admin/product_list/", disabled: true }
        ]

        dispatch(fetchBreadcumb(breadCrumb))
    }, [])
    return (

        <DeliveryMenContainer>
            <BreadcrumCmnt />


            <div className="delivery_men">
                {
                    delivery_men.map((item, index) =>

                        <DeliveryManCart item={item} index={index} />
                    )
                }
            </div>
        </DeliveryMenContainer>
    )
}
