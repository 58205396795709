/**
 * @constant Menu - OPtions about type of cut
 */
import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import PresentationItem from './MenuOverlay/PresentationItem'

export default function MenuOverlay({ checked, setChecked, visible, presentationsFromDB, UpdatePresentation, itemId, meatName, indexProduct }) {

    const [PresentationsList, SetPresentationsList] = useState(presentationsFromDB === null ? [
        { "name": "Porcionado 5xLb", "selectable": false },
        { "name": "Porcionado 4xLb", "selectable": false },
        { "name": "Porcionado 3xLb", "selectable": false },
        { "name": "Porcionado 2xLb", "selectable": false },
        { "name": "Entero", "selectable": false },
        { "name": "Molido", "selectable": false },
        { "name": "Mariposa", "selectable": false },
        { "name": "Goulash", "selectable": false },
        { "name": "Medallones", "selectable": false },
    ] : [
            ...presentationsFromDB
        ])

    const changePortionedStatus = (e, index) => {

        PresentationsList[index].selectable = !PresentationsList[index].selectable

        UpdatePresentation(meatName, indexProduct, PresentationsList, itemId)

    }

    return (
        <Menu>
            {
                visible ?
                    PresentationsList.map((item, index) =>
                        <PresentationItem key={index} item={item} index={index} changePortionedStatus={changePortionedStatus}></PresentationItem>
                    )
                    : null
            }
        </Menu>
    )
}