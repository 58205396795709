import React from 'react';
import ReactDOM from 'react-dom';
import './dist/css/main.min.css'
import Routes from './Routes/Routes';
import { Provider } from 'react-redux';
import store from './Redux/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routes/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);