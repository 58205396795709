const ordersProps = [
    {
        date_creation: "2020-12-15",
        date_delivery: null,
        datos_usuario: '{"id": "", "tel": "", "cell": "", "city": "", "date": "2020-12-15", "hour": "", "name": " ", "am_pm": "", "email": "", "minute": "", "address": "Calle 31b #89d25 ", "commune": "Las Violetas", "password": null, "username": "Yennymile", "addressID": "Medellín Belén Las Violetas", "last_name": "", "idDirection": "267", "orderStatus": "3", "orientation": "Sur", "neighborhood": "Belén", "payment_state": true, "invoice_number": 1, "payment_method": "Efectivo", "point_reference": "Frente a la cede comunal "}',
        delivery_route_id: "0",
        dispatch_point_id: null,
        id: "12135",
        pedido: `{"notes": "", "notesAsesor": "", "delivery_price": "5000", "discount": 100}`,
        position: "0",
        time_creation: "6:54",
        total: "1",
        user_admin_id: "0",
        user_id: "673",
    }
];

export { ordersProps }