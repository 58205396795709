import React, { useState, useEffect } from 'react'
import { Popover, Badge, Input, Button } from 'antd';
import { dir_api_name, host } from '../../../../api_vars'
import { InputOrderLimitContainer } from './Styles'
import { useSelector, useDispatch } from 'react-redux';
import { fetchCalendarData } from '../../../../Redux';

export default function DateCellRender({ date }) {
    const [modalVisible, setModalVisible] = useState({ visible: false })
    const handleOk = e => {
        setModalVisible({ visible: e })
    };

    const [btnStatus, setBtnStatus] = useState(true)
    const dispatch = useDispatch()
    const [limit, setLimit] = useState(0)

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);
    const miInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
    };

    function getListData(limit) {

        return { type: `success`, content: `0` };

    }

    const listData = getListData(limit);

    const changeLimitState = (value) => {


        setLimit(value)

        if (value >= 0) {

            setBtnStatus(false)

        } else {

            setBtnStatus(true)

        }
    }

    const setLimitInDb = () => {

        let url = host + dir_api_name + '/api_setOrdersLimit.php'
        let dateLocal = date.format("YYYY-MM-DD")
        let limitInputValue = document.getElementById("limit" + dateLocal).value
        let query = `?date=${dateLocal}&limit=${limitInputValue}`
        fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.text();
            }

            else { throw response };
        }).catch((err) => console.error(err)).then(() => {
            dispatch(fetchCalendarData({
                loading: false
            }))
            setBtnStatus(true)
        })

    }

    const InputOrdersLimit = () => {
        return (
            <InputOrderLimitContainer>
                <Input type="number" placeholder="Limite" id={"limit" + date.format("YYYY-MM-DD")} value={limit} onChange={e => changeLimitState(e.target.value)} />
                <Button type="primary" className="changeLimitBtn" disabled={btnStatus} onClick={() => setLimitInDb()}>Establecer</Button>
            </InputOrderLimitContainer>
        )
    }

    return (
        <Popover
            title={"Disponibilidad el " + date.format("YYYY-MM-DD")}
            trigger="click"
            visible={modalVisible.visible}
            onVisibleChange={handleOk}
            content={InputOrdersLimit}
        >
            <ul className="events">

                <li key={listData.content}>
                    <Badge status={listData.type} text={listData.content} id={"badge" + date.format("YYYY-MM-DD")} />
                </li>

            </ul>

        </Popover>

    )
}
