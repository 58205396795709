import React from 'react'
import TableOrdersBodyItemRow from './Components/TableOrdersBodyItemRow'
import { useSelector } from 'react-redux'
import TreeOrdersBodyItemRow from './Components/TreeOrdersBodyItemRow'




// import 'antd/lib/checkbox/style/css'




export default function TableOrdersBodyPrint({ orders, cells, dataType, controlsUpDown, partent }) {

    const orders_filter = useSelector(state => state.orders_filter)
    const ordersToUpdate = useSelector(state => state.ordersToUpdate.orders)

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {
                orders_filter.viewType === "last_added" || dataType === "json" ? partent === "multiple_choice" ? ordersToUpdate.map((item, index) =>

                    <TableOrdersBodyItemRow item={item} cellsProps={cells} dataType={dataType} index={index} controlsUpDown={controlsUpDown} parentComponent={partent} />

                )
                    :
                        orders.map((item, index) =>

                            <TableOrdersBodyItemRow item={item} cellsProps={cells} dataType={dataType} index={index} controlsUpDown={controlsUpDown} />

                        )
                    : 

                    <TreeOrdersBodyItemRow orders={orders} cells={cells} dataType={dataType}  />

            }

        </div>

    )

}
