import styled from "styled-components";

const RetailAddUserContainer = styled.section`
    h1{
        font-size: 24px;
        margin-bottom: 2rem;
    }
    width: 50%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
    padding: 15px 20px;
    border-radius: 7px;
    background: #ececec;
    .signInBtn{
        margin: auto;
        margin-top: 2vh;
        margin-bottom: 2vh;

    }
    .ant-select-selection-placeholder{
        text-align: left !important;
    }
`


export {
    RetailAddUserContainer
}