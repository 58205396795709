import React from 'react'
import { Route, Switch } from 'react-router-dom';

// Compontents
import Admin from '../Components/Admin/Products/Products';
import API_Cart from '../Components/Admin/Purchases/Api_orders/API_Cart';
import Api_orders from '../Components/Admin/Purchases/Api_orders';
import List_orders from '../Components/Admin/Purchases/List_orders';
import AddProduct from '../Components/Admin/Products/AddProduct';
import DateAvailability from '../Components/Admin/Purchases/DateAvailability';
import MinimumOrderPrice from '../Components/Admin/Purchases/MinimumOrderPrice';
import AddOrder from '../Components/Admin/Purchases/AddOrder';
import AddUser from '../Components/Admin/Users/AddUser';
import Users from '../Components/Admin/Users/Users';
import ProductStats from '../Components/Admin/Products/ProductStats';
import PurchasesTimeLine from '../Components/Admin/Purchases/PurchasesStats/PurchasesTimeLine.js';
import PurchasesUbicationsChart from '../Components/Admin/Purchases/PurchasesStats/PurchasesUbicationsChart';
import DeliveryMen from '../Components/Admin/Delivery/DeliveryMen';
import DeliveryManRoutes from '../Components/Admin/Delivery/DeliveryManRoutes';
import DeliveryManRoute from '../Components/Admin/Delivery/DeliveryManRoute';
import Retail from '../Components/Admin/Retail/RetailAddUser';
import RetailAddUser from '../Components/Admin/Retail/RetailAddUser';

export default function RoutesAdmin() {


    return (
        <Switch>

            {/* Products */}

            <Route path="/admin/product_list/" component={Admin}></Route>
            <Route path="/admin/add_product/" component={AddProduct}></Route>
            <Route path="/admin/products_stats/" component={ProductStats}></Route>

            {/* Products */}

            {/* Purchases */}
            <Route path="/admin/api_orders/" component={Api_orders}></Route>
            <Route path="/admin/registro/:id" component={API_Cart}></Route>
            <Route path="/admin/date_availability/" component={DateAvailability}></Route>
            <Route path="/admin/table_orders/:page" component={List_orders}></Route>
            <Route path="/admin/add_order/" component={AddOrder}></Route>
            <Route path="/admin/minimun_order_price/" component={MinimumOrderPrice}></Route>
            <Route path="/admin/purchases_timeline/" component={PurchasesTimeLine}></Route>
            <Route path="/admin/purchases_ubications_chart/" component={PurchasesUbicationsChart}></Route>

            {/* Purchases */}

            {/* Users */}

                <Route path="/admin/routes/route/route_point/:id/:user_id/:route_id/" component={API_Cart}></Route>
                <Route path="/admin/add_user/" component={AddUser}></Route>
                <Route path="/admin/users/" component={Users}></Route>

            {/* Users */}

            {/* Delivery */}

            <Route path="/admin/delivery_men/" component={DeliveryMen}></Route>
            <Route path="/admin/delivery_man/:id" component={DeliveryManRoutes}></Route>
            <Route path="/admin/delivery_route/:id_user/:id_route" component={DeliveryManRoute}></Route>

            {/* Delivery */}

            {/* Retail */}
                <Route path="/admin/add_retail_users/" component={RetailAddUser} />
            {/* Retail */}


        </Switch>
    )
}
