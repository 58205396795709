import React from 'react'
import TableRoutesBodyItemRow from './Components/TableRoutesBodyItemRow'



export default function TableRoutesBodyPrint({ routes, cells, id }) {


    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {
                routes.map((route, index) =>

                    <TableRoutesBodyItemRow route={route} cells={cells} index={index} user_id={id} />

                )
            }
        </div>

    )

}
