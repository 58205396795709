import styled from "styled-components";


const MinimumOrderPriceContainer = styled.section`
    padding-top: 5vh;
    padding-bottom: 5vh;
`
const MinimumOrderPriceSection1 = styled.section`
    margin: auto;
    margin-top: 10%;
    width: 30%;
    padding: 20px;
    box-shadow: 0px 0px 2px #0000003b;
    text-align: left;
    #changePrice{
        margin-top: 10px;
    }
`

export { MinimumOrderPriceContainer, MinimumOrderPriceSection1 }