import React, { useEffect, useState } from 'react'

import LogoPig from './../../../../Assets/pigIcon.png'

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, message, Popconfirm } from 'antd';
import styled from 'styled-components';
import SearchItem from './CartAddOrder/SearchItem';

const CartAddOrderContainer = styled.section`
        @media (max-width: 600px) {
            padding-bottom: 10vh;
            width: 100% !important;
            margin-top: 3vh;
            padding-left: 4vw;
        }
        h2 {
            font-weight: 500;
            color: #212121;
            margin-top: 1vh;
            font-size: 24px;
        }
        .Cart-footer-cart {
            @media (max-width: 600px) {
                h2 {
                    margin-left: 2vw;
                }
            }
        }
        .Cart-footer-cart-body {
                padding-bottom: 10px;
                padding-right: 20px;
                text-align: left;
                input {
                    border: none;
                    border-bottom: 0.5px solid #979797;
                }
                ul {
                    padding-left: 2vw;
                    @media (max-width: 600px) {
                        padding-left: 7vw;
                    }
                }
                ul > li {
                    margin: 9px 0px;
                    margin-top: 14px;
                    font-size: 16px;
                    list-style: none;
                    border-bottom: 1px solid #7070707d;
                    input {
                        margin-top: 2px;
                    }
                }
                .Cart-footer-cart-body-info {
                    color: #616161;
                    font-size: 12px;
                }
            }
        .Cart-footer-summary {
            margin-top: 5vh;
            margin-left: 5vw;
            li {
                font-weight: 600;
                color: #202020;
            }
            p {
                font-weight: 800;
                font-size: 16px;
                color: #202020;
            }
            @media (min-width: 600px) {
                margin-top: 5vh;
                margin-left: 5vw;
            }
        }
    `

const ItemCartContainer = styled.div`

margin-top: 20px;
border: 1px solid #7070702b;
border-radius: 4px;
box-shadow: 0px 0px 4px #7070702b;
padding: 10px;
@media(max-width: 600px){
    width: 100%;
    .cartSummatyDetails-section1{
        display: flex;
        flex-direction: column;
    }
}
}
div:last-child{
    margin-top: 10px;
}

.inptQuantity{
    width: 80px;
    border-radius: 0px;
    margin: 0px 5px;
    border: 1px solid #40a9ff;
    border-radius: 2px;
    box-shadow: 0px 0px 2px #40a9ff;
}
.inptQuantity:disabled{
    border: none;
    border-bottom: 1px solid #707070;
    background-color: #7070702b;
    color: #000 !important;
    box-shadow: 0px 0px 0px #40a9ff;
}
.editBtn{
    margin-left: 5px;
    height: 25px;
    padding: 1px 5px;
    font-size: 14px;
    border-color: #40a9ff;
    color: #40a9ff;
}
.itemTotal{
    font-weight: 700;
    padding: 0px 2px;
}

`

export default function CartAddOrder({ title, Cart, total, setCart, setTotal }) {

    const { TextArea } = Input;



    const updatePriceResume = () => {


        let summaryPig = 0
        let summaryBeef = 0
        let summaryChicken = 0
        let summaryFish = 0
        let summarySausages = 0
        let summaryCravings = 0

        Cart.CERDO.forEach(item => {
            summaryPig += Number(item.price)
        })
        Cart.RES.forEach(item => {
            summaryBeef += Number(item.price)
        })
        Cart.POLLO.forEach(item => {
            summaryChicken += Number(item.price)
        })
        Cart[`PESCADOS Y MARISCOS`].forEach(item => {
            summaryFish += Number(item.price)
        })
        Cart[`CARNES FRIAS`].forEach(item => {
            summarySausages += Number(item.price)
        })
        Cart[`ANTOJOS`].forEach(item => {
            summaryCravings += Number(item.price)
        })


        const total = summaryPig + summaryBeef + summaryChicken + summaryFish + summarySausages + summaryCravings + Number(Cart.delivery_price)

        setTotal(total)

    }

    const DeleteItemCart = (meatType, indexOfItem) => {

        Cart[meatType].splice(indexOfItem, indexOfItem === 0 ? indexOfItem + 1 : indexOfItem)


        setCart(prevState => {
            return {
                ...prevState,
                Cart
            }
        })


    }

    const ChangeQuantityItem = (meatType, indexOfItem, price) => {

        let inpt_quantity = document.getElementById("Quantity-" + meatType + indexOfItem)

        let quantity = inpt_quantity.value

        const initial_price = Cart[meatType][indexOfItem].price / Cart[meatType][indexOfItem].quantity


        // console.log(e)
        if (quantity > 99) {

            quantity = inpt_quantity.value.slice(0, 2);

            return false

        }
        ; // Check if value is less than 99



        if (quantity.indexOf(".") !== -1 && quantity.indexOf(",") !== -1) { // Chack special chars

            alert("No se permiten '.', ni ','")

            quantity = quantity.replace(/[^0-9]/g, "").slice(-1)

            document.getElementById("Quantity-" + meatType + indexOfItem).value = quantity.replace(/[^0-9]/g, "").slice(0, 1)

        }

        if (quantity <= 0 || quantity === "") quantity = 1;

        const finalPrice = initial_price * quantity

        const final_offer_price = Number(Cart[meatType][indexOfItem].offer_price) * Number(quantity)



        Cart[meatType][indexOfItem].price = Cart[meatType][indexOfItem].offer === "1" ? final_offer_price : finalPrice

        Cart[meatType][indexOfItem].initial_price = finalPrice

        Cart[meatType][indexOfItem].quantity = quantity

        setCart(prevState => {
            return {
                ...prevState,
                Cart
            }
        })

        inpt_quantity.focus()

    }


    const ItemCart = ({ item, logo, meatType, index }) => {

        //States



        const editItem = (e) => {

            let btn = e.target


            if (btn.innerHTML === "Editar") {

                Cart[meatType][index].disabled = false

                console.log(Cart)

                setCart(prevState => {
                    return {
                        ...prevState,
                        Cart
                    }
                })

            } else {

                Cart[meatType][index].disabled = true

                setCart(prevState => {
                    return {
                        ...prevState,
                        Cart
                    }
                })

                ChangeQuantityItem(meatType, index, item.price)

            }

        }

        async function changeMeatCut(e, meatType, name) {
            let indexOfItem
            let checkOfCartItem = true
            let TypeOfCut = e.target.value

            const checkCart = Cart[meatType].length > 0 ?
                await Cart[meatType].forEach((item, index) => {
                    if (item.name === name) {// Si es false significa que el item ya está registrado - y se dentendrá el forEach
                        indexOfItem = index
                        checkOfCartItem = false
                        return false
                    }
                    return true
                }) : true

            if (!checkOfCartItem) {

                Cart[meatType][indexOfItem].TypeOfCut = TypeOfCut

                setCart(Cart)

                message.success("Actualizado")

            } else {

                return false // SI no está registrado aún no cambiará nada

            }
        }

        const changeNote = (value) => {

            Cart[meatType][index].note = value

        }

        const PresentationsList = [
            { "name": "Porcionado 5xLb", "selectable": true },
            { "name": "Porcionado 4xLb", "selectable": true },
            { "name": "Porcionado 3xLb", "selectable": true },
            { "name": "Porcionado 2xLb", "selectable": true },
            { "name": "Entero", "selectable": true },
            { "name": "Molido", "selectable": true },
            { "name": "Mariposa", "selectable": true },
            { "name": "Goulash", "selectable": true },
        ]

        const CheckTypesOfCut = (TypesOfCut) => {
            TypesOfCut = JSON.parse(TypesOfCut)
            if (TypesOfCut !== "" && Array.isArray(TypesOfCut)) {
                return TypesOfCut
            } else {
                return []
            }
        }

        return (
            <ItemCartContainer key={item.id} className="">
                <div className="cartSummatyDetails-section1">
                    {
                        logo
                    }

                    {" "}

                    {item.name} x

                    <Input type="number"
                        className="inptQuantity"
                        id={
                            "Quantity-" + meatType + index
                        }
                        disabled={
                            item.disabled !== undefined ? item.disabled : true
                        }
                        defaultValue={
                            item.quantity
                        }
                    >
                    </Input>

                    {item.unit}

                    {" "} - {" "}


                    <select onChange={(e) => changeMeatCut(e, meatType, item.name)} value={item.TypeOfCut} id={"TypeOfCut-" + meatType + "-" + item.id}>
                        <option value="">¿Cómo lo quiere?</option>
                        {
                            item.TypesOfCut !== "" ? CheckTypesOfCut(item.TypesOfCut).map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null) :
                                PresentationsList.map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null)
                        }
                    </select> 

                </div>

                <div className="notes">
                    <b>Nota:</b>
                    {
                        item.disabled !== undefined && item.disabled !== false ?
                            <span>
                                {item.note}
                            </span>
                            : ""
                    }
                    {
                        item.disabled !== undefined && item.disabled !== true ?

                            <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value)}>


                            </TextArea>

                            : null
                    }
                    <Button className="editBtn" type="ghost" style={{ display: item.disabled === undefined || item.disabled ? "" : "none" }} onClick={(e) => editItem(e, meatType, index)}>
                        {item.disabled === undefined || item.disabled ? "Agregar" : null}
                    </Button>
                </div>


                <div>

                    Total : <span className="itemTotal"> {Number(item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</span>


                    <Button className="editBtn" type="ghost" onClick={(e) => editItem(e, meatType, index)}>
                        {item.disabled === undefined || item.disabled ? "Editar" : "Actualizar"}
                    </Button>

                    <DeleteOutlined style={{ color: "#d50000", marginLeft: "10px" }} onClick={() => DeleteItemCart(meatType, index)} />

                </div>

            </ItemCartContainer>
        )

    }


    const PrintItem = ({ meatType, logo }) => {

        return (

            Cart[`${meatType}`].map((item, index) => {

                return (
                    <ItemCart item={item} logo={logo} meatType={meatType} index={index} />
                )
            })
        )
    }

    const clearCart = () => {
        setCart({
            RES: [],
            [`CARNES FRIAS`]: [],
            [`POLLO`]: [],
            [`PESCADOS Y MARISCOS`]: [],
            [`CERDO`]: [],
            [`ANTOJOS`]: [],
            delivery_price: 0,
            discount: 0
        })

        if (localStorage.getItem("cart")) {
            localStorage.removeItem("cart")
        }
    }

    useEffect(() => {

        updatePriceResume()

    }, [Cart])


    return (
        <CartAddOrderContainer className="Cart-footer">
            <section className={"Cart-footer-cart " + title === "Resumen" ? "Cart-footer-summary" : ""}>
                <h2>{title}</h2>
                <div className="Cart-footer-cart-body">
                    <ul>
                        {
                            Cart.CERDO.length === 0 && Cart.RES.length === 0 && Cart.POLLO.length === 0 && Cart[`PESCADOS Y MARISCOS`].length === 0 && Cart[`CARNES FRIAS`].length === 0 && Cart[`ANTOJOS`].length === 0
                                ?
                                <li style={{ color: "#707070" }}>Vacío</li> : null
                        }
                        <PrintItem meatType={"CERDO"} logo={<img src={LogoPig} alt="" style={{ width: "19px" }} />} />
                        <PrintItem meatType={"RES"} logo={<span role="img" aria-label="Res"> 🐮 {" "}</span>} />
                        <PrintItem meatType={"POLLO"} logo={<span role="img" aria-label="Pollo">🐔{" "}</span>} />
                        <PrintItem meatType={"PESCADOS Y MARISCOS"} logo={<span role="img" aria-label="Pescado">🐟{" "}</span>} />
                        <PrintItem meatType={"CARNES FRIAS"} logo={<span role="img" aria-label="bacon">🥓{" "}</span>} />
                        <PrintItem meatType={"ANTOJOS"} logo={<span role="img" aria-label="Pescado">🥚{" "}</span>} />
                        <SearchItem Cart={Cart} setCart={setCart} />
                    </ul>
                    <p style={{ fontSize: "13px" }}>Valor domicilio:
                        {
                            Number(Cart.delivery_price) > 0 ?
                                <b>
                                    {Number(Cart.delivery_price).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                </b>
                                :
                                <b> Seleccione primero una ubicación</b>
                        }
                    </p>
                    <p style={{ fontSize: "16px" }}>Total: <b>{Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
                    <small className="Cart-footer-cart-body-info">Los valores de esta cotización son aproximados, pueden variar por su peso exacto al momento de la liquidación.</small>
                </div>
                <Popconfirm placement="bottom" title={"Desea vasear el carrito?"} onConfirm={clearCart} okText="Si" cancelText="No">
                    <Button type="ghost" style={{ borderColor: "#d50000", color: "#d50000" }}>Vaciar carrito</Button>
                </Popconfirm>
            </section>
        </CartAddOrderContainer>

    )
}