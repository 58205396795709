import styled from "styled-components"

const BoxStyle = styled.section`
    width: ${props => props.width !== undefined ? props.width + "vw" : ""};
    margin: auto;
    margin-top: 0;
    /* margin-top: 4vh; */
    background-color: ${props => props.background === null ? "#eceff1" : props.background};
    padding: 20px 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #0000003d;
`
const AddOrderContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 5vh;
    margin-top: 5vh;
    
`
const AddOrderContainerBody = styled.section`
     display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .ant-select-auto-complete{
        width: 230px;
    }
    .btn-consultId{
        margin-top: 8vh;
        margin-right: auto;
        margin-left: 2vw;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-bottom: 2vh;
        input,
        select {
            width: 230px;
            border-radius: 5px;
            border: 0.5px solid $inputCartColor;
            padding: 7px 7px;
            font-size: 15px;
        }
        #userData-payment_method {
            width: 100%;
        }
        @media (max-width: 600px) {
            width: 100%;
            input {
                width: 85%;
                border: 0.5px solid $inputCartColor;
            }
            #userData-payment_method {
                width: 100%;
            }
        }
    }
    #userData-payment_method {
        width: 20.2%;
        padding: 5px 5px;
    }
    @media (max-width: 600px) {
        #userData-payment_method {
            width: 93%;
        }
    }
    .item {
        width: 230px;
        margin-bottom: 2vh;
        input,
        select {
            width: 230px;
            border-radius: 5px;
            // border: none;
            border: 0.5px solid $inputCartColor !important;
            padding: 7px 7px;
        }
        @media (max-width: 600px) {
            width: 100%;
            input {
                width: 93%;
                border: none;
                border-bottom: 0.5px solid $inputCartColor;
            }
        }
    }
    form {
        margin-left: 3vw;
        margin-top: 2vh;
        .form-title {
            border-bottom: 1px solid #7070704d;
            width: 30%;
        }
        label {
            display: block;
            margin-top: 3vh;
            margin-bottom: 2vh;
        }
    }
    .btnFinishContainer{
        text-align: left;
    }
    #userData-id{
        width: 100%;
    }
    .buyFinishBtn {
        background-color: #ff6d00;
        border: 1px solid #e96503;
        font-family: "Baloo Thambi 2", cursive;
        padding: 10px !important;
        height: auto !important;
        border-radius: 0px 3px 3px 0px;
        color: white;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        margin-top: 4vh;
        transition: all 0.4s;

        @media (max-width: 600px) {
            padding: 10px;
            border-radius: 0px;
            width: 100vw;
        }
    }
    .buyFinishBtn:hover{
        background-color: #ff4d00;
        border-color: #a13000;
    }
`
export {
    BoxStyle,
    AddOrderContainer,
    AddOrderContainerBody
}