import React, { useState, useEffect } from 'react'
import { DateAvailabilityContainer } from './DateAvailability/Styles'
import { Calendar, Badge, Modal, Popover } from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment from 'moment'
import 'moment/locale/es'


import 'antd/lib/calendar/style/css'
import 'antd/lib/badge/style/css'

import 'antd/lib/modal/style/css'
import DateCellRender from './DateAvailability/dateCellRender'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBreadcumb, fetchCalendarData, fetchPageProps } from '../../../Redux'
import { dir_api_name, host } from '../../../api_vars'
import BreadcrumCmnt from '../../BreadcrumCmnt'

export default function DateAvailability() {
  moment.locale("es");

  const [ordersLimit, setOrdersLimit] = useState({
    date: "",
    ordersLimit: 0
  })
  const dispatch = useDispatch()
  const [month, setMonth] = useState(moment().format("MM"))
  const calendar = useSelector(state => state.calendar)
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Origin', host);
  const miInit = {
    method: 'GET',
    headers: headers,
    mode: 'cors',
  };

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  }

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  }

  const DateCellRenderLocal = (value) => {

    const [limit, setLimit] = useState(0)

    const getLimitByDate = () => {

      const dateLocal = value.format("YYYY-MM-DD")
      const url = host + dir_api_name + "/admin/components/purchases/availability/api_consult_availability.php"
      const query = `?date=${dateLocal}`

      return fetch(url + query, miInit).then((response) => {
        if (response.ok) {
          return response.text();
        }

        else { throw response };
      }).catch((err) => console.error(err))
    }

    getLimitByDate().then(

      res => {
        const badgeParent = document.querySelector(`#badge${value.format("YYYY-MM-DD")}`)
        const badgeLimit = document.querySelector(`#badge${value.format("YYYY-MM-DD")} .ant-badge-status-text`)
        if (badgeLimit !== null) {
          if (res !== "") {
            badgeParent.style.display = "block"
            badgeLimit.innerHTML = res
          } else {
            if (badgeParent !== null) {
              badgeParent.style.display = "none"
            }
          }
        }

      }

    )

    console.log("Cambiando" + value.format("YYYY-MM-DD"))

    return <DateCellRender date={value} limit={limit} />

  }

  const selectOrderLimit = (value) => {
    // console.log(value)

    // setOrdersLimit({ ...ordersLimit, date: "" })

    // setModalVisible({...modalVisible, visible: true})

  }

  const changeMonth = (e) => {
    dispatch(
      fetchCalendarData(
        {

          month: e.month(),
          loaded: false

        }
      )
    )
  }

  useEffect(() => {

    dispatch(fetchPageProps({ titlePage: "Disponibilidad" }))
    const breadCrumb = [
      { title: "Compras", link: "/admin/date_availability/", disabled: true },
      { title: "Disponibilidad", link: "/admin/date_availability/", disabled: true }
    ]

    dispatch(fetchBreadcumb(breadCrumb))

  }, [])

  return (
    <DateAvailabilityContainer>
    <BreadcrumCmnt />

      <Calendar dateCellRender={DateCellRenderLocal} monthCellRender={monthCellRender} locale={locale} id={calendar.month} mode="month" fullscreen={true} onSelect={(value) => selectOrderLimit(value)} onPanelChange={(e) => changeMonth(e)} />

    </DateAvailabilityContainer>
  )
}
