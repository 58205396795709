import styled from "styled-components";


const dispached_color = "#40A9FF";
const delivered_color = { light: "#8D47EE", normal: "#6200EA", dark: "#3F206B" };

const RouteBody = styled.section`

`

const RouteContainer = styled.section`
    padding: 2rem 0rem;
`

const RouteHeader = styled.header`
    padding: 2rem 0rem 1rem 0rem;
    text-align: center;
    .route_position{
        width: 2rem;
    }
    .route_name{
        width: 5rem;
    }
    .route_address{
        width: 8rem;
    }
    .route_payment_method{
        width: 2rem;
    }
    .route_total{
        width: 5rem;
    }
`

const RouteItem = styled.div`
    width: 98%;
    margin: 10px auto;
`

const RouteItemBody = styled.div`
    box-shadow: ${props => props.state === "En camino" ? "0px 2px 3px rgba(0, 0, 0, 0.2)" : props.state === "Entregado" ? "0px 3px 6px rgba(98, 0, 234, 0.2)" : ""};
    background-color: ${props => props.state === "Despachado" || props.state === "En camino" ? "#fcfcfc" :
        props.state === "Postergado" ? "#DFDFDF" :
            delivered_color.light
    }; 
    border: 1px solid ${props => props.state === "Entregado" ? delivered_color.light : "#f0f0f0"};
    color:${props => props.state === "Entregado" ? "white" : props.state === "Despachado" ? "#000a12" : "#000a12"};
    min-height: ${props => props.state === "" ? "60px" : "30px"};
    font-weight: ${props => props.state === "" ? "600" : "400"};
    font-size: ${props => props.state === "" ? "16px" : ""};
    transform: ${(props) => props.state === "" ? "scale(1.02,1.05)" : ""};
    margin: auto;
    padding: 0px 10px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    .route_name{
        width: 5rem;
    }
    .route_address{
        width: 12rem;
        overflow-y: hidden;
        text-align: center;

        @media(max-width: 600px){
            width: 8rem;
        }
    }
    .route_position{
        width: 2rem;
    }
    .route_address::-webkit-scrollbar{
        display: none;
    }
    .route_total{
        color: #00c853;
        width: 5rem;
    }
    .route_payment_method{
        width: 2rem;
    }
`

const RouteItemControls = styled.div`
    justify-content: flex-end;
    button{
        color: white;
        border-radius: 1px;
        border: none;
        font-size: 14px;
        a{
            color: white;
        }
        cursor: pointer;
    }
    .linkBtn{
        width: 50px;
        padding: 2px;
        margin-left: 5px;
        background-color: ${dispached_color}
    }
    .postponeDelivertBtn{
        background-color: #4f5b62;
        margin-left: 5px;
    }
    .deliverBtn{
        background-color: ${delivered_color.normal};
    }
    .deliverBtn:disabled{
        background-color: ${delivered_color.light};
        cursor: not-allowed;
    }
`

const StateLine = styled.div`
    margin: ${props => props.type === "delivered" ? "0px auto 1rem auto" : "1rem auto 0rem auto"};
    background-color: ${props => props.type === "delivered" ? delivered_color.light : dispached_color};
    width: 5px;
    height: 20px;
`
const RouteControls = styled.section`
    
`
export {
    RouteBody,
    RouteItem,
    RouteItemBody,
    RouteItemControls,
    StateLine,
    RouteContainer,
    RouteHeader,
    RouteControls
}