import React, { useState } from 'react'

import moment from 'moment'

import { useSelector, useDispatch } from 'react-redux';

import OrientationsNode from './Components/TreeOrdesTable/OrientationsNode';

import {
    TreeOrdersBodyItemRowContainer,
} from './../../Style/Styles'

import { useEffect } from 'react';
import { fetchOrderFilter } from '../../../../../../Redux';


import { Skeleton, notification, message } from 'antd';
import 'antd/lib/skeleton/style/css'
import { host, dir_api_name } from '../../../../../../api_vars';
import OrdersOldVersion from './Components/TreeOrdesTable/OldVersionNode';

export default function TreeOrdersBodyItemRow({ orders, cells, dataType }) {

    const loadingStatus = useSelector(state => state.loadingStatus)
    const filters = useSelector(state => state.orders_filter)

    const dispatch = useDispatch()

    const [orientations, setOrientations] = useState([])
    const [total, setTotal] = useState(0)

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);

    const miInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
    };

    const openNotification = (title, type) => {
        let message
        const notificationSucess = (message) => {
            notification.success({
                message: message,
                description: title,
                placement: "bottomRight",
                className: "notifications"
            });
        }

        const notificationDeleted = (message) => {
            notification.destroy()
            notification.error({
                message: message,
                description: title,
                placement: "bottomRight",
                className: "notifications"
            })
        }



        switch (type) {
            case "actualizando":
                message = "Actualizando pedido"
                notificationSucess(message)
                break;

            case "actualizado":
                message = "Actualizado!"
                notificationSucess(message)
                break;
            case "enviando":
                message = "Enviando email!"
                notificationSucess(message)
                break;
            case "enviado":
                message = "Email enviado !"
                notificationSucess(message)
                break;

            case "error":
                message = "Error!"
                notificationDeleted(message)
                break;

            default:
                return false;
        }


    };

    const fetchOrders = (query) => {

        return fetch(`${host}${dir_api_name}/${query}
        `, miInit).then((response) => {

            if (response.ok) {
                return response.json();
            }

            else { throw response };

        })
    }

    const fetchTotal = (query) => {

        return fetch(`${host}${dir_api_name}/${query}
        `, miInit).then((response) => {

            if (response.ok) {
                return response.text();
            }

            else { throw response };

        })
    }


    const setOrientationsActions = (res) => {

        setOrientations(res)


        // dispatch(fetchLoadingStatus({}, "Set to 100%"))

        setTimeout(() => {

            // dispatch(fetchLoadingStatus({}, "Off"))

        }, 1000)

    }

    useEffect(() => {

        // dispatch(fetchLoadingStatus({}, "On"))

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")


        let query = `tableOrders/views/ordered/api_consult_orientations.php?date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`
        let totalQuery = `api_consult_orders.php?type=totalOrders&state=${filters.state}&date_delivery=${filters.date_delivery}&payment_method=${filters.type_payment}&keywords=${filters.keywords}`

        let reloadDataInterval

        clearInterval(reloadDataInterval !== undefined ? reloadDataInterval : null)

        reloadDataInterval = setInterval(() => {

            if (filters.reload && filters.viewType === "ordered_list") {




                fetchTotal(totalQuery).then((res) => {


                    if (res !== total) {

                        dispatch(
                            fetchOrderFilter({
                                reloadTreeOrders: true
                            })
                        )

                        fetchOrders(query).then((res) => {

                            setOrientationsActions(res)

                        }).catch(err => {

                            openNotification(`Error cargando datos ref: ${err}`, "error")

                        })

                    } else if (res === total) {

                        dispatch(

                            fetchOrderFilter({
                                reloadTreeOrders: false

                            })

                        )

                    }

                    setTotal(res)

                }).catch(err => {

                    openNotification(`Error cargando datos ref: ${err}`, "error")

                })

                // dispatch(fetchLoadingStatus({}, "On"))




                // dispatch(fetchLoadingStatus({}, "On"))

            }

        }, 10000)


        return () => clearInterval(reloadDataInterval)

    }, [filters])

    useEffect(() => {

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")


        let query = `tableOrders/views/ordered/api_consult_orientations.php?date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`
        let totalQuery = `api_consult_orders.php?type=totalOrders&state=${filters.state}&date_delivery=${filters.date_delivery}&payment_method=${filters.type_payment}&keywords=${filters.keywords}`

        if (filters.reload && filters.viewType === "ordered_list") {

            message.destroy()

            fetchOrders(query).then((res) => {

                setOrientationsActions(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })

            fetchTotal(totalQuery).then((res) => {

                setTotal(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })


            // dispatch(fetchLoadingStatus({}, "On"))

        }

    }, [filters])


    useEffect(() => {
        if (filters.date_delivery === "") {
            dispatch(
                fetchOrderFilter({
                    date_delivery: moment().format("YYYY-MM-DD")
                })
            )
        }
    }, [])
    return (

        <TreeOrdersBodyItemRowContainer>

            {
                loadingStatus ? orientations.map((orientation, index) =>

                    orientation[index] !== null ?
                        <OrientationsNode
                            title={orientation}
                            fetchOrders={fetchOrders}
                            fetchTotal={fetchTotal}
                            openNotification={openNotification}
                        />
                        : null


                )
                    :
                    <Skeleton active />

            }

            <OrdersOldVersion
                fetchOrders={fetchOrders}
                fetchTotal={fetchTotal}
                openNotification={openNotification}
            />

        </TreeOrdersBodyItemRowContainer>
    )
}
