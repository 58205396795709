const { default: styled } = require("styled-components");


const DateAvailabilityContainer = styled.div`
    width: 80%;
    margin: auto;
    margin-top: 4vh;
    .events {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
    }
    .events .ant-badge-status {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        font-size: 12px;
    }
    .notes-month {
        text-align: center;
        font-size: 28px;
    }
    .notes-month section {
        font-size: 28px;
    }
    label[class='ant-radio-button-wrapper']{
        display: none;
    }
`

const InputOrderLimitContainer = styled.div`
    text-align: center;
    .changeLimitBtn{
        margin-top: 10px;
    }
`

export { DateAvailabilityContainer, InputOrderLimitContainer }