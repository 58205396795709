import React, { useState, useEffect } from 'react'
import Logo from './../../Assets/cropped-logosocial-300x300-min.png'
import LogoCatalogo from './../../Assets/catalogos-min.png'
import { testMode, appVersion } from './../../api_vars'
import { HeaderTop, HeaderBottom } from './Header/Styles/Styles'
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoadingStatus } from '../../Redux/ui/uiActions'


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});
export default function Header({ showCatalogue }) {


    const loadingStatus = useSelector(state => state.loadingStatus)
    const pageProps = useSelector(state => state.pageProps)
    const dispatch = useDispatch()

    useEffect(() => {

        let timer = ""


        if (!loadingStatus.loading) {
            clearInterval(timer)
        } else {
            timer = setInterval(async () => {

                function getProgressRandom() {

                    let randomLenght = 20

                    if (loadingStatus.progress === 100) {
                        return 100;
                    }else if (loadingStatus.progress > 70){
                        randomLenght = 3
                    }

                    const diff = Math.random() * randomLenght;

                    return Math.min(loadingStatus.progress + diff, 99);
                }

                const progressRandom = await getProgressRandom()

                dispatch(fetchLoadingStatus({ loading: true, progress: progressRandom }))
            }, 500);
        }

        return () => {
            clearInterval(timer);
        };
    }, [loadingStatus]);
    return (
        <div style={{backgroundColor: "black"}}>
            <LinearProgress variant="determinate" className={!loadingStatus.loading ? "loadingFalse" : ""} value={loadingStatus.progress} style={{ background: "black"}} />
            <HeaderTop className="Form-header1">
                <p>{pageProps.titlePage} | Santas Carnitas. {
                    <small>{testMode ? ` ${appVersion}` : ""}</small>
                }</p>
            </HeaderTop>
            <HeaderBottom className="Form-header" style={{ height: "10vh" }}>
                <img src={Logo} alt="Imagen no disponible" />

                {
                    showCatalogue ?
                        <div className="Form-header-section2">
                            <p>Catálogos</p>
                            <img src={LogoCatalogo} alt="Imagen no disponible" />
                        </div>
                        :
                        null
                }
            </HeaderBottom>
        </div>
    )
}
