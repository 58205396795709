import React, { useState, useEffect } from 'react'
import { host, dir_api_name, appVersion } from './../../api_vars'
import Logo from './../../Assets/cropped-logosocial-300x300-min.png'

import ServiceWorker from './../serviceWorker'
import {
    UpdateScreen,
    UpdateScreenLogo,
    UpdateScreenMessage,
    UpdateScreenBtn
} from './Styles/checkVersionStyles'

import Button from 'antd/es/button'
import { DownloadOutlined } from '@ant-design/icons';

import 'antd/lib/button/style/css'

export default function CheckVersion() {

    const [versionUpdated, setVersionUpdated] = useState(true)
    const [versionDownloaded, setVersionDownloaded] = useState(false)

    useEffect(() => {

        fetch(host + dir_api_name + "/Tools/checkVersion.php?appName=admin").then((res) => {

            if (res.ok) return res.json(); else throw res

        }).then((res) => {

            if (res[0].version === appVersion) {

                console.log("Updated")

            } else {

                setVersionUpdated(false)

                console.log("New version available")

            }

        }).catch(() => {

            console.log("Error")

        }, [])

    },[versionUpdated, versionDownloaded])


    const UpdateAppScreen = () => {

        return (
            <UpdateScreen>

                <UpdateScreenLogo className="flex">

                    <img src={Logo} alt="Imagen no disponible" />

                </UpdateScreenLogo>

                <UpdateScreenMessage>

                    {
                        versionDownloaded ?

                            <p>¡Nueva version disponible!</p>

                            :

                            <p>
                                ¡Nueva version disponible! Descargando ...
                            </p>
                    }

                </UpdateScreenMessage>

                <UpdateScreenBtn className="flex">

                    <Button type="primary" icon={<DownloadOutlined />}
                        onClick={() =>
                            window.location.reload(true)
                        } disabled={!versionDownloaded}>
                        Instalar
                    </Button>

                </UpdateScreenBtn>

            </UpdateScreen>
        )

    }

    return (
        <span>
            {
                !versionUpdated ? <UpdateAppScreen /> : null
            }
            <ServiceWorker setVersionDownloaded={setVersionDownloaded}/>
        </span>
    )


}