import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TableOrdersBodyPrint from '../TableOrdersBody/TableOrdersBodyPrint'
import { Empty, Steps, Divider, Button, Select } from 'antd'
import { OrderState } from '../Style/Styles'

import 'antd/lib/divider/style/css'
import { fetchOptionsListToUpdate } from '../../../../../Redux'
import styled from 'styled-components'
import { dir_api_name, host } from '../../../../../api_vars'


const { Option } = Select;


/**
 * @function createRoute - create the route of delivery for delivery man
 */

const DrawerContainerStyles = styled.section`

    .drawer-delivery_man{
        width: 100%;
        justify-content: center;
        margin-top: 5vh;
        .ant-select{
            width: 40%;
        }
    }

`

export default function DrawerContainer() {

    const ordersToUpdate = useSelector(state => state.ordersToUpdate)

    const { Step } = Steps;

    const dispatch = useDispatch()

    const cellsProps = {
        check: {
            visible: false,
            width: ""
        },
        id: {
            visible: true,
            width: ""
        },
        name: {
            visible: true,
            width: ""
        },
        date_delivery: {
            visible: true,
            width: ""
        },
        ubication: {
            visible: true,
            width: ""
        },
        state: {
            visible: true,
            width: ""
        },
        dispatch_price: {
            visible: false,
            width: ""
        },
        delivery_man: {
            visible: false,
            width: ""
        },
        invoice_number: {
            visible: true,
            width: ""
        },
        total: {
            visible: true,
            width: ""
        }
    }

    const orderToUpdateOptionsInitialState = {
        update: false,
        delivery_man_id: "",
        route_id: null,
        state: 0
    }

    const [delivery_men, setDelivery_men] = useState([])


    const consultDeliverymen = () => {


        const headers = new Headers();

        headers.append('Content-Type', 'application/json');

        headers.append('Origin', host);

        const miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(`${host}${dir_api_name}/admin/components/delivery/api_consult_delivery_men.php`, miInit).then((response) => {

            if (response.ok) {

                return response.json();

            }

            else { throw response };

        })

    }


    const createRoute = () => {

        const headers = new Headers();

        headers.append('Content-Type', 'application/x-www-form-urlencoded');

        headers.append('Origin', host);

        const miInit = {
            method: 'POST',
            headers: headers, 
            body: `delivery_man_id=${ordersToUpdate.delivery_man_id}`,
            mode: 'cors',
        };


        fetch(`${host}${dir_api_name}/admin/components/delivery/api_create_route.php`, miInit).then((response) => {

            if (response.ok) {

                return response.json();

            }

            else { throw response };

        }).then((res)=>{
            dispatch(fetchOptionsListToUpdate({ update: true, route_id: res.route_id }))
        })
        .catch((err)=> alert(err))
    }

    useEffect(() => {

        consultDeliverymen().then((res) => setDelivery_men([...res])).catch((err) => alert(err))
        dispatch(fetchOptionsListToUpdate(orderToUpdateOptionsInitialState))

    }, [])

    return (
        <DrawerContainerStyles>
            {
                ordersToUpdate.orders.length > 0 ?
                    <TableOrdersBodyPrint cells={cellsProps} dataType={"json"} controlsUpDown={true} partent="multiple_choice" />
                    : <Empty description="Vacío" />

            }
            {
                ordersToUpdate.state === 4 ? <h2>Ruta</h2> : null
            }
            <div className="flex">
                <Divider type="vertical" style={{ height: "20px", borderColor: ordersToUpdate.state < 1 ? "#7070702b" : "#1890ff", marginTop: "20px", borderWidth: "2px" }} />
            </div>
            <OrderState style={{ marginTop: "20px" }}>

                <Steps size="default" current={ordersToUpdate.state} onChange={(e) => dispatch(fetchOptionsListToUpdate({ state: "" + e + "" }))}>

                    <Step title="Sin Confirmar" />

                    <Step title="Cancelar" className="orderCanceled" />

                    <Step title="Confirmar" />

                    <Step title="Porcionado" />

                    <Step title="Despachado" />

                </Steps>

            </OrderState>

            <div className="flex">

                <Divider type="vertical" style={{ height: "20px", borderColor: ordersToUpdate.state < 1 ? "#7070702b" : "#1890ff", marginTop: "20px", borderWidth: "2px" }} />

            </div>

            {

                ordersToUpdate.state > 3 ?

                    <div className="drawer-delivery_man flex">
                        <Select onChange={(value) =>
                            dispatch(fetchOptionsListToUpdate({
                                delivery_man_id: value
                            }))
                        }
                        defaultValue={ordersToUpdate.delivery_man_id}
                        placeholder="Domiciliario"
                        >
                            {
                                delivery_men.map((item, index) =>
                                    <Option key={item.id} value={item.id}> {JSON.parse(item.user_data).name}</Option>
                                )}
                        </Select>
                    </div>

                    : null

            }

            <div className="flex" style={{ marginTop: "20px" }}>

                <Button disabled={ordersToUpdate.state < 1 || (ordersToUpdate.state >= 4 && ordersToUpdate.delivery_man_id === null)} onClick={createRoute} type="primary">Actualizar</Button>

            </div>

        </DrawerContainerStyles>
    )
}
