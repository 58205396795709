import { AutoComplete, Button, DatePicker, notification, Form, Input, message, TreeSelect } from 'antd';
import React, { useRef, useState } from 'react'
import { currencyCountry, dir_api_name, host, styleCurrency } from '../../../api_vars';
import { AddOrderContainer, AddOrderContainerBody, BoxStyle } from './AddOrder/Styles/AddOrderStyles';
import locale from 'antd/es/date-picker/locale/es_ES'
import moment from 'moment'
import { MailOutlined } from '@ant-design/icons'
import Address from './AddOrder/Address';
import { useEffect } from 'react';
import CartAddOrder from './AddOrder/CartAddOrder';
import { TreeNode } from 'antd/lib/tree-select';
import BreadcrumCmnt from '../../BreadcrumCmnt';
import { useDispatch } from 'react-redux';
import { fetchBreadcumb, fetchPageProps } from '../../../Redux';


export default function AddOrder() {


    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const initialCartState = {
        RES: [],
        [`CARNES FRIAS`]: [],
        [`POLLO`]: [],
        [`PESCADOS Y MARISCOS`]: [],
        [`CERDO`]: [],
        [`ANTOJOS`]: [],
        delivery_price: 0,
        combos: [],
        discount: 0
    }

    const initialUserDataState = {
        name: "",
        last_name: "",
        id: "",
        cell: "",
        tel: "",
        address: "",
        orientation: "",
        city: "",
        email: "",
        neighborhood: "",
        commune: "",
        idDirection: "",
        addressID: "",
        point_reference: "",
        date: "",
        hour: "",
        minute: "",
        am_pm: "",
        payment_method: ""
    }

    const [fields, setFields] = useState([
        {
            name: ['name'],
            value: ""
        }, {
            name: ['last_name'],
            value: ""
        }, {
            name: ['id'],
            value: ""
        }, {
            name: ['email'],
            value: ""
        }, {
            name: ['cell'],
            value: ""
        }, {
            name: ['tel'],
            value: ""
        }, {
            name: ['address'],
            value: ""
        }, {
            name: ['payment_method'],
            value: ""
        },
        {
            name: ['point_reference'],
            value: ""
        }

    ])

    const [userData, setUserData] = useState(initialUserDataState)

    const [dateStatus, setDateStatus] = useState({ state: null, msg: " " })

    const [minimumPrice, setMinimumPrice] = useState(30000)

    const [total, setTotal] = useState(0)

    const [cart, setCart] = useState(initialCartState)

    const [inputValidate, setinputValidate] = useState({
        email: {
            status: null,
            msg: " "
        }
    })

    const [loadingBtn, setLoadingBtn] = useState(false)

    const [dataSource, setDataSource] = useState([])

    const inputEmail = useRef(null)

    const [address, setAddress] = useState()
    const [cities, setCities] = useState([])
    const [neighBorHoods, setNeighBorHoods] = useState({})
    const [commune, setCommune] = useState({})
    const [loading, setLoading] = useState(true)

    const openNotification = (title, type) => {
        let message
        const notificationSucess = (message) => {
            notification.success({
                message: message,
                description: title,
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                placement: "bottomRight",
                className: "notifications"
            });
        }

        const notificationDeleted = (message) => {
            notification.error({
                message: message,
                description: title,
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                placement: "bottomRight",
                className: "notifications"
            })
        }



        switch (type) {
            case "product_added":
                message = "Añadido al carrito!"
                notificationSucess(message)
                break;

            case "product_deleted":
                message = "Eliminado!"
                notificationDeleted(message)
                break;

            case "product_updated":
                message = "Actualizado!"
                notificationSucess(message)
                break;

            case "error_reload":
                message = "Error al cargar!"
                notificationDeleted(message)
                break;

            case "error_finish":
                message = "Error al cargar"
                notificationDeleted(message)
                break;

            case "consult_date":

                message = "Verificando disponibilidad"
                notificationSucess(message)
                break;

            case "date_unavailable":

                message = "No disponible"
                notificationDeleted(message)
                break;
            case "date_available":

                message = "Disponible"
                notificationSucess(message)
                break;

            default:
                return false;
        }


    };

    const getLimitByDate = (value) => {

        const dateLocal = value.format("YYYY-MM-DD")
        const url = host + dir_api_name + "/api_consult_ordersLimit.php"
        const query = `?date=${dateLocal}&city=${userData.city}`

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };

        openNotification("Consultando fecha", "consult_date")

        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.text();
            }

            else { throw response };
        }).catch((err) => console.error(err))
    }

    const RegisterUserData = (e, name) => {

        let value

        if (name === "date") {

            value = e

            getLimitByDate(value).then(

                res => {

                    console.log("El limite es de: " + res)

                    if (Number(res) > 0 || res === "") {


                        setDateStatus({ state: "success", msg: " " })

                        setUserData({
                            ...userData,
                            [`${name}`]: value.format("YYYY-MM-DD")
                        })

                        openNotification("El día: " + value.format("YYYY-MM-DD") + " se encuentra disponible.", "date_available")

                    } else if (res === "0" || res < 0) {

                        openNotification("Lo sentimos el día: " + value.format("YYYY-MM-DD") + " no esta disponible en este momento.", "date_unavailable")

                        document.getElementById("userData-date").focus();

                        setDateStatus({ state: "error", msg: "Por favor establezca una fecha" })

                        setUserData({
                            ...userData,
                            [`${name}`]: ""
                        })

                    }

                }
            )

        } else {

            value = decodeURI(name !== "email" ? e.target.value : e)


            setUserData({
                ...userData,
                [`${name}`]: ("" + value + ""),
            })

        }

    }

    const checkPrice = () => {

        if (Number(total) < minimumPrice) {


            alert("El pedido debe ser mayor o igual a " + Number(minimumPrice).toLocaleString(currencyCountry, styleCurrency))

            return false

        }

        else return true

    }

    const consultID = () => {

        message.info("Cargando...", 0)

        const headers = new Headers();

        headers.append('Content-Type', 'application/json');

        headers.append('Origin', host);

        const id = document.getElementById("userData-id").value

        const miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        fetch(`${host}${dir_api_name}/admin/components/purchases/add_order/api_consult_user_by_id.php?id=${id}`, miInit).then((response) => {

            if (response.ok) {
                return response.json();
            }

            else { throw response };

        })
            .catch((err) => {

                message.destroy()

                message.error("Error: " + err)

            })
            .then((res) => {

                message.destroy()

                if (res !== null) {


                    const user_data_res = JSON.parse(res)

                    user_data_res.date = ""
                    user_data_res.orderStatus = "0"
                    user_data_res.delivery_man = ""
                    user_data_res.password = ""
                    user_data_res.invoice_number = ""

                    setUserData({
                        ...userData,
                        ...user_data_res,
                        date: ""
                    })

                    setFields([
                        {
                            name: ['name'],
                            value: user_data_res.name
                        }, {
                            name: ['last_name'],
                            value: user_data_res.last_name
                        }, {
                            name: ['id'],
                            value: user_data_res.id
                        }, {
                            name: ['email'],
                            value: user_data_res.email
                        }, {
                            name: ['cell'],
                            value: user_data_res.cell
                        }, {
                            name: ['tel'],
                            value: user_data_res.tel
                        }, {
                            name: ['address'],
                            value: user_data_res.addressSelected === undefined ? user_data_res.address :
                                user_data_res.addresses[user_data_res.addressSelected].address
                        }, {
                            name: ['payment_method'],
                            value: user_data_res.payment_method
                        },
                        {
                            name: ['point_reference'],
                            value: user_data_res.point_reference
                        }

                    ])
                }

            })

    }

    const register = async (value) => {

        let status = true
        await document.querySelectorAll(".itemsForm").forEach(item => {
            if (item.value === "") {

                item.focus();

                status = false;

                return false;

            } else if (userData.date === "") {
                document.getElementById("userData-date").focus();

                status = false;

                return false;
            } else if (userData.idDirection === "") {

                document.getElementById("userData-location").focus();

                status = false;

                return false;
            }

        })
        if (status && checkPrice()) {

            const headers = new Headers();

            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            headers.append('Origin', host);



            const order = JSON.stringify({
                pig: cart.CERDO,
                beef: cart.RES,
                chicken: cart.POLLO,
                fish: cart["PESCADOS Y MARISCOS"],
                sausages: cart["CARNES FRIAS"],
                cravings: cart.ANTOJOS,
                combos: cart.combos,
                delivery_price: cart.delivery_price,
                discount: cart.discount
            })


            userData.orderStatus = undefined;

            const user_data = JSON.stringify(userData)

            const miInit = {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: `order=${order}&user_data=${user_data}&total=${total}&device_data=""`
            };



            fetch(`${host}${dir_api_name}/admin/components/purchases/add_order/api_register_order.php`, miInit).then((response) => {

                if (response.ok) return response.json()

                else throw response

            })
                .then((res) => {

                    if (res.result) {
                        message.success("¡Pedido guardado!")
                    } else {
                        message.error("¡El pedido no ha podido ser guardado! Error: " + res.message, 3000)
                    }

                    setCart(initialCartState)

                    setUserData(initialUserDataState)
                })

                .catch(err => {
                    console.log(err)
                    message.error("Error al regisgtrar pedido " + err)
                    setLoadingBtn(false)
                })

        } else if (!status) {
            setLoadingBtn(false)
            alert("Por favor llene todos los campos");
        }

    }

    const handleChange = value => {

        RegisterUserData(value, "email")

        setinputValidate({ ...inputValidate, email: { status: "success", msg: " " } })

        setDataSource(
            !value || value.indexOf('@') >= 0
                ? []
                : [`${value}@gmail.com`, `${value}@hotmail.com`, `${value}@outlook.com`],

        );

    };

    //Adress Funtions

    const onChange = value => {
        console.log(value);
        if (value === undefined) {
            setAddress(value)
            setCart(prevState => {
                return {
                    ...prevState,
                    delivery_price: ""
                }
            })


            setUserData(prevState => {
                return {
                    ...prevState,
                    idDirection: "",
                    orientation: "",
                    city: "",
                    neighborhood: "",
                    commune: "",
                    addressID: ``
                }
            })
        }
    };

    const consulCities = () => {
        const url = host + dir_api_name + "/api_consult_address.php"
        const query = `?type=cities`

        const headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        const miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err)).then(citiesRes => {
            setCities(citiesRes)

            citiesRes.map((citiesItem, key) =>

                consultNeighborhoods(citiesItem.city).then(

                    neighborhoodRes => {

                        setNeighBorHoods(prevState => { return { ...prevState, [`${citiesItem.city}`]: neighborhoodRes } })

                        neighborhoodRes.map((commune, key2) =>

                            consultCommune(commune.neighborhood, citiesItem.city).then(
                                communeRes => {
                                    setCommune(prevState => { return { ...prevState, [`${citiesItem.city}-${commune.neighborhood}`]: communeRes } })
                                    if (neighborhoodRes.length === (key2 + 1) && citiesRes.length === (key + 1)) {
                                        setLoading(false)
                                    }
                                }
                            )

                        )
                    }
                )
            )
        })

    }

    const consultNeighborhoods = (city) => {
        const url = host + dir_api_name + "/api_consult_address.php"
        const query = `?type=neighborhoods&city=${city}`

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err))
    }

    const consultCommune = (neighborhood, city) => {
        const url = host + dir_api_name + "/api_consult_address.php"
        const query = `?type=commune&neighborhood=${neighborhood}&city=${city}`

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err))
    }


    const setAddres = (id, orientation, city, neighborhood, commune, price, key, key2, key3) => {

        setAddress(`${city} ${neighborhood} ${commune} ${key}-${key2}-${key3}`)

        setCart({
            ...cart,
            delivery_price: price
        })

        setUserData(prevState => {
            return {
                ...prevState,
                idDirection: id,
                orientation: orientation,
                city: ((city)),
                neighborhood: ((neighborhood)),
                commune: ((commune)),
                addressID: `${city} ${neighborhood} ${commune}`
            }
        })

    }

    const consultPrice = () => {
        const url = host + dir_api_name + "/api_consult_delivery_price.php"
        const query = `?id=` + unescape(userData.commune)

        const headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        const miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };

        let price = 0

        return fetch(url + query, miInit).then((response) => {

            if (response.ok) {
                return response.text();
            }

            else { throw response };
        })

    }

    //Address Funtions

    useEffect(() => {
        if (userData.addressID !== "") {
            consultPrice().then((res) => {
                setCart(prevState => {
                    return {
                        ...prevState,
                        delivery_price: res
                    }
                })
            }).catch((err) => console.log(err))
        }
    }, [userData])


    useEffect(() => {
        if (userData.id !== fields[2].value) {
            setFields([
                {
                    name: ['name'],
                    value: userData.name
                }, {
                    name: ['last_name'],
                    value: userData.last_name
                }, {
                    name: ['id'],
                    value: userData.id
                }, {
                    name: ['email'],
                    value: userData.email
                }, {
                    name: ['cell'],
                    value: userData.cell
                }, {
                    name: ['tel'],
                    value: userData.tel
                }, {
                    name: ['date'],
                    value: userData.date
                },
                {
                    name: ['address'],
                    value: userData.addressSelected === undefined ? userData.address :
                        userData.addresses[userData.addressSelected].address
                }, {
                    name: ['payment_method'],
                    value: userData.payment_method
                },
                {
                    name: ['point_reference'],
                    value: userData.point_reference
                }

            ])
        }

    }, [userData])

    useEffect(() => {

        const url = host + dir_api_name + "/shop/tools/consultMinimumPrice.php"

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        fetch(url, miInit).then((response) => {
            if (response.ok) {
                return response.text();
            }

            else { throw response };
        }).catch((err) => console.error(err))
            .then((res) =>
                setMinimumPrice(Number(res))
            )

    }, []) // Consult minimum price

    useEffect(() => {

        consulCities()

    }, [])

    useEffect(() => {

        dispatch(fetchPageProps({ titlePage: "Añadir Compra" }))
        const breadCrumb = [
            { title: "Compras", link: "/admin/product_list/", disabled: true },
            { title: "Añadir Compra", link: "/admin/product_list/", disabled: true }
        ]

        dispatch(fetchBreadcumb(breadCrumb))
    }, [])
    return (
        <AddOrderContainer>
            <BreadcrumCmnt />
            <AddOrderContainerBody>
                <BoxStyle width={45}>
                    <Form form={form} className="userData" onFinish={register} preserve={true} fields={fields}>

                        <h3 className="form-title">Datos personales</h3>

                        <div className="flex">

                            <div>
                                <label>Cédula</label>
                                <Form.Item
                                    name="id"
                                    preserve
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor introduzca su cedula!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        className="itemsForm"
                                        type="number"
                                        id="userData-id"
                                        onChange={
                                            (e) => {
                                                RegisterUserData(e, "id")
                                            }
                                        }
                                    />
                                </Form.Item>

                            </div>

                            <div className="btn-consultId">
                                <Button onClick={consultID} type="primary">
                                    Consultar
                            </Button>
                            </div>

                        </div>

                        <div className="flex">

                            <div>

                                <label>Nombre</label>

                                <Form.Item
                                    name="name"
                                    preserve
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor introduzca su nombre!',
                                            whitespace: true,
                                        },
                                    ]}
                                >

                                    <Input
                                        className="itemsForm"
                                        id="userData-name"
                                        onChange={
                                            (e) => RegisterUserData(e, "name")
                                        }
                                    />

                                </Form.Item>

                            </div>

                            <div>

                                <label>Apellido</label>

                                <Form.Item
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor introduzca su apellido!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        className="itemsForm"
                                        type="text"
                                        id="userData-last_name"
                                        onChange={
                                            (e) => RegisterUserData(e, "last_name")
                                        }
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="item">
                            <div style={{ width: "100%" }}>
                                <label>Correo Electrónico</label>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor introduzca su email!',
                                            whitespace: true,
                                        },
                                    ]}
                                    style={{ width: "100%" }}
                                    help={inputValidate.email.msg}
                                    validateStatus={inputValidate.email.status}
                                >
                                    <AutoComplete
                                        dataSource={dataSource}
                                        onChange={handleChange}
                                        size="large"
                                        prefix={<MailOutlined />}
                                        ref={inputEmail}
                                        className="itemsForm"
                                    // value={userData.email}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="flex">
                            <div>
                                <label>Celular</label>
                                <Form.Item
                                    name="cell"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor introduzca su número de celular!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input className="itemsForm" name="cell" type="number" id="userData-cell" onChange={(e) => RegisterUserData(e, "cell")} />

                                </Form.Item>
                            </div>
                            <div>
                                <label>Teléfono fijo (Opcional)</label>
                                <Form.Item
                                    name="tel"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Por favor introduzca su teléfono!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input name="tel" type="number" id="userData-tel" onChange={(e) => RegisterUserData(e, "tel")} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="flex">
                            <div>
                                <label>Dirección</label>
                                <Form.Item
                                    name="address"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor introduzca su dirección!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input className="itemsForm" type="text" id="userData-address" onChange={(e) => RegisterUserData(e, "address")} />
                                </Form.Item>

                            </div>
                            <div>
                                <label>Punto de referencia</label>

                                <Form.Item
                                    name="point_reference"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor introduzca un punto de referencia!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input className="itemsForm" type="text" id="userData-point_reference" onChange={(e,) => {
                                        if (escape(e.target.value) === "%0A") {
                                            e.preventDefault();
                                            console.log('prevented');
                                            return false;
                                        } else {
                                            RegisterUserData(e, "point_reference")
                                        }
                                    }} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="item">

                            <div>

                                <label>Municipio, Barrio y Comuna</label>

                                <TreeSelect
                                    showSearch
                                    style={{ width: '100%' }}
                                    value={userData.addressID !== undefined ? userData.addressID : null}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder="Buscar ubicación:"
                                    allowClear
                                    onChange={onChange}
                                    id="userData-location"
                                >
                                    {
                                        loading ?
                                            <TreeNode value="Default" title="Cargando..." selectable={false}></TreeNode>
                                            :
                                            cities.map(
                                                (item, key) =>
                                                    <TreeNode value={`${item.city} ${key}`} title={item.city} onClick={(e) => console.log(e)} selectable={false}>
                                                        {
                                                            neighBorHoods[`${item.city}`] ? neighBorHoods[`${item.city}`].map(

                                                                (item2, key2) => <TreeNode value={`${item2.neighborhood}  ${key}-${key2}`} title={item2.neighborhood} selectable={false}>

                                                                    {
                                                                        commune[`${item.city}-${item2.neighborhood}`] ? commune[`${item.city}-${item2.neighborhood}`].map(

                                                                            (item3, key3) =>
                                                                                <TreeNode value={`${item.city} ${item2.neighborhood} ${item3.commune} ${key}-${key2}-${key3}`}
                                                                                    title=
                                                                                    {
                                                                                        <p style={{ margin: 0 }} onClick={() => setAddres(item3.id, item3.orientation, item.city, item2.neighborhood, item3.commune, item3.rate, key, key2, key3)}> {item.city} / {item2.neighborhood} / {item3.commune}</p>
                                                                                    }
                                                                                />
                                                                        )
                                                                            : null
                                                                    }

                                                                </TreeNode>
                                                            ) : null
                                                        }
                                                    </TreeNode>
                                            )
                                    }
                                </TreeSelect>


                            </div>

                        </div>
                        <section className="flex">

                            <div>
                                <label>¿Cuando quieres que te llegue?</label>

                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor seleccione una fecha!',
                                            whitespace: true,
                                        },
                                    ]}
                                    help={dateStatus.msg}
                                    validateStatus={dateStatus.state}
                                >
                                    <DatePicker className="itemsForm" type="date" id="userData-date" value={userData.date !== "" ? moment(userData.date) : null} allowClear={true} locale={locale} autoComplete={"off"} onChange={(e) => RegisterUserData(e, "date")} />

                                </Form.Item>
                            </div>

                            <div>

                                <label>¿Como quieres pagar?</label>

                                <Form.Item
                                    name="payment_method"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor seleccione una forma de pago!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <select className="itemsForm" id="userData-payment_method" onChange={(e,) => RegisterUserData(e, "payment_method")}>

                                        <option value=""> </option>

                                        <option value="Efectivo"> Efectivo </option>

                                        <option value="Tarjeta de Crédito o Débito"> Tarjeta de Crédito o Débito </option>

                                        <option value="Transferencia Electronica"> Transferencia Electrónica </option>

                                        <option value="Código QR"> Código QR </option>

                                    </select>

                                </Form.Item>


                            </div>

                        </section>

                        <Form.Item className="btnFinishContainer">

                            <Button htmlType="submit" // Event Listener Click END---------------------------

                                style={{ boxShadow: "-1.5px 0px 3px rgba(143, 62, 0, 0.5)" }}
                                className="buyFinishBtn"
                                loading={loadingBtn}
                            >

                                Guardar pedido <span style={{ color: "#fafafa", padding: "0px 5px" }}>|</span>
                                <span style={{ color: "white" }}>Total : {<b>{Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>}</span>

                            </Button>
                        </Form.Item>
                    </Form>

                </BoxStyle>
                <BoxStyle width={40}>
                    <CartAddOrder title={""} Cart={cart} total={total} setCart={setCart} setTotal={setTotal} />
                </BoxStyle>
            </AddOrderContainerBody>
        </AddOrderContainer>
    )
}
