const SET_BREADCUMB = 'SET_BREADCUMB'
const SET_ORDERS_FILTER = 'SET_ORDERS_FILTER'
const SET_CALENDAR = 'SET_CALENDAR'
const SET_ORDER_DATA = 'SET_ORDER_DATA'
const SET_ORDER_LIST_TO_UPDATE = 'SET_ORDER_LIST_TO_UPDATE'
const DELETE_ORDER_LIST_TO_UPDATE = 'DELETE_ORDER_LIST_TO_UPDATE'
const FETCH_LOADING_STATUS = 'FETCH_LOADING_STATUS'
const SET_OPTIONS_LIST_TO_UPDATE = 'SET_OPTIONS_LIST_TO_UPDATE'
const FETCH_PAGE_PROPS = "FETCH_PAGE_PROPS"


export {
    SET_BREADCUMB,
    SET_ORDERS_FILTER,
    SET_CALENDAR,
    SET_ORDER_DATA,
    SET_ORDER_LIST_TO_UPDATE,
    DELETE_ORDER_LIST_TO_UPDATE,
    FETCH_LOADING_STATUS,
    SET_OPTIONS_LIST_TO_UPDATE,
    FETCH_PAGE_PROPS
}