import React from 'react'
import { Button } from 'antd'

import {
    UnorderedListOutlined,
    ApartmentOutlined
} from '@ant-design/icons';

import { ViewToggleContainer } from './Style/Styles'
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrderFilter } from '../../../../Redux';


export default function ViewToggle() {

    const orders_filter = useSelector(state => state.orders_filter)
    const loadingStatus = useSelector(state => state.loadingStatus)
    const dispatch = useDispatch()

    const changeView = (value) => {

        dispatch(fetchOrderFilter({
            viewType: value
        }))

    }

    return (
        <ViewToggleContainer className="flex">

            <Button title="Últimos añadidos"
                type={orders_filter.viewType === "last_added" ? "primary" : "default"}
                onClick={() => changeView("last_added")}
                disabled={loadingStatus.loading}
            >
                <UnorderedListOutlined />

            </Button>

            <Button title="Lista Ordenada"
                type={orders_filter.viewType === "ordered_list" ? "primary" : "default"}
                onClick={() => changeView("ordered_list")}
                disabled={loadingStatus.loading}
            >

                <ApartmentOutlined />

            </Button>

        </ViewToggleContainer>
    )
}
