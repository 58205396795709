import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'
import { DownOutlined, SwapLeftOutlined } from '@ant-design/icons';
import {
    TreeCity
} from './../../../../Style/Styles'
import TableOrdersBodyItemRow from '../../TableOrdersBodyItemRow'
import {  message } from 'antd'
import { ordersProps } from '../../../../ordersProps'

const dispositive_type = window.screen.width > 600 ? "PC" : "MOBILE";


export default function CommuneNode({ title, fetchOrders, openNotification, orientation, city, neighborhood, sectionColor, fetchTotal }) {


    const cellsProps = {
        check: {
            visible: dispositive_type === "PC",
            width: "2%"
        },
        id: {
            visible: true,
            width: dispositive_type === "PC" ? "5%" : "10%"
        },
        name: {
            visible: dispositive_type === "PC",
            width: "11%"
        },
        date_delivery: {
            visible: dispositive_type === "PC",
            width: "9%"
        },
        ubication: {
            visible: true,
            width: dispositive_type === "PC" ? "18%" : "45%"
        },
        state: {
            visible: true,
            width: dispositive_type === "PC" ? "11%" : "24%"
        },
        dispatch_price: {
            visible: false,
            width: "10%"
        },
        invoice_number: {
            visible: dispositive_type === "PC",
            width: "11%"
        },
        delivery_man: {
            visible: dispositive_type === "PC",
            width: "10%"
        },
        total: {
            visible: dispositive_type === "PC",
            width: "11%"
        },
        payment_state: {
            visible: true,
            width: "9%"
        }
    }

    const [collapse, setCollapse] = useState(false)
    const [orders, setOrders] = useState(ordersProps)
    const commune = unescape(title[0].replace("\"", "").replace("\"", ""))
    const filters = useSelector(state => state.orders_filter)
    const [total, setTotal] = useState(0)


    const setOrdersActions = (res) => {

        setOrders(res)

    }

    useEffect(() => {


        let commune = unescape(title[0].replace("\"", "").replace("\"", ""))

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_orders_treeView.php?date=${date_delivery}&orientation=${orientation}&city=${city}&neighborhood=${neighborhood}&commune=${commune}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=commune&optionValue=${commune}&orientation=${(orientation)}&city=${(city)}&neighborhood=${(neighborhood)}&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let reloadDataInterval

        clearInterval(reloadDataInterval !== undefined ? reloadDataInterval : null)

        reloadDataInterval = setInterval(() => {

            if (filters.reload && filters.viewType === "ordered_list" && filters.reloadTreeOrders) {


                fetchTotal(totalQuery).then((res) => {

                    if (res > total || res < total) {

                        fetchOrders(query).then((res) => {

                            setOrdersActions([...res])

                        }).catch(err => {

                            openNotification(`Error cargando datos ref: ${err}`, "error")

                        })

                    }
                    setTotal(res)

                }).catch(err => {

                    openNotification(`Error cargando datos ref: ${err}`, "error")

                })
                // dispatch(fetchLoadingStatus({}, "On"))

            }

        }, 10000)


        return () => clearInterval(reloadDataInterval)

    }, [filters, orientation, city, neighborhood, title])

    useEffect(()=>{

        let commune = unescape(title[0].replace("\"", "").replace("\"", ""))

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_orders_treeView.php?date=${date_delivery}&orientation=${orientation}&city=${(((city)))}&neighborhood=${((neighborhood))}&commune=${((commune))}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=commune&optionValue=${(commune)}&orientation=${(orientation)}&city=${(city)}&neighborhood=${(neighborhood)}&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        if (filters.reload && filters.viewType === "ordered_list") {

            message.destroy()

            fetchOrders(query).then((res) => {

                setOrdersActions([...res])

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })

            fetchTotal(totalQuery).then((res) => {

                setTotal(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })
            // dispatch(fetchLoadingStatus({}, "On"))

        }

    },[filters])

    const collapsesSection = (e) => {
        let className = e.target.className
        if (className === "Tree-header flex commune") {

            setCollapse(!collapse)
        }
    }
    return (

        <TreeCity key={title} className={collapse ? "collapsed" : ""} onClick={(e) => collapsesSection(e)}>

            <div className="Tree-header flex commune" style={{ backgroundColor: sectionColor }}>

                <div className="Tree-header-title">

                <SwapLeftOutlined className={collapse ? "treeLine collapseIcon" : "treeLine"}/> {city} - {neighborhood} - {commune} | <b> Total : {total}</b>

                </div>

                <div>

                    <DownOutlined className="DownBtn" />

                </div>

            </div>

            <div className="Tree-body">

                {

                    orders.map((item, index) =>

                        <TableOrdersBodyItemRow item={item} cellsProps={cellsProps} dataType={"text"} index={index} />

                    )

                }

            </div>

        </TreeCity>
    )
}