import { SET_BREADCUMB, SET_ORDERS_FILTER, SET_CALENDAR, SET_ORDER_DATA, SET_ORDER_LIST_TO_UPDATE, DELETE_ORDER_LIST_TO_UPDATE, FETCH_LOADING_STATUS, SET_OPTIONS_LIST_TO_UPDATE, FETCH_PAGE_PROPS } from './uiTypes'

const fetchBreadcumb = (breadCrumb) => {
    return {
        type: SET_BREADCUMB,
        payload: breadCrumb
    }
}

const fetchOrderFilter = (filters) => {
    return {
        type: SET_ORDERS_FILTER,
        payload: filters
    }
}

const fetchCalendarData = (calendarData) => {
    return {
        type: SET_CALENDAR,
        payload: calendarData
    }
}

const fetchOrderData = (orderData) => {
    return {
        type: SET_ORDER_DATA,
        payload: orderData
    }
}

const fetchOptionsListToUpdate = (options) => {
    return {
        type: SET_OPTIONS_LIST_TO_UPDATE,
        payload: options
    }
}

const fetchOrderListToUpdate = (order) => {
    return {
        type: SET_ORDER_LIST_TO_UPDATE,
        payload: order
    }
}

const deleteOrderOfListToUpdate = (index) => {
    return {
        type: DELETE_ORDER_LIST_TO_UPDATE,
        payload: index
    }
}

const fetchLoadingStatus = (status, action) => {

    switch (action) {
        case "On":

            return {
                type: FETCH_LOADING_STATUS,
                payload: {
                    loading: true,
                    progress: 0
                }
            }

            break;

        case "Set to 100%":

            return {
                type: FETCH_LOADING_STATUS,
                payload: {
                    loading: true,
                    progress: 100
                }
            }
        case "Off":

            return {
                type: FETCH_LOADING_STATUS,
                payload: {
                    loading: false,
                    progress: 0
                }
            }


        default:

            return {
                type: FETCH_LOADING_STATUS,
                payload: status
            }

            return false;

    }
}

const fetchPageProps = (props) => {

    return {
        type: FETCH_PAGE_PROPS,
        payload: props
    }
}


export {
    fetchOrderFilter,
    fetchBreadcumb,
    fetchCalendarData,
    fetchOrderData,
    fetchOrderListToUpdate,
    deleteOrderOfListToUpdate,
    fetchLoadingStatus,
    fetchOptionsListToUpdate,
    fetchPageProps
}