import { host, dir_api_name } from "../../api_vars";

const checkRol = async (token) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Origin', host);
    let miInit = {
        method: 'POST',
        headers: headers,
        body : 'token='+token,
        mode: 'cors'
    };

    let rol = 0
    let error = ""

    await fetch(host + dir_api_name + `/users/view/tools/checkAccess.php`, miInit)

        .then((res) => {

            if (res.ok)

                return res.text()

            else

                throw res

        })

        .then((res) => {
            rol = res
        })

        .catch((err) => error = err)

    return new Promise((resolve, reject) => {
        if(error === ""){
            resolve(rol)
        }else{
            reject(error)
        }
    })

}

export { checkRol }