import styled from 'styled-components'


const LoginContainer = styled.form`
    background-color: #b9180d;
    width: 100%;
    height: 100vh;
`
const LoginHeader = styled.header`
    display: flex;
    justify-content: center;
    img {
        width: 17vw;
    }
`
const LoginForm = styled.section`

`

const LoginFormHeader = styled.header`
    background-color: #ac0b00;
    width: 30vw;
    height: 7vh;
    margin: auto;
    border-radius: 2px 2px 0px 0px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
        width: 80vw;
    }
    h2 {
        margin: 0;
        padding: 0;
        color: white;
    }
`

const LoginFormBody = styled.section`
    background-color: white;
    min-height: 30vh;
    width: 30vw;
    padding: 10vh 0vh;
    margin: auto;
    border-radius: 0px 0px 2px 2px;
    box-shadow: 0px -3px 4px rgba($color: #000000, $alpha: 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    box-sizing:initial;
    @media (max-width: 600px) {
        width: 80vw;
    }
    input {
        padding: 12px;
        width: 17vw;
        border: none;
        border-bottom: 1px solid;
        border-color: rgba(112, 112, 112, 0.4);
        border-radius: 2px;
        @media (max-width: 600px) {
            width: 41vw;
        }
    }
    input::placeholder {
        color: rgba(112, 112, 112, 0.9);
    }
    button {
        background-color: #d51c10;
        border: none;
        border-radius: 2px;
        padding: 10px;
        width: 10vw;
        color: white;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.5s;
        @media (max-width: 600px) {
            width: 25vw;
        }
    }
    button:hover {
        background-color: rgb(179, 24, 13);
    }
`
export {LoginContainer, LoginForm, LoginHeader, LoginFormHeader, LoginFormBody}
