import { checkRol } from "../Util/checkRol";
import { resolveOnChange } from "antd/lib/input/Input";

let rol = 0

const checkUserAccess = async (levels) => {


    await checkRol("").then((res) => rol = res)

    let access = false

    levels.forEach(element => {
        if (element === rol) {
            access = true
        }
    });


    return new Promise((resolve,reject)=>{
        resolve(access)
    })

}


export { checkUserAccess }