import React from 'react'
import { Menu, Checkbox } from 'antd'
import { useState } from 'react'

//Styles
import 'antd/lib/checkbox/style/css'

export default function PresentationItem({ index, changePortionedStatus, item }) {

    const [checkedStatus, setCheckedStatus] = useState(item.selectable)

    return (
        <li className="ant-menu-item ant-menu-item-only-child" key={item.name+index} style={{ height: 27 + "px" }}>
            <Checkbox className="" style={{ zIndex: "1000" }}
                onChange={e => { changePortionedStatus(e, index); setCheckedStatus(!checkedStatus) }}
                checked={checkedStatus}>
                {item.name}
            </Checkbox>
        </li>
    )

}
