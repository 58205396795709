import React, { useEffect, useState } from 'react'
import { fetchBreadcumb, fetchPageProps } from '../../../Redux'
import BreadcrumCmnt from '../../BreadcrumCmnt'
import { useDispatch } from 'react-redux'
import { MinimumOrderPriceContainer, MinimumOrderPriceSection1 } from './MinimumOrderPrice/Style/Style'
import { InputNumber, Button } from 'antd'
import { host, dir_api_name } from '../../../api_vars'

const headers = new Headers();
headers.append('Content-Type', 'application/x-www-form-urlencoded');
headers.append('Origin', host);

export default function MinimumOrderPrice() {
    const dispatch = useDispatch()
    const [price, setPrice] = useState(0)
    const [initialPrice, setInitialPrice] = useState(0)
    useEffect(() => {

        const breadCrumb = [
            { title: "Compras", link: "/admin/minimun_order_price/", disabled: true },
            { title: "Precio Mínimo", link: "/admin/minimun_order_price/", disabled: true }
        ]

        dispatch(
            fetchBreadcumb(breadCrumb)
        )
        dispatch(fetchPageProps({ titlePage: "Precio Mínimo" }))

    }, []) // Set breadcrumb

    useEffect(() => {
        let miInit = {
            method: 'POST',
            headers: headers,
            mode: 'cors'
        };

        fetch(host + dir_api_name + `/shop/tools/consultMinimumPrice.php`, miInit)

            .then((res) => {

                if (res.ok)

                    return res.text()

                else

                    throw res

            })
            .then((res) => {
                setPrice(Number(res))
                setInitialPrice(Number(res))
            })
            .catch((err) => console.error(err))

    }, [])

    const changeMinimumPriceOrder = () => {

        let body = "price="+price
        let miInit = {
            method: 'POST',
            headers: headers,
            body: body,
            mode: 'cors'
        };

        fetch(host + dir_api_name + `/admin/components/orders/minimum_price/changeMinimumPrice.php`, miInit)

        .then((res) => {

            if (res.ok)

                return res.text()

            else

                throw res

        })
        .then((res) => {
            setPrice(Number(res))
            setInitialPrice(Number(res))
        })
        .catch((err) => console.error(err))

    }

    return (
        <MinimumOrderPriceContainer>
            <BreadcrumCmnt />

            <MinimumOrderPriceSection1>
                <h2>Precio Mínimo</h2>
                <InputNumber
                    defaultValue={price}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={value => value.replace(/\$\s?|(\.*)/g, '')}
                    className=""
                    style={{ width: "100%" }}
                    value={price}
                    onChange={(e) => {
                        setPrice(e)
                    }}
                    id={"inputPrice"}
                />
                <Button id="changePrice" type="primary" disabled={initialPrice === price} onClick={changeMinimumPriceOrder}>
                    Cambiar
                </Button>
            </MinimumOrderPriceSection1>
        </MinimumOrderPriceContainer>
    )
}
