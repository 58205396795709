import React, { useState } from 'react'

import moment from 'moment';

import { FiltersContainer } from './Style/Styles'

import { SettingOutlined } from '@ant-design/icons';
import 'antd/lib/collapse/style/css'
import 'antd/lib/date-picker/style/css'
import locale from 'antd/es/date-picker/locale/es_ES'
import { Collapse, Select, DatePicker, Button, message } from 'antd';
import { dir_api_name, host } from '../../../../api_vars';

const { Panel } = Collapse;

const genExtra = () => (
    <SettingOutlined
        onClick={event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}
    />
);

const { Option } = Select;

export default function Reports() {



    const [initial_date, setInitialDate] = useState("")
    const [end_date, setEndDate] = useState("")
    const [type, setType] = useState("operative")
    const [orderState, setOrderState] = useState("2")

    const [loading, setLoading] = useState(false)


    const downloadExcel = () => {
        fetch(`${host}${dir_api_name}/admin/components/purchases/reports/Reporte.xlsx`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Origin: host
            }),
            mode: 'cors'
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "Reporte.xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setLoading(false)

            });
    }

    const generateReport = () => {

        const headers = new Headers();

        headers.append('Content-Type', 'application/x-www-form-urlencoded');

        headers.append('Origin', host);


        const miInit = {
            method: 'POST',
            headers: headers,
            body: `initial_date=${moment(initial_date).format("YYYY-MM-DD")}&end_date=${moment(end_date).format("YYYY-MM-DD")}&type=${type}&order_state=${orderState}`,
            mode: 'cors',
        };

        setLoading(true)


        fetch(`${host}${dir_api_name}/admin/components/purchases/reports/api_generate_report.php`, miInit).then((response) => {

            if (response.ok) {
                return response.blob();
            }

            else { throw response };

        }).then(res => downloadExcel())
            .catch(err => {

                message.error("Error: " + err)
                setLoading(false)

            })

    }


    return (
        <Collapse
            defaultActiveKey={['0']}
            expandIconPosition={"right"}
            className="filtrer-collapse-custom-collapse"
        >
            <Panel header="Generar reportes" key="1" extra={genExtra()}>
                <FiltersContainer className="flex">
                    <div>

                        <h4>Desde</h4>

                        <DatePicker locale={locale} defaultValue={initial_date} value={initial_date !== "" ? moment(initial_date) : initial_date}
                            onChange={(value) => setInitialDate(value !== null ? moment(value).format("YYYY-MM-DD") : "")} />

                    </div>

                    <div>

                        <h4>Hasta</h4>

                        <DatePicker locale={locale} defaultValue={end_date} value={end_date !== "" ? moment(end_date) : end_date}
                            onChange={(value) => setEndDate(value !== null ? moment(value).format("YYYY-MM-DD") : "")} />

                    </div>

                    <div>

                        <h4>Estado</h4>

                        <Select id="order_state" defaultValue="2" style={{ width: 120, marginLeft: "5px" }} onChange={(e)=> setOrderState(e)}>

                            <Option value="2">Confirmado</Option>
                            <Option value="4">Despachado</Option>

                        </Select>

                    </div>

                    <div>

                        <h4>Tipo de reporte</h4>

                        <Select id="type_of_report" defaultValue="operative" style={{ width: 120, marginLeft: "5px" }} onChange={(e) => setType(e)}>

                            <Option value="operative">Operativo</Option>
                            <Option value="strategic">Estrategico</Option>

                        </Select>

                    </div>

                    <div>

                        <h4>Generar</h4>

                        <Button onClick={generateReport} loading={loading}>Generar</Button>

                    </div>
                </FiltersContainer>
            </Panel>
        </Collapse>
    )
}
