import styled from "styled-components";

const ListOrdersContainer = styled.section`
    padding-top: 5vh;
    padding-bottom: 5vh;
    .filtrer-collapse-custom-collapse{
        margin-top: 5vh;
        text-align: left;
    }
`

const SearcherContainer = styled.section`
    width: 88vw;
    display: flex;
    justify-content: flex-end;
    padding-top: 5vh;
    @media(max-width: 600px){
        width: 98vw;
    }
`

const TableOrdersContainer = styled.section`
    width: 88vw;
    padding-top: 7vh;
    @media(max-width: 600px){
        width: 98vw;
    }
`

const TableOrdersHeader = styled.header`
    width: 88vw;
    border-bottom: 2px solid #7070702b;
    font-weight: 600;
    font-size: 16px
    font-family: 'Roboto', sans-serif;
    margin-top: 5vh;
    @media(max-width: 600px){
        width: 98vw;
    }
`
const TableOrdersBody = styled.section`
    padding: 20px 0px;
`

const TableOrdersBodyItemRowContainer = styled.div`
    justify-content: flex-start !important;
    padding: 10px 5px;
    margin-top: 8px;
    border: 1px solid #7070702b;
    background: white;
    cursor: pointer;
    transition: background 0.5s, margin 0.2s;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: black;
    .btnOrderStatus{
        height: 30px !important;
    }
    .position{
        font-size: 16px;
        font-weight: 600;
        font-style: italic;
    }
    .flex{
        /* justify-content: flex-start !important; */
        p{
        text-align:center;
        text-overflow: ellipsis;
        /* overflow: hidden; */
        color: #616161;
        margin:0;
        justify-content: center;
        }
    }
    .ListOrder_IntputTotal{
        border: none;
        border-bottom: 1px solid #7070702b;
        background-color: transparent;
    }
    .InputModified{
        box-shadow: 0px 0px 1px #64dd17;
        border: 2px solid #64dd17;
    }
    :nth-child(1n){
        background: #eceff1;
    }
    :nth-child(2n){
        background: white;
    }
    :hover{
        background: #babdbe;
    }
    @media(max-width: 600px){
        .ant-btn{
            padding: 2px !important;
        }
    }
`

const OrderState = styled.p`
    .Sin_Confirmar{
        background: #7070703b;
        padding: 5px
    }
    .Confirmado{
       background: #00bfa5;
       padding: 5px;
       color: white;
    }
    .Porcionado{
        background: #00c853;
        color: white;
        padding: 5px
    }
    .Despachado{
        background: #00b8d4;
        color: white;
        padding: 5px
    }
    .Cancelado{
        background: #b71c1c;
        color: white;
        padding: 5px
    }

    #orderCanceled{
        .ant-steps-item-icon{
            border-color: red !important;
        }
        svg{
            color: red !important;
        }
        .ant-steps-item-title{
            color: red !important;
        }
        .ant-steps-icon{
            color: red;
        }
    }
    .orderCanceled.ant-steps-item-process .ant-steps-icon{
        color: white !important;
    }
    .orderCanceled.ant-steps-item-process .ant-steps-item-icon{
        background-color: red !important;
    }

    .ant-steps-vertical .ant-steps-item-content{
        min-height: 40px;
    }
`

const FiltersContainer = styled.section`

    @media(max-width: 600px){
        flex-direction: column;
        gap: 10px;
    }
`

const ViewToggleContainer = styled.section`
    justify-content: flex-end;
    margin-top: 4vh;
    .ant-btn:last-child{
        margin-left: 5px;
    }
    .ant-btn{
        height: auto ;
        padding: 2px 8px;
    }
`

const TreeOrdersBodyItemRowContainer = styled.section`

    margin-bottom: 200px;

    .Tree-header{
        width: 100%;
        border-bottom: 1px solid #7070704b;
        text-align: left;
        padding-left: 15px;
        padding-right: 5px;
        padding-bottom: 7px;
        padding-top: 7px;
        font-size: 16px;
        font-weight: 500;
        justify-content: space-between;
        cursor: pointer;
        background: #7070701b;
        transition: all .5s;
        .treeLine{
            float: left;
            margin-left: -27px;
            transform: rotateX(0deg);
            transition: all 0.5s;
        }
        .collapseIcon{
            transform: rotateX(180deg);
        }
    }
    .DownBtn{
        font-size: 16px;
        transform:rotate(180deg);
        transition: all 0.5s;
    }
    .collapsed{
        .DownBtn{
            transform: rotate(0deg);
        }
        .Tree-body{
            height: 0px;
            overflow: hidden;
            border: 0px solid #7070704b;
        }
    }
    .Tree-header:hover{
        background: #7070702b;
    }
    .Tree-body{
        height: auto;
        /* border: 1px solid #7070704b; */
        transition: all 0.5s;
        background-color: white;
    }
`

const TableOrdersBodyControlsRowContainer = styled.section`
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    .ant-btn{
        padding: 0px 10px;
        height: 24px;
    }
`

const TreeCity = styled.section`

    margin-top: 10px;
    margin-left: 10px;

`
const TreeOrientations = styled.section`

    margin-top: 10px;

`

const TreeNeighborhood = styled.section`

    margin-top: 10px;
    margin-left: 10px;

`


export {
    ListOrdersContainer,
    SearcherContainer,
    TableOrdersContainer,
    TableOrdersHeader,
    TableOrdersBody,
    TableOrdersBodyItemRowContainer,
    TableOrdersBodyControlsRowContainer,
    OrderState,
    FiltersContainer,
    ViewToggleContainer,
    TreeOrdersBodyItemRowContainer,
    TreeCity,
    TreeOrientations,
    TreeNeighborhood
}