import React from 'react'

import moment from 'moment';

import { FiltersContainer } from './Style/Styles'

import { SettingOutlined } from '@ant-design/icons';
import 'antd/lib/collapse/style/css'
import 'antd/lib/date-picker/style/css'
import locale from 'antd/es/date-picker/locale/es_ES'
import { Collapse, Select, DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrderFilter } from '../../../../Redux';

const { Panel } = Collapse;

const genExtra = () => (
    <SettingOutlined
        onClick={event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}
    />
);

const { Option } = Select;

export default function Filter() {

    const filters = useSelector(state => state.orders_filter)
    const dispatch = useDispatch()


    const changeFilter = (value, filter) => {

        dispatch(
            fetchOrderFilter(
                { [`${filter}`]: value }
            )
        )
    }

    const changeDate = (value, filter) => {
        console.log(value)
        dispatch(
            fetchOrderFilter(
                { [`${filter}`]: value !== null ? moment(value).format("YYYY-MM-DD") : "" }
            )
        )
    }


    const date = () => {
        return filters.date_delivery !== "" && filters.date_delivery !== "Invalid date" ? moment(filters.date_delivery) : ""
    }
    return (
        <Collapse
            defaultActiveKey={['1']}
            expandIconPosition={"right"}
            className="filtrer-collapse-custom-collapse"
        >
            <Panel header="Filtros" key="1" extra={genExtra()}>
                <FiltersContainer className="flex">
                    <div>

                        <h4>Fecha de entrega</h4>

                        <DatePicker locale={locale} defaultValue={date()} value={date()} onChange={(e) => changeDate(e, "date_delivery")} />

                    </div>

                    <div>

                        <h4>Tipo de pago</h4>

                        <Select id="type_of_payment" defaultValue={filters.type_payment} style={{ width: 120, marginLeft: "5px" }} onChange={(e) => changeFilter(e, "type_payment")}>

                            <Option value="">Todos</Option>
                            <Option value="Efectivo">Efectivo</Option>
                            <Option value="Transferencia Electronica">Transferecia Electrónica</Option>
                            <Option value="Tarjeta de Crédito o Débito">Tarjeta de Crédito o Débito</Option>
                            <Option value="Código QR">Código QR</Option>

                        </Select>

                    </div>

                    <div>

                        <h4>Estado</h4>

                        <Select id="state" defaultValue={filters.state} style={{ width: 120, marginLeft: "5px" }} onChange={(e) => changeFilter(e, "state")}>

                            <Option value="">Todos</Option>
                            <Option value="0">Sin Confirmar</Option>
                            <Option value="1">Cancelado</Option>
                            <Option value="2">Confirmado</Option>
                            <Option value="3">Porcionado</Option>
                            <Option value="4">Despachado</Option>
                            <Option value="5">Entregado</Option>

                        </Select>

                    </div>
                </FiltersContainer>
            </Panel>
        </Collapse>
    )
}
