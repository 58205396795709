import { message, Popconfirm, Popover } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { currencyCountry, dir_api_name, host, styleCurrency } from '../../../api_vars';
import { fetchBreadcumb } from '../../../Redux';
import BreadcrumCmnt from '../../BreadcrumCmnt';
import Button from '@material-ui/core/Button';
import { RouteBody, RouteContainer, RouteControls, RouteHeader, RouteItem, RouteItemBody, RouteItemControls, StateLine } from './DeliveryManRoute/DeliveryManRouteStyles/RouteStyles';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';

const RouteAdminOptions = styled.section`
    justify-content: flex-end;
`

export default function DeliveryManRoute() {

    const { id_user, id_route } = useParams()

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)

    const [routePoints, setRoutePoints] = useState([
        {
            id: 1,
            position: 0,
            address: "Cargando ...",
            payment_state: "Cargando ...",
            delivery_state: "Entregado",
            total: 0
        },
        {
            id: 1,
            position: 1,
            address: "Cargando ...",
            payment_state: "Cargando ...",
            delivery_state: "En camino",
            total: 0
        },
        {
            id: 1,
            position: 2,
            address: "Cargando ...",
            payment_state: "Cargando ...",
            delivery_state: "Despachado",
            total: 0
        }
    ])

    const [routeState, setRouteState] = useState("En espera")

    const ConsultRoutes = () => {

        const data = `route_id=${id_route}`;
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);
        const miInit = {
            method: 'POST',
            headers: headers,
            body: data,
            mode: 'cors'
        };
        return fetch(host + dir_api_name + '/admin/components/delivery/api_consult_delivery_route.php', miInit).then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw res
            }
        })

    }

    const ToggleRouteState = () => {
        const data = `route_id=${id_route}&action=toggleRouteState`;
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);
        const miInit = {
            method: 'POST',
            headers: headers,
            body: data,
            mode: 'cors'
        };
        return fetch(host + dir_api_name + '/admin/components/delivery/api_route_state.php', miInit).then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw res
            }
        }).then(res => {
            setRouteState(res.state)
        }).catch(err => message.error("Error: " + err))
    }

    const ToggleRoutePointState = (routePointId, action, index) => {

        const data = `route_id=${id_route}&route_point_id=${routePointId}&action=${action}`;

        const headers = new Headers();

        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);

        const miInit = {
            method: 'POST',
            headers: headers,
            body: data,
            mode: 'cors'
        };

        return fetch(host + dir_api_name + '/admin/components/delivery/api_route_point_state.php', miInit).then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw res
            }
        }).then((res) => {

            routePoints[index].delivery_state = res.route_point_state;

            setRoutePoints([...routePoints])

        }).catch(err => message.error("Error: " + err))


    }

    const ConsultState = () => {
        const data = `route_id=${id_route}&action=getState`;
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);
        const miInit = {
            method: 'POST',
            headers: headers,
            body: data,
            mode: 'cors'
        };
        return fetch(host + dir_api_name + '/admin/components/delivery/api_route_state.php', miInit).then((res) => {
            if (res.ok) {
                return res.text()
            } else {
                throw res
            }
        })
    }

    const DownloadPayroll = (filename) => {
        fetch(`${host}${dir_api_name}/admin/components/delivery/tools/${filename}`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Origin: host
            }),
            mode: 'cors'
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again

            });
    }

    const GeneratePayroll = () => {
        const data = `route_id=${id_route}`;
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);
        const miInit = {
            method: 'POST',
            headers: headers,
            body: data,
            mode: 'cors'
        };

        setLoading(true)

        message.info("Generando recibo", 0);

        fetch(host + dir_api_name + '/admin/components/delivery/tools/api_generate_payroll.php', miInit).then((res) => {
            if (res.ok) {
                return res.text()
            } else {
                throw res
            }

        }).then(res => {

            message.destroy()
            message.success("Descargando planilla")
            setLoading(false)
            DownloadPayroll(res)
        }).catch(err => {

            message.destroy()
            message.error("Error " + err)
            setLoading(false)
        })
    }



    useEffect(() => {
        ConsultRoutes().then((res) => {
            console.log(res);
            setRoutePoints(res.routePoints)
        })
            .catch((err) => {
                setRoutePoints([{
                    id: 1,
                    position: 1,
                    name: "Error ...",
                    payment_state: "Error ...",
                    delivery_state: "Despachado",
                    total: 0
                }])
            })

        ConsultState().then(res => {
            setRouteState(res)
        }).catch(err => message.error("Error: " + err))
        const breadCrumb = [
            { title: "Domicilio", link: "/admin/delivery_route/", disabled: true },
            { title: "Domiciliarios", link: "/admin/delivery_men/", disabled: false },
            { title: "Rutas", link: "/admin/delivery_man/" + id_user, disabled: false },
            { title: "Ruta " + id_route, link: "/admin/delivery_route/" + id_user + "/" + id_route, disabled: true }
        ]

        dispatch(
            fetchBreadcumb(breadCrumb)
        )
    }, [])

    return (
        <RouteContainer>
            <BreadcrumCmnt />
            <RouteAdminOptions className="flex">
                <Button variant="contained" color={"primary"} disabled={loading} size="small" startIcon={<DownloadOutlined />} onClick={() => GeneratePayroll()}>
                    Descargar planilla
                </Button>
            </RouteAdminOptions>
            <RouteHeader className="flex">
                <div className="route_position">
                    ID
                </div>
                <div className="route_name">
                    Nombre
                </div>
                <div className="route_address">
                    Dirección
                </div>
                <div className="route_payment_method">
                    Pago
                </div>
            </RouteHeader>

            <RouteBody>
                {
                    routePoints.map((point, index) =>
                        <RouteItem>
                            {
                                point.delivery_state === "En camino" && index > 0 ? <StateLine type={"delivered"} /> : null
                            }
                            <RouteItemBody state={point.delivery_state} className="flex">
                                <div className="route_position">
                                    {point.id}
                                </div>
                                |
                                <div className="route_name">
                                    {
                                        point.name
                                    }
                                </div>
                                |
                                <div className="route_address">
                                    {
                                        point.city
                                    }
                                    <br></br>
                                    {
                                        point.neighborhood
                                    }
                                    <br></br>
                                    {
                                        point.commune
                                    }
                                </div>
                                |
                                <div className="route_payment_method">
                                    {
                                        point.payment_state === "No pagado" ? "NO" : "SI"
                                    }
                                </div>
                            </RouteItemBody>
                            <RouteItemControls className="flex">
                                {
                                    point.delivery_state !== "Postergado" ?
                                        <Popconfirm okText="Si" cancelText="No" disabled={point.delivery_state === "Entregado"} title="¿Desea entregar este pedido?" trigger="click" onConfirm={() => ToggleRoutePointState(point.id, "delivery", index)}>

                                            <button className="deliverBtn" disabled={point.delivery_state === "Entregado"}>
                                                {point.delivery_state === "Entregado" ? "Entregado" : "Entregar"}
                                            </button>

                                        </Popconfirm>

                                        : null
                                }
                                {
                                    point.delivery_state !== "Entregado" && point.delivery_state !== "Postergado" ?
                                        <Popconfirm okText="Si" cancelText="No" title="¿Desea posponer este pedido?" trigger="click" onConfirm={() => ToggleRoutePointState(point.id, "postpone", index)}>

                                            <button className="postponeDelivertBtn" disabled={point.delivery_state === "Entregado"}>
                                                Postergar
                                            </button>

                                        </Popconfirm>
                                        : null
                                }

                                <button className="linkBtn">
                                    <Link to={`/admin/routes/route/route_point/${point.id}/${id_user}/${id_route}/`}>
                                        Ver
                                    </Link>
                                </button>
                            </RouteItemControls>
                            {
                                point.delivery_state === "En camino" && routePoints.length < (index + 1) ? <StateLine type={"inComin"} /> : null
                            }
                        </RouteItem>
                    )
                }
            </RouteBody>

            <RouteControls>
                <Popconfirm okText="Si" cancelText="No" title="¿Desea inicial la ruta?" trigger="click" onConfirm={ToggleRouteState}>

                    <Button variant="contained" color={routeState === "En espera" ? "primary" : routeState === "En ruta" ? "default" : "secondary"} disabled={routeState === "Entregado"}>
                        {
                            routeState === "En espera" ? "Iniciar Ruta" : routeState === "Entregado" ? "Entregado" : routeState === "En ruta" ? "Volver de ruta" : "Finalizar ruta"
                        }
                    </Button>

                </Popconfirm>
            </RouteControls>
        </RouteContainer>
    )
}
