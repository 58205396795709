import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoadingStatus, fetchOrderFilter } from '../../../../../../../../Redux'
import { useEffect } from 'react'
import moment from 'moment'
import { DownOutlined, SwapLeftOutlined } from '@ant-design/icons';
import {
    TreeNeighborhood
} from './../../../../Style/Styles'

import CommuneNode from './CommuneNode'
import { message, Button } from 'antd'

export default function NeighborhoodNode({ title, fetchOrders, openNotification, orientation, city, sectionColor, fetchTotal }) {

    const [collapse, setCollapse] = useState(false)
    const [communes, setCommunes] = useState([])
    const neighborhood = unescape(title[0].replace("\"", "").replace("\"", ""))
    const filters = useSelector(state => state.orders_filter)
    const [total, setTotal] = useState(0)
    const dispatch = useDispatch()

    const setCommuneActions = (res) => {

        setCommunes(res)

        // dispatch(fetchLoadingStatus({}, "Set to 100%"))

        setTimeout(() => {

            // dispatch(fetchLoadingStatus({}, "Off"))

        }, 1000)
    }

    const errorMessage = (text) => {
        message.error(text, 0);
    };

    useEffect(() => {

        // dispatch(fetchLoadingStatus({}, "On"))

        let neighborhood = unescape(title[0].replace("\"", "").replace("\"", ""))

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_consult_commune.php?date=${date_delivery}&orientation=${orientation}&city=${((city))}&neighborhood=${((neighborhood))}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=neighborhood&optionValue=${((neighborhood))}&orientation=${orientation}&city=${((city))}&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let reloadDataInterval

        clearInterval(reloadDataInterval !== undefined ? reloadDataInterval : null)

        reloadDataInterval = setInterval(() => {

            if (filters.reload && filters.viewType === "ordered_list" && filters.reloadTreeOrders) {


                fetchTotal(totalQuery).then((res) => {

                    if (res > total || res < total) {

                        fetchOrders(query).then((res) => {

                            setCommuneActions(res)

                        }).catch(err => {

                            openNotification(`Error cargando datos ref: ${err}`, "error")

                        })

                    }
                    setTotal(res)

                }).catch(err => {

                    openNotification(`Error cargando datos ref: ${err}`, "error")

                })


                // dispatch(fetchLoadingStatus({}, "On"))

            }

        }, 10000)


        return () => clearInterval(reloadDataInterval)

    }, [filters, title, city, orientation])

    useEffect(() => {
        let neighborhood = unescape(title[0].replace("\"", "").replace("\"", ""))

        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_consult_commune.php?date=${date_delivery}&orientation=${orientation}&city=${((city))}&neighborhood=${((neighborhood))}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=neighborhood&optionValue=${neighborhood}&orientation=${orientation}&city=${city}&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        if (filters.reload && filters.viewType === "ordered_list") {


            message.destroy()

            fetchOrders(query).then((res) => {

                setCommuneActions(res)


            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })


            fetchTotal(totalQuery).then((res) => {

                setTotal(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })


            // dispatch(fetchLoadingStatus({}, "On"))

        }
    }, [filters])

    const collapsesSection = (e) => {
        let className = e.target.className
        if (className === "Tree-header flex neighborhood") {

            setCollapse(!collapse)
        }
    }

    return (

        <TreeNeighborhood key={title} className={collapse ? "collapsed" : ""} onClick={(e) => collapsesSection(e)}>

            <div className="Tree-header flex neighborhood" style={{ backgroundColor: sectionColor }}>
                <div className="Tree-header-title">
                    <SwapLeftOutlined className={collapse ? "treeLine collapseIcon" : "treeLine"}/> {city} - {neighborhood} | <b> Total : {total} </b>
                </div>
                <div>
                    <DownOutlined className="DownBtn" />
                </div>
            </div>

            <div className="Tree-body">
                {
                    communes.map((commune, key) =>
                        <CommuneNode key={
                            neighborhood[key]
                        }
                            title={
                                commune
                            }
                            fetchOrders={
                                fetchOrders
                            }
                            openNotification={
                                openNotification
                            }
                            city={
                                city
                            }
                            orientation={
                                orientation
                            }
                            neighborhood={
                                neighborhood
                            }
                            sectionColor={
                                sectionColor
                            }
                            fetchTotal={fetchTotal}
                        />
                    )
                }
            </div>

        </TreeNeighborhood>
    )
}