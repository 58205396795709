import styled from "styled-components";

const StateBtn = styled.span`
    background-color: ${(props) => props.state === "En ruta" ? "#6200EA" :
        props.state === "Disponible" ? "#00BFA5" :
            props.state === "Retornando" || props.state === "Entregado" ? "#9E9E9E" :
                props.state === "En espera" ? "#455a64" :
                    props.state === "No disponible" ? "#212121" :
                        "white"
    };
    width: ${props => props.width + "%"};
    /* height: 10px; */
    padding: 4px 10px;
    color: white;
    /* border-radius: 100%; */
    /* display: inline-block; */
`

const StateCircle = styled.span`
    background-color: ${(props) => props.state === "En ruta" ? "#6200EA" :
        props.state === "Disponible" ? "#00BFA5" :
            props.state === "Retornando" || props.state === "Entregado" ? "#9E9E9E" :
                props.state === "En espera" ? "#455a64" :
                    props.state === "No disponible" ? "#212121" :
                        "white"
    };
    width: 10px;
    height: 10px;
    /* padding: 4px; */
    color: white;
    border-radius: 100%;
    display: inline-block;
`

export {
    StateBtn,
    StateCircle
}