import React from 'react'
import { useState } from 'react';

// Components
import { Dropdown, Button } from 'antd';
import { PresentationsContainer } from '../Styles/Styles';
import { DownOutlined } from '@ant-design/icons';
import MenuOverlay from './Presentations/MenuOverlay';

//Styles
import 'antd/lib/dropdown/style/css'



export default function Presentations({presentations, updatePresentation, itemId, meatName, indexProduct}) {
    const [visible, setVisible] = useState(false)
    const [checked, setChecked] = useState(false)


    return (

        <PresentationsContainer>
            <Dropdown className="dropdown-presentations" overlay={
                <MenuOverlay
                    presentationsFromDB={presentations}
                    checked={checked}
                    setChecked={setChecked}
                    visible={visible}
                    UpdatePresentation={updatePresentation}
                    itemId={itemId}
                    meatName={meatName}
                    indexProduct={indexProduct}
                />}
                placement="bottomLeft" trigger={['click']} visible={visible} onClick={() => setVisible(!visible)}>

                <Button>Presentaciones<DownOutlined /></Button>

            </Dropdown>

        </PresentationsContainer>

    )

}
