import React, { useEffect, useState } from 'react'
import { host, dir_api_name } from '../../../api_vars'
import { ProductsContainer } from './Styles/Styles'
import ProductItem from './Products/ProductItem'
import { fetchBreadcumb, fetchPageProps } from '../../../Redux'
import { useDispatch } from 'react-redux'
import { message } from 'antd'
import BreadcrumCmnt from '../../BreadcrumCmnt'

export default function Admin() {
    // const host = "http://localhost/SC_API/"
    const [meatTypeList, setmeatTypeList] = useState({
        pig: [],
        beef: [],
        chicken: [],
        fish: [],
        sausages: [],
        cravings: []
    })
    const [sectionsCollapseStatus, setSectionsCollapseStatus] = useState({
        pig: 'open',
        beef: 'collapsed',
        chicken: 'collapsed',
        fish: 'collapsed',
        sausages: 'collapsed',
        cravings: 'collapsed'
    })

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);

    const dispatch = useDispatch()

    const Loadmeats = (meatType, query) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);

        const myInit = {
            method: 'POST',
            headers: headers,
            body: "type=" + query,
            mode: 'cors',
        };

        return fetch(host + dir_api_name + '/admin/components/products/api_consult_products.php', myInit).then((response) => {
            if (response.ok) return response.json()

            else { throw response; };

        }).catch(err => console.log(err))
            .then((response) => {
                setmeatTypeList((prevState) => { return { ...prevState, [`${meatType}`]: response } })
            })
    }

    const LoadAllMeats = () => {
        Loadmeats("pig", "cerdo")
            .then(() => Loadmeats("beef", "res")
                .then(() => Loadmeats("chicken", "pollo")
                    .then(() => Loadmeats("fish", "PESCADOS Y MARISCOS")
                        .then(() => Loadmeats("sausages", "CARNES FRIAS")
                            .then(() => Loadmeats("cravings", "ANTOJOS"))))))
    }

    const ChangeButtonStatus = (e, id) => {
        let button = document.getElementById(id);

        if (e !== "") {
            if (button.className !== "update_product actived") button.className += " actived";
            button.dataset.status = "actived"
        } else {
            button.className = "btn_product_controls update_product";
            button.dataset.status = "disabled"
        }

    }

    const UpdateProduct = (e, id, IdValue, meatName, indexProduct, public_price) => {

        //Data to update
        let price = document.getElementById(IdValue).value <= 0 ? public_price : document.getElementById(IdValue).value
        let presentations = meatTypeList[`${meatName}`][indexProduct].presentacion
        let name = escape(meatTypeList[`${meatName}`][indexProduct].descripcion)
        let unit = meatTypeList[`${meatName}`][indexProduct].unidad_medida
        const produc_status = meatTypeList[`${meatName}`][indexProduct].formulario
        const product_offer = meatTypeList[`${meatName}`][indexProduct].offer
        const offer_price = meatTypeList[`${meatName}`][indexProduct].offer_price

        if (presentations !== "") {
            presentations = typeof presentations === "string" ? JSON.parse(decodeURI(presentations)) : presentations

            presentations = encodeURI(JSON.stringify(presentations))
        }


        //Update Button
        let button = document.getElementById("Update-" + meatName + id)
        let buttonText = document.getElementById("Update_text-" + meatName + id)

        button.className += " loading";
        button.dataset.status = "loading"
        buttonText.innerText = "Cambiando..."

        const myInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };

        return fetch(host + dir_api_name + '/api_change_price.php?id=' + id +
            "&price=" + price +
            "&presentations=" + presentations +
            "&name=" + name +
            "&unit=" + unit +
            "&product_status=" + produc_status +
            "&product_offer=" + product_offer +
            "&offer_price=" + offer_price
            , myInit).then((response) => {
                if (response.ok) return response.text()

                else { throw response };

            }).then((response) => {
                button.className += " loaded";
                button.dataset.status = "loaded"
                buttonText.innerText = "Cambiado!"

                LoadAllMeats()

                setTimeout(() => {
                    button.className = "btn_product_controls update_product";
                    button.dataset.status = "disabled"
                    buttonText.innerText = "Actualizar producto"
                }, 2000)

            }).catch(err => {
                console.log(err)
                button.className = "update_product";
                button.dataset.status = "disabled"
                buttonText.innerText = "Error"
                setTimeout(() => {
                    buttonText.innerText = "Actualizar producto"
                }, 2000)
            })
            

    }

    const UpdateSection = (meatType) => {

        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);

        const myInit = {
            method: 'POST',
            headers: headers,
            body: "products_data=" + JSON.stringify(meatTypeList[`${meatType}`]),
            mode: 'cors',
        };

        return fetch(host + dir_api_name + '/admin/components/products/api_update_meat_section_order.php'
            , myInit).then((response) => {
                if (response.ok) return response.text()

                else { throw response };

            })
            .then((response) => {

                console.log(response)

                message.success("Actualizada")

            }).catch(err => {

                message.error("Error" + err)

            })

    }

    function UpdatePresentation(meatName, indexProduct, value, itemId) {

        console.log("MeatList "+meatTypeList[`${meatName}`])
        meatTypeList[`${meatName}`][indexProduct].presentacion = value


        setmeatTypeList({ ...meatTypeList })

        let button = document.getElementById("Update-" + meatName + itemId);

        button.className += " actived";
        button.dataset.status = "actived"

    }

    const ChangeProductStatus = (value, meatName, indexProduct) => {
        meatTypeList[`${meatName}`][indexProduct].formulario = value.toString()
        setmeatTypeList({ ...meatTypeList })
    }

    const ChangeOfferStatus = (value, meatName, indexProduct) => {


        // alert(value.toString())
        meatTypeList[`${meatName}`][indexProduct].offer = value ? "1" : "0"

        setmeatTypeList({ ...meatTypeList })

    }

    const ChangeProductUnit = (value, meatName, indexProduct) => {
        meatTypeList[`${meatName}`][indexProduct].unidad_medida = value
        setmeatTypeList({ ...meatTypeList })
    }

    const ChangeName = (value, meatName, indexProduct) => {
        meatTypeList[`${meatName}`][indexProduct].descripcion = value
        setmeatTypeList({ ...meatTypeList })
    }

    const UpdateItem = (value, meatName, indexProduct, cellName) => {
        meatTypeList[`${meatName}`][indexProduct][`${cellName}`] = value
        setmeatTypeList({ ...meatTypeList })
    }

    const DeleteProduct = (event, id) => {
        const myInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };

        fetch(host + dir_api_name + '/api_delete_product.php?id=' + id, myInit).then((response) =>
            response.text()
        ).catch((error) => console.log(error))
            .then((response) => LoadAllMeats())
    }

    const ChangeOrderInList = (meatType, index, order, direction) => {
        meatTypeList[`${meatType}`][index].order_list = order

        if (direction === "down") meatTypeList[`${meatType}`][index + 1].order_list = Number(order) - 1
        else meatTypeList[`${meatType}`][index - 1].order_list = Number(order) + 1

        meatTypeList[`${meatType}`] = meatTypeList[`${meatType}`].sort((a, b) => {
            if (Number(a.order_list) > Number(b.order_list)) {
                return 1;
            }
            if (a.order_list < b.order_list) {
                return -1;
            }
            // a must be equal to b
            return 0;
        })

        setmeatTypeList({
            ...meatTypeList
        })
    }

    useEffect(() => {

        LoadAllMeats()

    }, [])

    useEffect(() => {

        dispatch(fetchPageProps({ titlePage: "Productos" }))
        const breadCrumb = [
            { title: "Productos", link: "/admin/product_list/", disabled: true },
            { title: "Lista de Productos", link: "/admin/product_list/", disabled: true }
        ]

        dispatch(fetchBreadcumb(breadCrumb))
    }, [])

    return (
        <ProductsContainer className="Form FormAdmin">
            <BreadcrumCmnt />
            <section className="Form-body FormAdmin-body">

                <div id="stage-0" data-stage="0" className="Form-body-section1">

                    {/* ||||||||||| CERDO ||||||||||| */}

                    <ProductItem
                        meatSection={meatTypeList.pig}
                        meatName={"pig"}
                        sectionTitle={"Cerdo"}
                        ChangeButtonStatus={ChangeButtonStatus}
                        setSectionsCollapseStatus={setSectionsCollapseStatus}
                        sectionsCollapseStatus={sectionsCollapseStatus}
                        UpdateProduct={UpdateProduct}
                        UpdatePresentation={UpdatePresentation}
                        ChangeProductStatus={ChangeProductStatus}
                        ChangeProductUnit={ChangeProductUnit}
                        ChangeName={ChangeName}
                        DeleteProduct={DeleteProduct}
                        ChangeOfferStatus={ChangeOfferStatus}
                        UpdateItem={UpdateItem}
                        ChangeOrderInList={ChangeOrderInList}
                        UpdateSection={UpdateSection}
                    />

                    {/* ||||||||||| FIN CERDO ||||||||||| */}

                    {/* ||||||||||| RES ||||||||||| */}

                    <ProductItem
                        meatSection={meatTypeList.beef}
                        meatName={"beef"}
                        sectionTitle={"Res"}
                        ChangeButtonStatus={ChangeButtonStatus}
                        setSectionsCollapseStatus={setSectionsCollapseStatus}
                        sectionsCollapseStatus={sectionsCollapseStatus}
                        UpdateProduct={UpdateProduct}
                        UpdatePresentation={UpdatePresentation}
                        ChangeProductStatus={ChangeProductStatus}
                        ChangeProductUnit={ChangeProductUnit}
                        ChangeName={ChangeName}
                        DeleteProduct={DeleteProduct}
                        ChangeOfferStatus={ChangeOfferStatus}
                        UpdateItem={UpdateItem}
                        ChangeOrderInList={ChangeOrderInList}
                        UpdateSection={UpdateSection}
                    />

                    {/* ||||||||||| FIN RES ||||||||||| */}

                    {/* ||||||||||| POLLO ||||||||||| */}

                    <ProductItem
                        meatSection={meatTypeList.chicken}
                        meatName={"chicken"}
                        sectionTitle={"Pollo"}
                        ChangeButtonStatus={ChangeButtonStatus}
                        setSectionsCollapseStatus={setSectionsCollapseStatus}
                        sectionsCollapseStatus={sectionsCollapseStatus}
                        UpdateProduct={UpdateProduct}
                        UpdatePresentation={UpdatePresentation}
                        ChangeProductStatus={ChangeProductStatus}
                        ChangeProductUnit={ChangeProductUnit}
                        ChangeName={ChangeName}
                        DeleteProduct={DeleteProduct}
                        ChangeOfferStatus={ChangeOfferStatus}
                        UpdateItem={UpdateItem}
                        ChangeOrderInList={ChangeOrderInList}
                        UpdateSection={UpdateSection}
                    />

                    {/* ||||||||||| FIN POLLO ||||||||||| */}

                    {/* ||||||||||| PESCADON ||||||||||| */}

                    <ProductItem
                        meatSection={meatTypeList.fish}
                        meatName={"fish"}
                        sectionTitle={"Pescados y Mariscos"}
                        ChangeButtonStatus={ChangeButtonStatus}
                        setSectionsCollapseStatus={setSectionsCollapseStatus}
                        sectionsCollapseStatus={sectionsCollapseStatus}
                        UpdateProduct={UpdateProduct}
                        UpdatePresentation={UpdatePresentation}
                        ChangeProductStatus={ChangeProductStatus}
                        ChangeProductUnit={ChangeProductUnit}
                        ChangeName={ChangeName}
                        DeleteProduct={DeleteProduct}
                        ChangeOfferStatus={ChangeOfferStatus}
                        UpdateItem={UpdateItem}
                        ChangeOrderInList={ChangeOrderInList}
                        UpdateSection={UpdateSection}
                    />

                    {/* ||||||||||| FIN PESCADO ||||||||||| */}

                    {/* ||||||||||| CARNES FRÍAS ||||||||||| */}

                    <ProductItem
                        meatSection={meatTypeList.sausages}
                        meatName={"sausages"}
                        sectionTitle={"Carnes Frías"}
                        ChangeButtonStatus={ChangeButtonStatus}
                        setSectionsCollapseStatus={setSectionsCollapseStatus}
                        sectionsCollapseStatus={sectionsCollapseStatus}
                        UpdateProduct={UpdateProduct}
                        UpdatePresentation={UpdatePresentation}
                        ChangeProductStatus={ChangeProductStatus}
                        ChangeProductUnit={ChangeProductUnit}
                        ChangeName={ChangeName}
                        DeleteProduct={DeleteProduct}
                        ChangeOfferStatus={ChangeOfferStatus}
                        UpdateItem={UpdateItem}
                        ChangeOrderInList={ChangeOrderInList}
                        UpdateSection={UpdateSection}
                    />

                    {/* ||||||||||| FIN CARNES FRÍAS ||||||||||| */}

                    {/* ||||||||||| ANTOJOS ||||||||||| */}

                    <ProductItem
                        meatSection={meatTypeList.cravings}
                        meatName={"cravings"}
                        sectionTitle={"Antojos"}
                        ChangeButtonStatus={ChangeButtonStatus}
                        setSectionsCollapseStatus={setSectionsCollapseStatus}
                        sectionsCollapseStatus={sectionsCollapseStatus}
                        UpdateProduct={UpdateProduct}
                        UpdatePresentation={UpdatePresentation}
                        ChangeProductStatus={ChangeProductStatus}
                        ChangeProductUnit={ChangeProductUnit}
                        ChangeName={ChangeName}
                        DeleteProduct={DeleteProduct}
                        ChangeOfferStatus={ChangeOfferStatus}
                        UpdateItem={UpdateItem}
                        ChangeOrderInList={ChangeOrderInList}
                        UpdateSection={UpdateSection}
                    />

                    {/* ||||||||||| FIN ANTOJOS ||||||||||| */}

                </div>

            </section>


        </ProductsContainer>

    )
}
