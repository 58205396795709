import { Button, Collapse, DatePicker, Select } from 'antd'
import React, { useState } from 'react'

import { SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES'


import { dir_api_name, host } from '../../../../../api_vars';
import { FiltersContainer } from '../../../Purchases/List_orders/Style/Styles';

const { Panel } = Collapse;

const genExtra = () => (
    <SettingOutlined
        onClick={event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}
    />
);

const { Option } = Select;

export default function FilterPurchasesTimeline({ setData }) {



    const [initial_date, setInitialDate] = useState("")
    const [end_date, setEndDate] = useState("")
    const [type, setType] = useState("days")

    const [loading, setLoading] = useState(false)

    const consultProducts = () => {

        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);

        const myInit = {
            method: 'GET',
            headers: headers,
            // body: `initial_date=${moment(initial_date).format("YYYY-MM-DD")}&end_date=${moment(end_date).format("YYYY-MM-DD")}&type=${type}`,
            mode: 'cors',
        };

        setLoading(true)

        return fetch(host + dir_api_name + `/admin/components/purchases/stadistics/api_stadistics_purchases_timeline.php?type=${type}&initial_date=${initial_date}&end_date=${end_date}`, myInit).then((response) => {
            if (response.ok) return response.json()

            else { throw response; };

        }).then((response) => {
            setData(prevState => {prevState[0].data = [...response]; return [...prevState]})
            setLoading(false)
        })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })



    }
    return (
        <Collapse
            defaultActiveKey={['0']}
            expandIconPosition={"right"}
            className="filtrer-collapse-custom-collapse"
        >
            <Panel header="Generar estadísticas" key="1" extra={genExtra()}>
                <FiltersContainer className="flex">

                    <div>

                        <h4>Tipo de fecha</h4>

                        <Select id="type_of_report" defaultValue="days" style={{ width: 120, marginLeft: "5px" }} onChange={(e) => setType(e)}>

                            <Option value="days">Días</Option>
                            <Option value="months">Mes</Option>

                        </Select>

                    </div>
                    <div>

                        <h4>Desde</h4>

                        <DatePicker locale={locale} defaultValue={initial_date} value={initial_date !== "" ? moment(initial_date) : initial_date}
                            onChange={(value) => setInitialDate(value !== null ? moment(value).format("YYYY-MM-DD") : "")} />

                    </div>

                    <div>

                        <h4>Hasta</h4>

                        <DatePicker locale={locale} defaultValue={end_date} value={end_date !== "" ? moment(end_date) : end_date}
                            onChange={(value) => setEndDate(value !== null ? moment(value).format("YYYY-MM-DD") : "")} />

                    </div>

                    {/* <div>

                        <h4>Estado</h4>

                        <Select id="order_state" defaultValue="2" style={{ width: 120, marginLeft: "5px" }} onChange={(e) => setOrderState(e)}>

                            <Option value="2">Confirmado</Option>
                            <Option value="4">Despachado</Option>

                        </Select>

                    </div> */}

                    <div>

                        <h4>Consultar</h4>

                        <Button onClick={consultProducts} loading={loading}>Consultar</Button>

                    </div>
                </FiltersContainer>
            </Panel>
        </Collapse>)
}
