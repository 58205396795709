import styled from "styled-components";


const APiOrderContainer = styled.section`
    min-width:80vw;
    height:50vh;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    h1{
        font-size:24px;
        margin-bottom: 5vh;
    }
    input{
        border: none;
        border-bottom: 2px solid #7070707d;
    }
    #btnConsultOrder{
        margin-left: 1vw;
    }
`

const NotesContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
    margin-left: 10px;
    width: 450%;
    label{
        margin-bottom: 1vh;
        font-size: 14px;
        font-weight:600;
        border-bottom: 1px solid #979797;
        padding-right: 150px;
    }
    textarea{
        border: 1px solid #7070704b;
        padding: 10px;
        width:100%;
        height: 20vh;
        @media (width:1366px) {
            width: 80%;
        }
    }
    @media (max-width:600px) {
        width: 100%;
    }
`

export { APiOrderContainer, NotesContainer }