import React from 'react';

// import '../../node_modules/antd/lib/style/css'

import 'antd/lib/menu/style/css'
import BusinessIcon from '@material-ui/icons/Business';

import { Menu } from 'antd';
import {
    ShopOutlined,
    ShoppingOutlined,
    FileTextOutlined,
    LogoutOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    BranchesOutlined,
    TabletOutlined,
    FieldTimeOutlined,
    SlidersOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    UserAddOutlined,
    CarOutlined
} from '@ant-design/icons';
import { host, dir_api_name, testMode } from '../api_vars';
import { MenuStylesPC, MenuContainer, MenuStylesMobile } from './Menu/Styles/Styles';
import { Link } from 'react-router-dom';
import { checkRol } from './Util/checkRol';

const { SubMenu } = Menu;

const DispositiveCheck = () => {
    return window.screen.width > 650 ? "PC" : "MOBILE"
}
const MenuStyles = DispositiveCheck() === "PC" ? MenuStylesPC : MenuStylesMobile

const headers = new Headers();
headers.append('Content-Type', 'application/x-www-form-urlencoded');
headers.append('Origin', host);

class Aside_Menu extends React.Component {
    state = {
        collapsed: DispositiveCheck() === "PC" ? true : true,
        rol: testMode? 3 : 0
    };
    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };


    closeSession = () => {

        let miInit = {
            method: 'POST',
            headers: headers,
            mode: 'cors'
        };
        fetch(host + dir_api_name + `/users/Auth/salir.php`, miInit)

            .then((res) => {

                if (res.ok)

                    return res.text()

                else

                    throw res

            })
            .then((res) => {
                if (localStorage.getItem("session")) {
                    if (localStorage.getItem("session") === "on") {
                        localStorage.removeItem("session")
                        window.location.href = "/login/"
                    }
                }
                window.location.href = "/login/"
            })
            .catch((err) => console.error(err))
    }

    clickLinkTo = (id) => {
        document.getElementById(id).click()
    }
    componentDidMount() {
        checkRol("").then((res) => {

            console.log("Rol in Menu " + res)

            this.setState({
                rol: testMode? 3 : res
            })

        })
    }
    render() {
        return (
            <MenuContainer>
                {/* <Button type="primary" onClick={this.toggleCollapsed} style={{ marginBottom: 16 }}>
                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                </Button> */}
                <Menu
                    defaultSelectedKeys={['list_products']}
                    defaultOpenKeys={['close']}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={this.state.collapsed}
                    style={MenuStyles}
                >

                    <Menu.Item key="close" icon={this.state.collapsed ? <MenuUnfoldOutlined /> : null} onClick={this.toggleCollapsed}>
                        {this.state.collapsed ? null : <MenuFoldOutlined />} {this.state.collapsed ? "Abrir" : "Cerrar"}
                    </Menu.Item>

                    {
                        this.state.rol > 2 ?
                            <SubMenu key="sub1" icon={<ShopOutlined />} title="Productos">
                                <Menu.Item key="list_products" onClick={() => this.clickLinkTo("/admin/product_list/")}><Link id="/admin/product_list/" to="/admin/product_list/">Lista de Productos</Link></Menu.Item>
                                <Menu.Item key="add_product" onClick={() => this.clickLinkTo("/admin/add_product/")}><Link id="/admin/add_product/" to="/admin/add_product/">Añadir Producto</Link></Menu.Item>
                                <Menu.Item key="products_stats" onClick={() => this.clickLinkTo("/admin/products_stats/")}><Link id="/admin/products_stats/" to="/admin/products_stats/">Estadísticas</Link></Menu.Item>
                            </SubMenu>
                            : null
                    }

                    <SubMenu key="sub2" icon={<ShoppingOutlined />} title="Compras">
                        <Menu.Item key="API_Pedidos" icon={<BranchesOutlined />} onClick={() => this.clickLinkTo("/admin/api_orders/")}><Link id="/admin/api_orders/" to="/admin/api_orders/">Api pedidos</Link></Menu.Item>
                        <Menu.Item key="BloqueoFechas" icon={<FieldTimeOutlined />} onClick={() => this.clickLinkTo("/admin/date_availability/")}><Link id="/admin/date_availability/" to="/admin/date_availability/">Disponibilidad</Link></Menu.Item>
                        {
                            this.state.rol > 2 ?
                                <Menu.Item key="9" icon={<FileTextOutlined />} onClick={() => this.clickLinkTo("generate_excel")}><a id="generate_excel" href={host + dir_api_name + "/generate_excel.php?id=1"}>Descargar informe</a></Menu.Item>
                                : null
                        }
                        {
                            this.state.rol > 2 ?
                                <Menu.Item key="minimum_order_price" icon={<SlidersOutlined />} onClick={() => this.clickLinkTo("/admin/minimun_order_price/")}><Link id="/admin/minimun_order_price/" to="/admin/minimun_order_price/">Precio Mínimo</Link></Menu.Item>
                                : null
                        }
                        {
                            this.state.rol > 2 ?
                                <Menu.Item key="purchases_timeline" icon={<SlidersOutlined />} onClick={() => this.clickLinkTo("/admin/purchases_timeline/")}><Link id="/admin/purchases_timeline/" to="/admin/purchases_timeline/">Estadísticas | Número de compras</Link></Menu.Item>
                                : null
                        }
                        {
                            this.state.rol > 2 ?
                                <Menu.Item key="purchases_ubications_chart" icon={<SlidersOutlined />} onClick={() => this.clickLinkTo("/admin/purchases_ubications_chart/")}><Link id="/admin/purchases_ubications_chart/" to="/admin/purchases_ubications_chart/">Estadísticas | Ubicaciones</Link></Menu.Item>
                                : null
                        }
                        <Menu.Item key="addOrder" icon={<ShoppingCartOutlined />} onClick={() => this.clickLinkTo("/admin/add_order/")}><Link id="/admin/add_order/" to="/admin/add_order/">Añadir pedido</Link></Menu.Item>
                        <Menu.Item key="Tabla_Pedidos" icon={<TabletOutlined />} onClick={() => this.clickLinkTo("/admin/table_orders/1/")}><Link id="/admin/table_orders/1/" to="/admin/table_orders/1/">Tabla pedidos</Link></Menu.Item>
                    </SubMenu>

                    <SubMenu key="sub3" icon={<UserOutlined />} title="Usuarios">
                        {
                            this.state.rol > 2 ?
                                <Menu.Item key="addUser" icon={<UserAddOutlined />} onClick={() => this.clickLinkTo("/admin/add_user/")}><Link id="/admin/add_user/" to="/admin/add_user/">Añadir Usuario</Link></Menu.Item>
                                : null
                        }
                    </SubMenu>

                    <SubMenu key="sub4" icon={<CarOutlined />} title="Domicilio">
                        {
                            this.state.rol > 1 ?
                                <Menu.Item key="delivery_men" icon={<UserOutlined />} onClick={() => this.clickLinkTo("/admin/delivery_men/")}><Link id="/admin/delivery_men/" to="/admin/delivery_men/">Domiciliarios</Link></Menu.Item>
                                : null
                        }
                    </SubMenu>

                    {/* <SubMenu key="sub5" icon={<div><BusinessIcon style={{fontSize: "18px"}} /></div>} title="Por Mayores">
                        {
                            this.state.rol > 1 ?
                                <Menu.Item key="delivery_men" icon={<UserOutlined />} onClick={() => this.clickLinkTo("/admin/add_retail_users/")}><Link id="/admin/add_retail_users/" to="/admin/add_retail_users/">Añadir Usuario Retail</Link></Menu.Item>
                                : null
                        }
                    </SubMenu> */}

                    <Menu.Item key="out" icon={<LogoutOutlined />} onClick={() => this.closeSession()}>Cerrar sesión</Menu.Item>

                </Menu>

            </MenuContainer>
        );
    }
}

export default Aside_Menu