import React, { useState, useEffect } from 'react'
import { TreeSelect } from 'antd';


import 'antd/lib/tree-select/style/css'
import { dir_api_name, host } from '../../../../api_vars';


const { TreeNode } = TreeSelect;

export default function Address({ parent, setCart, setUserData, userData }) {

    const [address, setAddress] = useState()
    const [cities, setCities] = useState([])
    const [neighBorHoods, setNeighBorHoods] = useState({})
    const [commune, setCommune] = useState({})
    const [loading, setLoading] = useState(true)

    const onChange = value => {
        console.log(value);
        if (value === undefined) {
            setAddress(value)
            setCart(prevState => {
                return {
                    ...prevState,
                    delivery_price: ""
                }
            })


            setUserData(prevState => {
                return {
                    ...prevState,
                    idDirection: "",
                    orientation: "",
                    city: "",
                    neighborhood: "",
                    commune: "",
                    addressID: ``
                }
            })
        }
    };

    const consulCities = () => {
        const url = host + dir_api_name + "/api_consult_address.php"
        const query = `?type=cities`

        const headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        const miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err)).then(citiesRes => {
            setCities(citiesRes)

            citiesRes.map((citiesItem, key) =>

                consultNeighborhoods(citiesItem.city).then(

                    neighborhoodRes => {

                        setNeighBorHoods(prevState => { return { ...prevState, [`${citiesItem.city}`]: neighborhoodRes } })

                        neighborhoodRes.map((commune, key2) =>

                            consultCommune(commune.neighborhood, citiesItem.city).then(
                                communeRes => {
                                    setCommune(prevState => { return { ...prevState, [`${citiesItem.city}-${commune.neighborhood}`]: communeRes } })
                                    if (neighborhoodRes.length === (key2 + 1) && citiesRes.length === (key + 1)) {
                                        setLoading(false)
                                    }
                                }
                            )

                        )
                    }
                )
            )
        })

    }

    const consultNeighborhoods = (city) => {
        const url = host + dir_api_name + "/api_consult_address.php"
        const query = `?type=neighborhoods&city=${city}`

        const headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        const miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err))
    }

    const consultCommune = (neighborhood, city) => {
        const url = host + dir_api_name + "/api_consult_address.php"
        const query = `?type=commune&neighborhood=${neighborhood}&city=${city}`

        const headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        const miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err))
    }


    const setAddres = (id, orientation, city, neighborhood, commune, price, key, key2, key3) => {


            setAddress(`${city} ${neighborhood} ${commune} ${key}-${key2}-${key3}`)

            setCart(prevState => {
                return {
                    ...prevState,
                    delivery_price: Number(price)
                }
            })


            setUserData(prevState => {
                return {
                    ...prevState,
                    idDirection: id,
                    orientation: orientation,
                    city: ((city)),
                    neighborhood: ((neighborhood)),
                    commune: ((commune)),
                    addressID: `${city} ${neighborhood} ${commune}`
                }
            })



    }



    useEffect(() => {

        consulCities()

    }, [])


    return (
        <TreeSelect
            showSearch
            style={{ width: '100%' }}
            value={address}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Buscar ubicación:"
            allowClear
            onChange={onChange}
            id="userData-location"
        >
            {
                loading ?
                    <TreeNode value="Default" title="Cargando..." selectable={false}></TreeNode>
                    :
                    cities.map(
                        (item, key) =>
                            <TreeNode value={`${item.city} ${key}`} title={item.city} onClick={(e) => console.log(e)} selectable={false}>
                                {
                                    neighBorHoods[`${item.city}`] ? neighBorHoods[`${item.city}`].map(

                                        (item2, key2) => <TreeNode value={`${item2.neighborhood}  ${key}-${key2}`} title={item2.neighborhood} selectable={false}>

                                            {
                                                commune[`${item.city}-${item2.neighborhood}`] ? commune[`${item.city}-${item2.neighborhood}`].map(

                                                    (item3, key3) =>
                                                        <TreeNode value={`${item.city} ${item2.neighborhood} ${item3.commune} ${key}-${key2}-${key3}`}
                                                            title=
                                                            {
                                                                <p style={{ margin: 0 }} onClick={() => setAddres(item3.id, item3.orientation, item.city, item2.neighborhood, item3.commune, item3.rate, key, key2, key3)}> {item.city} / {item2.neighborhood} / {item3.commune}</p>
                                                            }
                                                        />
                                                )
                                                    : null
                                            }

                                        </TreeNode>
                                    ) : null
                                }
                            </TreeNode>
                    )
            }
        </TreeSelect>
    );

}