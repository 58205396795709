import styled from "styled-components"

const MenuStylesPC = {
    height: 100 + "vh",
    position: "fixed",
    maxWidth: 18 + "vw",
    marginTop: "17vh",
    zIndex: 1000000000
}

const MenuStylesMobile = {
    height: 100 + "vh",
    position: "fixed",
    maxWidth: 55 + "vw",
    marginTop: "17vh"
}

const MenuContainer = styled.section`
    width: 256px;
    user-select: none;
    .ant-menu-item{
        a{
            color: white !important;
        }
    }
    .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
        background-color: #b9180d;
    }
    @media (max-width:600px) {
        width: 40px;
        .ant-menu-inline-collapsed{
        width: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        }
        .ant-menu-item.ant-menu-item-selected{
            padding: 0px 18px;
        }
    }
`

export { MenuStylesPC, MenuContainer, MenuStylesMobile }

