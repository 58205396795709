import React from 'react'
import { BrowserRouter as Router, Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from '../Components/Login';
import App from '../Components/App';


export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login/" component={Login}></Route>
                <Route path="/" component={App}></Route>
            </Switch>
        </BrowserRouter>
    )
}
