import React, { useState, useEffect } from 'react'
import combosProps from './combos.json'
import { combosPropsVer2 } from './combosver2'
import { currencyCountry, styleCurrency } from '../../../../../api_vars'

import LogoPig from './../../../../../Assets/pigIcon.png'
import LogoSausages from './../../../../../Assets/sausagesIcon.jpg'

import { Button, Input, message, Popconfirm } from 'antd';


export default function CartComponent({ Cart, setCart, rol, total, setTotal, setNewTotal }) {

    const [combos, setCombos] = useState(
        combosProps.combos
    ) // Combos
    const { TextArea } = Input;
    const changeNote = (value, meatType, index) => {

        Cart[meatType][index].note = value

    }

    const editItem = (e, meatType, index) => {

        let btn = e.target


        if (btn.innerHTML === "Agregar") {

            Cart[meatType][index].disabled = false

            console.log(Cart)

            setCart(prevState => {
                return {
                    ...prevState,
                    Cart
                }
            })

        } else {

            Cart[meatType][index].disabled = true

            setCart(prevState => {
                return {
                    ...prevState,
                    Cart
                }
            })

        }

    }

    const quantity = (value) => {
        if (value === undefined) {
            return 1
        }
        else {
            return value
        }
    }

    useEffect(() => {
        let summaryPromotions = 0
        let summaryPig = 0
        let summaryBeef = 0
        let summaryChicken = 0
        let summaryFish = 0
        let summarySausages = 0
        let summaryCravings = 0
        let summaryCombos = 0
        let summarySearch = 0
        let total


        Cart.promotions.forEach(item => {
            summaryPromotions += Number(item.price)
        })
        Cart.pig.forEach(item => {
            if (item.finalPrice === undefined || item.finalPrice < 1) {
                summaryPig += item.price
            } else {
                summaryPig += item.finalPrice
            }
        })
        Cart.beef.forEach(item => {
            let price = item.finalPrice === undefined || item.finalPrice === "" ? item.price : (item.finalPrice)
            summaryBeef += price
        })
        Cart.chicken.forEach(item => {
            let price = item.finalPrice === undefined || item.finalPrice === "" ? item.price : (item.finalPrice)
            summaryChicken += price
        })
        Cart.fish.forEach(item => {
            let price = item.finalPrice === undefined || item.finalPrice === "" ? item.price : (item.finalPrice)
            summaryFish += price
        })
        Cart.sausages.forEach(item => {
            let price = item.finalPrice === undefined || item.finalPrice === "" ? item.price : (item.finalPrice)
            summarySausages += price
        })
        Cart.cravings.forEach(item => {
            if (item.finalPrice === undefined || item.finalPrice < 1) {
                summaryCravings += item.price
            } else {
                summaryCravings += item.finalPrice
            }
        })
        Cart.search.forEach(item => {
            let price = item.finalPrice === undefined || item.finalPrice === "" ? item.price : (item.finalPrice)
            summarySearch += price
        })

        Cart.promotions.forEach(item => {
            let price = item.finalPrice === undefined || item.finalPrice === "" ? item.price : (item.finalPrice)
            summaryPromotions += price
        })
        Cart.combos.forEach(item => {

            summaryCombos += item.price

        })

        total = summaryPig + summaryBeef + summaryChicken + summaryFish + summarySausages + summaryCravings + summaryCombos + summaryPromotions + summarySearch

        // alert(total)

        setTotal(total)
        console.log(Cart.combos)

    }, [Cart]) // Use state by cart

    const changeTotal = (e) => {
        let value = Number(e.target.value)

        setTotal(value)
        setNewTotal(value)
    }
    const CheckTypeOfCut = (value) => value === "" ? "" : value
    return (
        <div className="Form-footer-cart-body">
            <ul>
                {
                    Cart.search.length === 0 && Cart.promotions.length === 0 && Cart.pig.length === 0 && Cart.beef.length === 0 && Cart.chicken.length === 0 && Cart.fish.length === 0 && Cart.sausages.length === 0 && Cart.cravings.length === 0
                        && Cart.combos.length === 0 ?
                        <li style={{ color: "#707070" }}>Vacío</li> : null
                }
                {
                    Cart.search.map((item, index) =>
                        <li>
                            {/* 🐷 */}
                            <span role="img" aria-label="Favoritos"></span>
                            {" "}
                            <span style={{ fontSize: "18px" }}>
                                <i style={{ textTransform: "lowercase" }}>{item.name} x {" "}</i>
                            </span>
                            <b style={{ fontSize: "20px" }}>{item.quantity} </b>
                            <i> {item.unit} </i> - {" "}
                            <b style={{ textTransform: "uppercase" }}><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                            <div className="notes">
                                <b>Nota:</b>
                                {
                                    item.disabled !== undefined && item.disabled !== false ?
                                        <span>
                                            {item.note}
                                        </span>
                                        : ""
                                }
                                {
                                    item.disabled !== undefined && item.disabled !== true ?

                                        <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value, "search", index)}>


                                        </TextArea>

                                        : null
                                }
                                <br/>
                                <Button className="editBtn" type="ghost" style={{ display: item.disabled === undefined || item.disabled ? "" : "none" }} onClick={(e) => editItem(e, "search", index)}>
                                    {item.disabled === undefined || item.disabled ? "Agregar" : null}
                                </Button>
                            </div>
                            Total :

                            <b>
                                {item.offer === "1" ?
                                    <span>
                                        <s>
                                            {Number(item.finalPrice === undefined ? item.initial_price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                        </s>
                                        <span style={{ color: "#00c853" }}>
                                            {Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString(currencyCountry, styleCurrency)}
                                        </span>
                                    </span>

                                    :
                                    Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

                                }
                            </b>

                            <br />

                            {/* <br />

                            <b>Peso : </b>

                            <input type="number" onInput={(e) => changeWeightProduct("pig", index, e)}
                                placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"}
                                // eslint-disable-next-line no-mixed-operators
                                readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                        </li>
                    )
                }
                {
                    Cart.promotions.map((item, index) =>
                        <li>
                            {/* 🐷 */}
                            <span role="img" aria-label="Favoritos">⭐</span>
                            {" "}
                            <span style={{ fontSize: "18px" }}>
                                <i style={{ textTransform: "lowercase" }}>{item.name} x {" "}</i>
                            </span>
                            <b style={{ fontSize: "20px" }}>{item.quantity} </b>
                            <i> {item.unit} </i> - {" "}
                            <b style={{ textTransform: "uppercase" }}><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                            <div className="notes">
                                <b>Nota:</b>
                                {
                                    item.disabled !== undefined && item.disabled !== false ?
                                        <span>
                                            {item.note}
                                        </span>
                                        : ""
                                }
                                {
                                    item.disabled !== undefined && item.disabled !== true ?

                                        <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value, "promotions", index)}>


                                        </TextArea>

                                        : null
                                }
                                <Button className="editBtn" type="ghost" style={{ display: item.disabled === undefined || item.disabled ? "" : "none" }} onClick={(e) => editItem(e, "promotions", index)}>
                                    {item.disabled === undefined || item.disabled ? "Agregar" : null}
                                </Button>
                            </div>

                            Total :

                            <b>
                                {item.offer === "1" ?
                                    <span>
                                        <s>
                                            {Number(item.finalPrice === undefined ? item.initial_price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                        </s>
                                        <span style={{ color: "#00c853" }}>
                                            {Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString(currencyCountry, styleCurrency)}
                                        </span>
                                    </span>

                                    :
                                    Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

                                }
                            </b>

                            {/* <br />

                            <b>Peso : </b>

                            <input type="number" onInput={(e) => changeWeightProduct("pig", index, e)}
                                placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"}
                                // eslint-disable-next-line no-mixed-operators
                                readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                        </li>
                    )
                }
                {
                    Cart.pig.map((item, index) =>
                        <li>
                            {/* 🐷 */}
                            <img src={LogoPig} alt="Cerdo" style={{ width: "19px" }} />
                            {" "}
                            <span style={{ fontSize: "18px" }}>
                                <i style={{ textTransform: "lowercase" }}>{item.name} x {" "}</i>
                            </span>
                            <b style={{ fontSize: "20px" }}>{item.quantity} </b>
                            <i> {item.unit} </i> - {" "}
                            <b style={{ textTransform: "uppercase" }}><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                            <div className="notes">
                                <b>Nota:</b>
                                {
                                    item.disabled !== undefined && item.disabled !== false ?
                                        <span>
                                            {item.note}
                                        </span>
                                        : ""
                                }
                                {
                                    item.disabled !== undefined && item.disabled !== true ?

                                        <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value, "pig", index)}>


                                        </TextArea>

                                        : null
                                }
                                <Button className="editBtn" type="ghost" style={{ display: item.disabled === undefined || item.disabled ? "" : "none" }} onClick={(e) => editItem(e, "pig", index)}>
                                    {item.disabled === undefined || item.disabled ? "Agregar" : null}
                                </Button>
                            </div>
                            Total :
                            <b>
                                {item.offer === "1" ?
                                    <span>
                                        <s>
                                            {Number(item.finalPrice === undefined ? item.initial_price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                        </s>
                                        <span style={{ color: "#00c853" }}>
                                            {Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString(currencyCountry, styleCurrency)}
                                        </span>
                                    </span>

                                    :
                                    Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

                                }
                            </b>

                            {/* <br />

                            <b>Peso : </b>

                            <input type="number" onInput={(e) => changeWeightProduct("pig", index, e)}
                                placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"}
                                // eslint-disable-next-line no-mixed-operators
                                readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                        </li>
                    )
                }
                {
                    Cart.beef.length === 0 ? null : Cart.beef.map((item, index) =>
                        <li>
                            <span role="img" aria-label="Res">🐮</span> {" "}
                            <span style={{ fontSize: "18px" }}>
                                <i style={{ textTransform: "lowercase" }}>{item.name} x {" "}</i>
                            </span>
                            <b style={{ fontSize: "20px" }}>{item.quantity} </b>
                            <i> {item.unit} </i> - {" "}
                            <b style={{ textTransform: "uppercase" }}><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                            <div className="notes">
                                <b>Nota:</b>
                                {
                                    item.disabled !== undefined && item.disabled !== false ?
                                        <span>
                                            {item.note}
                                        </span>
                                        : ""
                                }
                                {
                                    item.disabled !== undefined && item.disabled !== true ?

                                        <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value, "beef", index)}>


                                        </TextArea>

                                        : null
                                }
                                <Button className="editBtn" type="ghost" onClick={(e) => editItem(e, "beef", index)}>
                                    {item.disabled === undefined || item.disabled ? "Agregar" : "Confirmar"}
                                </Button>
                            </div>
                            Total :
                            <b>
                                {item.offer === "1" ?
                                    <span>
                                        <s>
                                            {Number(item.finalPrice === undefined ? item.initial_price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                        </s>
                                        <span style={{ color: "#00c853" }}>
                                            {Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString(currencyCountry, styleCurrency)}
                                        </span>
                                    </span>

                                    :
                                    Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

                                }
                            </b>

                            {/* <br />

                            <b>Peso : </b>

                            <input type="number" onInput={(e) => changeWeightProduct("beef", index, e)}
                                placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"}
                                // eslint-disable-next-line no-mixed-operators
                                readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                        </li>
                    )
                }
                {
                    Cart.chicken.length === 0 ? null :
                        Cart.chicken.map((item, index) =>
                            <li>
                                {/* <img src={LogoChicken} alt="Pollo" style={{ width: "19px" }} /> */}
                                <span role="img" aria-label="Pollo">🐔</span> {" "}
                                <span style={{ fontSize: "18px" }}>
                                    <i style={{ textTransform: "lowercase" }}>{item.name} x {" "}</i>
                                </span>
                                <b style={{ fontSize: "20px" }}>{item.quantity} </b>
                                <i> {item.unit} </i> - {" "}
                                <b style={{ textTransform: "uppercase" }}><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                                <div className="notes">
                                <b>Nota:</b>
                                {
                                    item.disabled !== undefined && item.disabled !== false ?
                                        <span>
                                            {item.note}
                                        </span>
                                        : ""
                                }
                                {
                                    item.disabled !== undefined && item.disabled !== true ?

                                        <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value, "chicken", index)}>


                                        </TextArea>

                                        : null
                                }
                                <Button className="editBtn" type="ghost" style={{ display: item.disabled === undefined || item.disabled ? "" : "none" }} onClick={(e) => editItem(e, "chicken", index)}>
                                    {item.disabled === undefined || item.disabled ? "Agregar" : null}
                                </Button>
                            </div>
                                Total :
                                <b>
                                    {item.offer === "1" ?
                                        <span>
                                            <s>
                                                {Number(item.finalPrice === undefined ? item.initial_price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                            </s>
                                            <span style={{ color: "#00c853" }}>
                                                {Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString(currencyCountry, styleCurrency)}
                                            </span>
                                        </span>

                                        :
                                        Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

                                    }
                                </b>

                                {/* <b>Peso : </b>

                                <input type="number" onInput={(e) => changeWeightProduct("chicken", index, e)}
                                    placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"}
                                    // eslint-disable-next-line no-mixed-operators
                                    readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                            </li>
                        )
                }
                {
                    Cart.fish.length === 0 ? null :
                        Cart.fish.map((item, index) =>
                            <li>
                                <span role="img" aria-label="Pescado">🐟{" "}</span>
                                <span style={{ fontSize: "18px" }}>
                                    <i style={{ textTransform: "lowercase" }}>{item.name} x {" "}</i>
                                </span>
                                <b style={{ fontSize: "20px" }}>{item.quantity} </b>
                                <i> {item.unit} </i> - {" "}
                                <b style={{ textTransform: "uppercase" }}><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                                <div className="notes">
                                <b>Nota:</b>
                                {
                                    item.disabled !== undefined && item.disabled !== false ?
                                        <span>
                                            {item.note}
                                        </span>
                                        : ""
                                }
                                {
                                    item.disabled !== undefined && item.disabled !== true ?

                                        <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value, "fish", index)}>


                                        </TextArea>

                                        : null
                                }
                                <Button className="editBtn" type="ghost" style={{ display: item.disabled === undefined || item.disabled ? "" : "none" }} onClick={(e) => editItem(e, "fish", index)}>
                                    {item.disabled === undefined || item.disabled ? "Agregar" : null}
                                </Button>
                            </div>
                                Total :
                                <b>
                                    {item.offer === "1" ?
                                        <span>
                                            <s>
                                                {Number(item.finalPrice === undefined ? item.initial_price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                            </s>
                                            <span style={{ color: "#00c853" }}>
                                                {Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString(currencyCountry, styleCurrency)}
                                            </span>
                                        </span>

                                        :
                                        Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

                                    }
                                </b>

                                {/* <b> Peso : </b>

                                <input type="number" onInput={(e) => changeWeightProduct("fish", index, e)}
                                    placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"}
                                    // eslint-disable-next-line no-mixed-operators
                                    readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                            </li>
                        )
                }
                {
                    Cart.sausages.length === 0 ? null :
                        Cart.sausages.map((item, index) =>
                            <li>
                                <img src={LogoSausages} alt="Carnes Frías" style={{ width: "28px" }} /> {" "}
                                <span style={{ fontSize: "18px" }}>
                                    <i style={{ textTransform: "lowercase" }}>{item.name} x {" "}</i>
                                </span>
                                <b style={{ fontSize: "20px" }}>{item.quantity} </b>
                                <i> {item.unit} </i> - {" "}
                                <b style={{ textTransform: "uppercase" }}><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                                <div className="notes">
                                <b>Nota:</b>
                                {
                                    item.disabled !== undefined && item.disabled !== false ?
                                        <span>
                                            {item.note}
                                        </span>
                                        : ""
                                }
                                {
                                    item.disabled !== undefined && item.disabled !== true ?

                                        <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value, "sausages", index)}>


                                        </TextArea>

                                        : null
                                }
                                <Button className="editBtn" type="ghost" style={{ display: item.disabled === undefined || item.disabled ? "" : "none" }} onClick={(e) => editItem(e, "sausages", index)}>
                                    {item.disabled === undefined || item.disabled ? "Agregar" : null}
                                </Button>
                            </div>
                                Total :
                                <b>
                                    {item.offer === "1" ?
                                        <span>
                                            <s>
                                                {Number(item.finalPrice === undefined ? item.initial_price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                            </s>
                                            <span style={{ color: "#00c853" }}>
                                                {Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString(currencyCountry, styleCurrency)}
                                            </span>
                                        </span>

                                        :
                                        Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

                                    }
                                </b>

                                {/* <br />

                                <b> Peso : </b>

                                <input type="number" onInput={(e) => changeWeightProduct("sausages", index, e)}
                                    placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"}
                                    // eslint-disable-next-line no-mixed-operators
                                    readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                            </li>
                        )
                }
                {
                    Cart.cravings.length === 0 ? null :
                        Cart.cravings.map((item, index) =>
                            <li>
                                <span role="img" aria-label="Pescado">🥚{" "}</span>
                                <span style={{ fontSize: "18px" }}>
                                    <i style={{ textTransform: "lowercase" }}>{item.name} x {" "}</i>
                                </span>
                                <b style={{ fontSize: "20px" }}>{item.quantity} </b>
                                <i> {item.unit} </i> - {" "}
                                <b style={{ textTransform: "uppercase" }}><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                                <div className="notes">
                                <b>Nota:</b>
                                {
                                    item.disabled !== undefined && item.disabled !== false ?
                                        <span>
                                            {item.note}
                                        </span>
                                        : ""
                                }
                                {
                                    item.disabled !== undefined && item.disabled !== true ?

                                        <TextArea defaultValue={item.note} placeholder="Agregar una nota" onChange={(e) => changeNote(e.target.value, "cravings", index)}>


                                        </TextArea>

                                        : null
                                }
                                <Button className="editBtn" type="ghost" style={{ display: item.disabled === undefined || item.disabled ? "" : "none" }} onClick={(e) => editItem(e, "cravings", index)}>
                                    {item.disabled === undefined || item.disabled ? "Agregar" : null}
                                </Button>
                            </div>
                                Total :
                                <b>
                                    {item.offer === "1" ?
                                        <span>
                                            <s>
                                                {Number(item.finalPrice === undefined ? item.initial_price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                            </s>
                                            <span style={{ color: "#00c853" }}>
                                                {Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString(currencyCountry, styleCurrency)}
                                            </span>
                                        </span>

                                        :
                                        Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

                                    }
                                </b>


                                {/* <br />

                                <b>Peso : </b>

                                <input type="number" onInput={(e) => changeWeightProduct("cravings", index, e)}
                                    placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"}
                                    // eslint-disable-next-line no-mixed-operators
                                    readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                            </li>
                        )
                }
                {
                    Cart.combos.length === null ? null :
                        Cart.combos.map((combo, indexComboinCart) =>
                            <li key={indexComboinCart}>
                                {combo.title} x {quantity(combo.quantity)} | <b>{Number(combo.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>
                                <ul>
                                    {
                                        combo.content.map((item, indexComboInCombos) =>

                                            <li style={{ marginBottom: "1vh" }}>

                                                {item.description} x {" "}
                                                <b>{item.quantity} {item.unit} </b> -
                                                    <b><u>{" " + CheckTypeOfCut(item.TypeOfCut)}</u></b> {" "}
                                                    Total :
                                                    <b>
                                                    {Number(item.finalPrice !== "" ? item.finalPrice : item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                                                </b>

                                                {/* <br/>
                                                    <b> Peso : </b>

                                                        <input type="number" id={"combo" + combo.index + indexComboInCombos} placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"} value={item.weight}
                                                            // eslint-disable-next-line no-mixed-operators
                                                            onInput={(e) => changeWeightCombos("combo", combo.index, indexComboInCombos, indexComboinCart, e, "last")} readOnly={item.unit === "LIBRA" || item.unit === "LIBRAS" && rol >= 2 ? false : true}></input> */}

                                            </li>
                                        )
                                    }
                                </ul>
                            </li>
                        )
                }
            </ul>
            <p style={{ fontSize: "13px", marginTop: "50px" }}>Valor domicilio: <b>{Number(Cart.delivery_price).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
            <p style={{ fontSize: "16px" }}>
                Total: {""}
                <input type="text" placeholder={Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}
                    disabled={rol === "2" || rol === "3" ? false : true}
                    onChange={e => changeTotal(e)}
                />
            </p>
        </div>
    )
}
