import styled from "styled-components"

const HeaderTop = styled.header`
    background-color: #ffc000;
    color: #ba170d;
    font-weight: 700;
    font-size: 27px;
    text-align: center;
    height: 7vh;
    border-top: 5px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    z-index:1000;
    p {
        margin: 0;
        user-select: none;
    }
    @media (max-width:600px) {
        p {
            font-size: 18px
        }
    }
`

const HeaderBottom = styled.header`
        background-color: #b9180d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15vw;
        border-bottom: 1px solid #9a160d;
        box-shadow: 0px 0px 2px rgba($color: #b9180d, $alpha: 0.3);
        position: fixed;
        width: 100vw;
        margin-top: 7vh;
        z-index:1000;
        img {
            width: 8vh;
            margin-left: 5vw;
        }
        h1 {
            color: white;
            margin-left: 4vw;
        }
        @media (max-width: 600px) {
            padding-right: 5vw;
            img {
                width: 5vh;
                margin-left: 5vw;
            }
            h1 {
                color: white;
                margin-left: 4vw;
                font-size: 19px;
            }
        }
        &-section2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                margin-left: 0px;
                width: 30vw;
                @media (max-width: 600px) {
                    width: 55vw;
                }
            }
            p {
                color: white;
                font-size: 22px;
                margin-bottom: 0px;
                margin-top: 0px;
                @media (max-width: 600px) {
                    font-size: 18px;
                }
            }
        }
`

export { HeaderTop, HeaderBottom }