import React from 'react'

import Logo from './../Assets/cropped-logosocial-300x300.png'
import './../dist/css/admin.min.css'
import { LoginForm, LoginContainer, LoginHeader, LoginFormHeader, LoginFormBody } from './Login/Styles/Styles'
import { host, dir_api_name } from './../api_vars'
import { checkRol } from './Util/checkRol'

export default function Login() {
    const LoginApp = () => {
        let user = document.getElementById("user").value
        let password = document.getElementById("password").value


        const data = `username=${user}&password=${password}`;
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Origin', host);
        const miInit = {
            method: 'POST',
            headers: headers,
            body: data,
            mode: 'cors'
        };


        fetch(host + dir_api_name + `/users/Auth/login.php`, miInit)

            .then((res) => {

                if (res.ok)

                    return res.json()

                else

                    throw res

            })
            .then((userResponse) => {

                if (!localStorage.getItem("session")) {

                    localStorage.setItem("session", JSON.stringify(userResponse))

                } else {

                    localStorage.removeItem("session")

                    localStorage.setItem("session", JSON.stringify(userResponse))

                }

                checkRol(userResponse.token).then(res => {

                    if (Number(res) === 0)
                        window.location = "/admin/delivery_man/" + userResponse.id
                    else
                        window.location = "/admin/table_orders/1"

                })


            })
            .catch((err) => console.error(err))

    }


    return (
        <LoginContainer method="POST" className="">
            <LoginHeader className="">
                <img src={Logo} alt="" />
            </LoginHeader>
            <LoginForm className="">
                <LoginFormHeader className="">
                    <h2>Login</h2>
                </LoginFormHeader>
                <LoginFormBody className="">
                    <div>
                        <label></label>
                        <input placeholder="Usuario" type="text" name="username" id="user" />
                    </div>
                    <div>
                        <label></label>
                        <input placeholder="Contraseña" type="password" name="password" id="password" />
                    </div>
                    <div>
                        <button id="login" onClick={(e) => { e.preventDefault(); LoginApp() }}> Login </button>
                    </div>
                </LoginFormBody>
            </LoginForm>
        </LoginContainer>
    )
}
