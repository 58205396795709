import React, { useState, useEffect } from 'react'

import moment from 'moment'

import {
    useSelector,
    useDispatch
} from 'react-redux'

import {
    fetchOrderFilter
} from '../../../../../../../../Redux'

import {
    DownOutlined
} from '@ant-design/icons';

import { Button, message } from 'antd'

import {
    TreeOrientations
} from '../../../../Style/Styles'

import TableOrdersBodyItemRow from '../../TableOrdersBodyItemRow'


export default function OrdersOldVersion({ fetchOrders, openNotification, fetchTotal }) {


    const cellsProps = {
        check: {
            visible: true,
            width: ""
        },
        id: {
            visible: true,
            width: ""
        },
        name: {
            visible: true,
            width: ""
        },
        date_delivery: {
            visible: true,
            width: ""
        },
        ubication: {
            visible: true,
            width: ""
        },
        state: {
            visible: true,
            width: ""
        },
        dispatch_price: {
            visible: false,
            width: ""
        },
        invoice_number: {
            visible: true,
            width: ""
        },
        total: {
            visible: true,
            width: ""
        }
    }

    const [collapse, setCollapse] = useState(true)
    const [orders, setOrders] = useState([])

    const filters = useSelector(state => state.orders_filter)
    const [total, setTotal] = useState(0)

    const dispatch = useDispatch()

    const setOrdersActions = (res) => {

        setOrders(res)

        // dispatch(fetchLoadingStatus({}, "Set to 100%"))

        // setTimeout(() => {

        //     // dispatch(fetchLoadingStatus({}, "Off"))

        // }, 1000)
    }

    const errorMessage = (text) => {
        message.error(text, 0);
    };

    useEffect(() => {


        let date_delivery = filters.date_delivery !== "" ? filters.date_delivery : moment().format("YYYY-MM-DD")

        let query = `tableOrders/views/ordered/api_consult_order_oldVersion.php?date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        let totalQuery = `tableOrders/views/ordered/api_consult_total_by_section.php?type=oldVersions&optionValue=null&orientation=null&city=null&neighborhood=null&date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`

        if (filters.reload && filters.viewType === "ordered_list") {

            message.destroy()

            fetchOrders(query).then((res) => {

                setOrdersActions([...res])

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })

            fetchTotal(totalQuery).then((res) => {

                setTotal(res)

            }).catch(err => {

                openNotification(`Error cargando datos ref: ${err}`, "error")

            })
            // dispatch(fetchLoadingStatus({}, "On"))

        } else if (filters.viewType === "ordered_list") {

            errorMessage(

                <span>

                    La actualización automática está detenida hasta completar la operación

                    <Button type="link"

                        onClick={() => {
                            dispatch(
                                fetchOrderFilter({ reload: true })
                            )
                        }} >

                        Reactivar

                    </Button>

                </span>
            )

        }

        let reloadDataInterval

        clearInterval(reloadDataInterval !== undefined ? reloadDataInterval : null)

        reloadDataInterval = setInterval(() => {

            if (filters.reload && filters.viewType === "ordered_list") {

                fetchOrders(query).then((res) => {

                    setOrdersActions([...res])

                }).catch(err => {

                    openNotification(`Error cargando datos ref: ${err}`, "error")

                })

                fetchTotal(totalQuery).then((res) => {

                    setTotal(res)

                }).catch(err => {

                    openNotification(`Error cargando datos ref: ${err}`, "error")

                })
                // dispatch(fetchLoadingStatus({}, "On"))

            }

        }, 10000)


        return () => clearInterval(reloadDataInterval)

    }, [filters])

    const collapsesSection = (e) => {

        let className = e.target.className

        if (className === "Tree-header flex orientation") {

            setCollapse(!collapse)

        }

    }

    return (

        <TreeOrientations key={"others"} className={collapse ? "collapsed" : ""} onClick={(e) => collapsesSection(e)}>

            <div className="Tree-header flex orientation">

                <div className="Tree-header-title">

                    No definidos | <b> Total : {total}</b>

                </div>

                <div>

                    <DownOutlined className="DownBtn" />

                </div>

            </div>

            <div className="Tree-body">

                {

                    orders.map((item, index) =>

                        <TableOrdersBodyItemRow item={item} cells={cellsProps} dataType={"text"} />

                    )

                }

            </div>

        </TreeOrientations>
    )
}