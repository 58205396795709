import React, { useEffect, useState } from 'react'
import { TableOrdersBodyControlsRowContainer, TableOrdersBodyItemRowContainer } from './../../Style/Styles'
import { Tooltip, Checkbox, Button, Popover, notification, message, InputNumber, Input, Switch } from 'antd'

import OrderSteps from './OderSteps'


import { CopyTwoTone } from '@ant-design/icons'
import { styleCurrency, currencyCountry, host, dir_api_name, testMode } from '../../../../../../api_vars'
import 'antd/lib/steps/style/css'
import 'antd/lib/input-number/style/css'
import { Link } from 'react-router-dom'
import ControlsRow from './Components/ControlsRow'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrderFilter, fetchOrderListToUpdate, deleteOrderOfListToUpdate, fetchOptionsListToUpdate, fetchLoadingStatus } from '../../../../../../Redux'


import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

export default function TableOrdersBodyItemRow({ item, cellsProps, dataType, index, controlsUpDown, parentComponent }) {

    const cells = {
        check: {
            visible: false,
            width: ""
        },
        id: {
            visible: false,
            width: false
        },
        name: {
            visible: false,
            width: ""
        },
        date_delivery: {
            visible: false,
            width: ""
        },
        ubication: {
            visible: false,
            width: false
        },
        state: {
            visible: false,
            width: false
        },
        dispatch_price: {
            visible: false,
            width: ""
        },
        invoice_number: {
            visible: false,
            width: ""
        },
        delivery_man: {
            visible: false,
            width: ""
        },
        total: {
            visible: false,
            width: ""
        },
        payment_state: {
            visible: true,
            width: "5%"
        },
        ...cellsProps
    }

    const orderStateInitialData = {
        id: 0,
        delivery_route_id: 0,
        pedido: {
            pig: [],
            beef: [],
            chicken: [],
            fish: [],
            sausages: [],
            cravings: [],
            combos: [],
            delivery_price: 0
        },
        datos_usuario: {
            name: "",
            last_name: "",
            id: "",
            invoice_number: undefined,
            email: '',
            cell: "",
            tel: "",
            address: "",
            city: "",
            neighborhood: "",
            commune: "",
            idDirection: "",
            point_reference: "",
            date: "",
            hour: "",
            minute: "",
            am_pm: "",
            payment_method: "",
            payment_state: false
        },
        position: "",
        total: 0
    }

    const [orderData, setOrderData] = useState(orderStateInitialData)
    const [deliveryMan, setdeliveryMan] = useState({ name: "" })

    const [invoiceNumber, setInvoiceNumber] = useState(0)

    const dispatch = useDispatch()

    const orderListToUpdate = useSelector(state => state.ordersToUpdate)

    const filters = useSelector(state => state.orders_filter)


    const [update, setUpdate] = useState(false)

    const [checked, setChecked] = useState(false)

    const [position, setPosition] = useState(index)

    const [newTotal, setNewTotal] = useState(0)

    const headers = new Headers();


    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);

    const miInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
    };


    // Functions

    const checkStatus = (status) => {
        return status === 1 ? "Cancelado" : status === 2 ? "Confirmado" : status === 3 ? "Porcionado" : status === 4 ? "Despachado" : "Entregado"
    }

    const copyText = (id_element) => {
        var aux = document.createElement("input");
        aux.setAttribute("value", document.getElementById(id_element).innerText);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        sucessMessage("Copiado en porta papeles")
    }

    const clickToLink = (event, id) => {
        if (event.target.className === "flex" || event.target.className === "item") {
            document.getElementById(id).click()
        }
    }

    const textToJson = (text) => {

        const json = JSON.parse(text)


        if (json !== SyntaxError) {

            return json

        } else {

            return { name: "Error", email: "Error", date: "Error", payment_method: "Error" }

        }
    }

    const btnOrderStatusStlye = (orderStatus) => {
        return {
            backgroundColor: orderStatus === 0 ? "#7070703b"
                : orderStatus === 1 ? "#b71c1c"
                    : orderStatus === 2 ? "#00bfa5"
                        : orderStatus === 3 ? "#00c853"
                            : orderStatus === 4 ? "#00b8d4" :
                                "#6200ea",
            color: orderStatus === undefined || orderStatus === 0 ? "" : "white",
            minWidth: "100%"
        }
    }



    const openNotification = (title, type) => {
        let message
        const notificationSucess = (message) => {
            notification.success({
                message: message,
                description: title,
                onClick: () => {
                },
                placement: "bottomRight",
                className: "notifications"
            });
        }

        const notificationDeleted = (message) => {
            notification.error({
                message: message,
                description: title,
                onClick: () => {
                },
                placement: "bottomRight",
                className: "notifications"
            })
        }



        switch (type) {
            case "actualizando":
                message = "Actualizando pedido"
                notificationSucess(message)
                break;

            case "actualizado":
                message = "Actualizado!"
                notificationSucess(message)
                break;
            case "enviando":
                message = "Enviando email!"
                notificationSucess(message)
                break;
            case "enviado":
                message = "Email enviado !"
                notificationSucess(message)
                break;

            case "error":
                message = "Error!"
                notificationDeleted(message)
                break;

            default:
                return false;
        }


    };

    const sendEmail = (type) => {

        let link = escape(`https://precompra.santascarnitas.com/Registro/`)
        let email = escape(orderData.datos_usuario.email)

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        openNotification("Enviado email", "enviando")

        let name = orderData.datos_usuario.name + " " + orderData.datos_usuario.last_name

        let totalOrder = Number(newTotal !== 0 ? newTotal : orderData.total).toLocaleString("de-AT", { style: "currency", currency: "USD" })

        return fetch(`${host}${dir_api_name}/api_sendEmail.php?email=${email}&link=${link}&id=${orderData.id}&type=${type}&date=${orderData.datos_usuario.date}&name=${name}&total=${totalOrder}`, miInit).then((response) => {

            if (response.ok) {
                return response.text()
            }
            else { throw response };

        }).catch(err => {
            openNotification(`Error enviando email ref: ${err}`, "error")
        })
    }

    const warning = (text) => {
        message.warning(text);
    };

    const sucessMessage = (text, duration) => {
        message.success(text, duration)
    }

    const turnOffReload = () => {
        if (filters.reload) {
            dispatch(fetchOrderFilter({
                reload: false
            }))
        }
    }

    const setNewOrderState = (orderNumber) => {

        turnOffReload()

        let inputTotal = document.getElementById("inputTotal" + orderData.id)
        let inputInvoiceNumber = document.getElementById("inputInvoiceNumber" + orderData.id)
        let oldOrderStatus = orderData.datos_usuario.orderStatus

        if (orderNumber > 2 && Number(oldOrderStatus === undefined ? 0 : oldOrderStatus) < 3) {

            if (newTotal === 0) {
                inputTotal.focus()
                warning("Por favor establezca el total")
            } else if (invoiceNumber === 0) {
                inputInvoiceNumber.focus()
                warning("Por favor establezca el N° de factura")
            }
            else {
                setUpdate(
                    true,
                    setOrderData({
                        ...orderData, datos_usuario: {
                            ...orderData.datos_usuario,
                            orderStatus: "" + orderNumber + ""
                        }

                    })
                )
            }
        } else {
            setUpdate(
                true,
                setOrderData({
                    ...orderData, datos_usuario: {
                        ...orderData.datos_usuario,
                        orderStatus: "" + orderNumber + ""
                    }

                })
            )
        }
    }

    async function addOrderToListOfSelected(checkedParm, orderListToUpdate) {

        if (checkedParm) {
            sucessMessage("Seleccionado")
            orderData.position = orderListToUpdate.orders.length
            dispatch(fetchOrderListToUpdate(
                orderData
            ))

        } else {
            let indexCount = 0
            let newOrderList = []

            const index = await orderListToUpdate.orders.forEach((item, index) => {

                if (item.id === orderData.id) {
                    console.log("Not found")
                }
                else {
                    newOrderList = [...newOrderList, item]
                    return true
                }

            }
            )


            await dispatch(deleteOrderOfListToUpdate(newOrderList))
        }
    }

    const clearOrderList = () => {
        dispatch(deleteOrderOfListToUpdate([]))
    }

    function searchIndex() {

        let indexCount = false

        orderListToUpdate.orders.forEach((item, index) => {

            if (item.id === orderData.id) {
                indexCount = index
            }
            else {
                return true
            }

        }
        )

        return indexCount

    }

    const orderModified = (value) => {
        if (value > 0 || value === "" || value === null) {
            return true
        } else {
            return false
        }
    }


    const changeOrderInList = (index, direction) => {



        const orders = orderListToUpdate.orders



        if (direction === "down") {

            orders[index + 1].position = position //  set position to next item
            orders[index].position = Number(position) + 1 // Set position to item

            console.log("Next: " + position);
            console.log("Actual: " + (Number(position) + 1));

            setPosition(Number(position) + 1)

        }

        else {

            orders[index - 1].position = position

            orders[index].position = position - 1 // Set position to item

            setPosition(position - 1)

        }// set position to previous item

        const ordersSort = orders.sort((a, b) => {
            if (a.position > b.position) {
                return 1;
            }
            if (a.position < b.position) {
                return -1;
            }
            // a must be equal to b
            return 0;
        }) // R

        dispatch(deleteOrderOfListToUpdate(ordersSort))

    }

    const consultDeliveryMan = (delivery_route_id) => {

        fetch(`${host}${dir_api_name}/admin/components/purchases/add_order/api_consult_delivery_man.php?delivery_route_id=${delivery_route_id}`, miInit).then((response) => {
            if (response.ok) return response.json()

            else { throw response };

        })
            .then((response) => {

                setdeliveryMan(response)

            })
            .catch(err => {
                message.error("Error en delivery_man")
            })

    }

    const update_order = (orderData, type) => {

        openNotification("Actualizando...", "actualizando")

        setUpdate(false)

        let status = true

        if (status) {

            let varName = ""

            let idR = orderData.id

            if (isNaN(idR)) varName = "key"; else varName = "orderId"

            // alert(varName)

            const end_total = Number(orderData.datos_usuario.orderStatus) === 5 || newTotal === 0 && Number(orderData.datos_usuario.orderStatus) !== 2 ? orderData.total : newTotal;


            const sendData = () => {

                const headersOrder = new Headers();
                headersOrder.append('Content-Type', 'application/x-www-form-urlencoded');
                headersOrder.append('Origin', host);
                const body = `${varName}=${idR}
                &order=${escape(JSON.stringify(orderData.pedido))}
                &user_data=${escape(JSON.stringify(orderData.datos_usuario))}
                &total=${end_total}
                &delivery_route_id=${orderListToUpdate.route_id}
                &position=${position}`;

                const miInit = {
                    method: 'POST',
                    headers: headersOrder,
                    body: body,
                    mode: 'cors'
                };

                return fetch(`${host}${dir_api_name}/api_update_order.php`, miInit).then((response) => {
                    if (response.ok) return response.text()

                    else { throw response };

                })
                    .catch(err => {

                        openNotification("Error", "error")

                        // btnUpdate.disabled = false;
                        dispatch(fetchOrderFilter({
                            reload: true
                        }))
                    })
                    .then((response) => {
                        dispatch(fetchOrderFilter({
                            reload: true
                        }))

                        dispatch(fetchLoadingStatus({ loading: true, progress: 100 }))

                        setTimeout(() => {
                            dispatch(fetchLoadingStatus({ loading: false, progress: 0 }))
                        }, 400)
                        if (type === "list") {
                            clearOrderList()

                        }
                        openNotification("Pedido actualizado", "actualizado")

                        if (testMode) {
                            if (Number(orderData.datos_usuario.orderStatus) === 2) {
                                sendEmail("confirmed").then((response) =>
                                    openNotification("Email enviado", "enviado")
                                )
                            }
                            else if (Number(orderData.datos_usuario.orderStatus) === 3) {
                                sendEmail("portioned").then((response) =>
                                    openNotification("Email enviado", "enviado")
                                )
                            } else if (Number(orderData.datos_usuario.orderStatus) === 4) {
                                sendEmail("dispatched").then((response) =>
                                    openNotification("Email enviado", "enviado")
                                )
                            }
                        }

                        // btnUpdate.disabled = false;
                    })
            }


            if (Number(orderData.datos_usuario.orderStatus) === 2) { // If state is confirmed

                fetch(`${host}${dir_api_name}/api_checkAndUpdateLimit.php?date=${orderData.datos_usuario.date}&id=${orderData.id}`, miInit).then((response) => {

                    if (response.ok) return response.text()

                    else { throw response };

                })
                    .catch(err => {

                        openNotification("Error", "error")

                        // btnUpdate.disabled = false;


                    }).then((res) => sendData())
            } else {

                sendData()

            }

        } else {

            // alert("Por favor llene todos los campos");

        }

    }

    //Use Effects

    const setOrderDataInitial = () => {

        if (dataType === "text") {


            setOrderData({
                ...orderData,
                id: item.id,
                delivery_route_id: item.delivery_route_id,
                pedido: { ...textToJson(item.pedido) },
                datos_usuario: { ...textToJson(item.datos_usuario) },
                position: "",
                total: item.total
            })

            if (item.delivery_route_id > 0 && deliveryMan.name === "") {

                consultDeliveryMan(item.delivery_route_id)

            } else if (item.delivery_route_id === null || item.delivery_route_id === "0") {

                if (deliveryMan.name !== "") {

                    setdeliveryMan({ name: "" })

                }

            }

        } else if (dataType === "json") {

            console.log(item)

            setOrderData((prevState) => {
                return {
                    ...prevState,
                    ...item
                }
            })

            if (item.delivery_route_id > 0 && deliveryMan.name === "") {
                consultDeliveryMan(item.delivery_route_id)
            } else {
                setdeliveryMan({ name: "" })
            }

        }

    }

    useEffect(() => {

        setOrderDataInitial()

    }, [item])

    useEffect(() => {
        if (orderData.id === 0) {
            setOrderDataInitial()
        }
    }, [orderData])

    // Set item data

    useEffect(() => {

        if (parentComponent === "multiple_choice" && orderListToUpdate.orders.length > 0 && orderData.id !== 0) {


            const updatePositionItem = async () => {

                let indexItem = false

                await orderListToUpdate.orders.forEach((item, index) => {

                    if (item.id === orderData.id) {
                        indexItem = index
                    }

                    else {
                        return true

                    }
                })

                const positionItem = orderListToUpdate.orders[indexItem].position === "" ? indexItem : orderListToUpdate.orders[indexItem].position

                setPosition(Number(positionItem))

            }

            updatePositionItem()

        }

    }, [orderListToUpdate.orders, index, orderData.id, parentComponent])

    useEffect(() => {

        if (update) {

            dispatch(fetchLoadingStatus({ loading: true, progress: 100 }))

            update_order(orderData)

        }

    }, [orderData, item, update])

    // Set loading bar to finished

    useEffect(() => {

        if (!cells.check.visible) {
            let inputTotal = document.getElementById("inputTotalInList" + orderData.id)
            let inputInvoiceNumber = document.getElementById("inputInvoiceNumberInList" + orderData.id)
            let oldOrderStatus = searchIndex() !== false ? orderListToUpdate.orders[searchIndex()].datos_usuario.orderStatus : undefined

            oldOrderStatus = oldOrderStatus === undefined ? 0 : Number(oldOrderStatus)


            if (orderListToUpdate.state > 2 && Number(oldOrderStatus) < 3) {

                if (newTotal === 0) {
                    inputTotal.focus()
                    warning("Por favor establezca el total")
                    dispatch(fetchOptionsListToUpdate({
                        state: 0
                    }))
                    setOrderData({
                        ...orderData, datos_usuario: {
                            ...orderData.datos_usuario,
                            orderStatus: oldOrderStatus
                        }
                    })
                } else if (invoiceNumber === 0) {
                    inputInvoiceNumber.focus()
                    warning("Por favor establezca el N° de factura")
                    dispatch(fetchOptionsListToUpdate({
                        state: 0
                    }))
                    setOrderData({
                        ...orderData, datos_usuario: {
                            ...orderData.datos_usuario,
                            orderStatus: oldOrderStatus
                        }
                    })
                }
                else {
                    setOrderData({
                        ...orderData, datos_usuario: {
                            ...orderData.datos_usuario,
                            orderStatus: orderListToUpdate.state
                        }
                    })
                }

            } else if (orderListToUpdate.state > oldOrderStatus) {
                setOrderData({
                    ...orderData, datos_usuario: {
                        ...orderData.datos_usuario,
                        orderStatus: "" + orderListToUpdate.state + ""
                    }

                })
            } else if (orderListToUpdate.state === 1 && oldOrderStatus < 4) {
                setOrderData({
                    ...orderData, datos_usuario: {
                        ...orderData.datos_usuario,
                        orderStatus: "" + orderListToUpdate.state + ""
                    }

                })
            }
            else if (orderListToUpdate.state > 0 && orderListToUpdate.state <= oldOrderStatus && orderListToUpdate.state !== 1) {
                warning("Este producto no puede ser establecido a un estado menor o igual al actual")
                inputTotal.focus()
                dispatch(fetchOptionsListToUpdate({
                    state: 0
                }))
            }
        }

    }, [orderListToUpdate, newTotal, checked])

    //Check change of state of item

    useEffect(() => {

        if (orderListToUpdate.update && dataType === "json") {

            dispatch(fetchLoadingStatus({ loading: true, progress: 100 }))

            update_order(orderData, "list")

            dispatch(fetchOptionsListToUpdate({
                update: false, state: 0
            }))

        }

    }, [orderListToUpdate, orderData])

    //Turn off the loading bar

    useEffect(() => {


        let index = searchIndex()

        if (index === false) {

            setChecked(false)

        } else {

            setChecked(true)

        }
    }, [orderListToUpdate, orderData])

    //Check check btn

    useEffect(() => {

        if (orderListToUpdate.orders.length === 0 && orderListToUpdate.state !== 0) {

            dispatch(fetchOptionsListToUpdate({ state: 0 }))

        }

    }, [orderListToUpdate])

    // Reset the state in multiple choice if number of items is = 0

    useEffect(() => {
        if (filters.reload) {
            setNewTotal(0)
            setInvoiceNumber(0)
        }
    }, [filters])

    // If reload is = true will to reset total and invoice number state

    return (

        <div style={{ order: position }}>

            <TableOrdersBodyItemRowContainer key={orderData.id} onClick={(e) => { if (cells.check.visible) clickToLink(e, "link" + orderData.id) }}>

                {/* <TableOrdersBodyItemRow key={orderData.id} > */}

                <div className="flex">

                    <Link to={"/admin/registro/" + orderData.id + "/"} style={{ display: "none" }} id={"link" + orderData.id}></Link>
                    {controlsUpDown && Number(orderData.datos_usuario.orderStatus) === 4 ?
                        <p className="position">{position + 1}</p>
                        : null}
                    {

                        controlsUpDown && Number(orderData.datos_usuario.orderStatus) === 4 ? <div className="order_controls">

                            <Button
                                icon={
                                    <CaretUpOutlined />
                                }
                                onClick={
                                    () => {
                                        changeOrderInList(position, "up")
                                    }
                                }
                                disabled={
                                    position === 0
                                }
                            />

                            <Button
                                icon={
                                    < CaretDownOutlined />
                                }
                                onClick={
                                    () => {
                                        changeOrderInList(position, "down")
                                    }
                                }
                                disabled={
                                    orderListToUpdate.orders.length - 1 === position
                                }
                            />
                        </div>
                            : null
                    }

                    {
                        cells.check.visible ?

                            <p style={{ width: "2%" }} key={"id" + orderData.id}>

                                <Checkbox

                                    onChange={(value) => {
                                        addOrderToListOfSelected(value.target.checked, orderListToUpdate)
                                        setChecked(!checked)
                                    }}

                                    className="notopen"

                                    checked={
                                        checked //checked stated
                                    }
                                    disabled={
                                        Number(orderData.datos_usuario.orderStatus) >= 4 || Number(orderData.datos_usuario.orderStatus) === 1 ? true : false // If order state is dispached or canceled
                                    } >

                                </Checkbox>

                            </p>

                            : null

                    }

                    {
                        cells.id.visible ?
                            <p style={{ width: cells.id.width }} className="flex" id={"id" + orderData.id} >

                                <Tooltip placement="top" title={"Copiar"}>

                                    <b onClick={() => copyText("id" + orderData.id)}>{orderData.id}</b>

                                    <CopyTwoTone style={{ marginLeft: "4px", fontSize: "12px" }} twoToneColor="#304ffe" />

                                </Tooltip>

                            </p>
                            : null
                    }

                    {
                        cells.invoice_number.visible ?

                            <p style={{ width: "13%" }} className="flex" id={"invoiceNumber" + orderData.id} >

                                {/* <Tooltip placement="top" title={"Copiar"}> */}


                                <InputNumber
                                    defaultValue={orderData.datos_usuario.invoice_number === undefined ? 0 : orderData.datos_usuario.invoice_number}
                                    formatter={value => `N° ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                                    parser={value => value.replace(/N°\s?|(\.*)/g, '')}
                                    className={orderModified(invoiceNumber) ? "InputModified" : ""}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        setOrderData({
                                            ...orderData,
                                            datos_usuario: {
                                                ...orderData.datos_usuario,
                                                invoice_number: e === null ? 0 : e
                                            }
                                        })
                                        setInvoiceNumber(e === null ? 0 : e)
                                        if (cells.check.visible) turnOffReload()
                                    }
                                    }
                                    id={"inputInvoiceNumber" + (!cells.check.visible ? "InList" : "") + orderData.id}
                                />


                                {/* </Tooltip> */}

                                {/* <CopyTwoTone style={{ marginLeft: "4px", fontSize: "12px" }} twoToneColor="#304ffe" onClick={() => copyText("incoice" + orderData.id)}/> */}

                            </p>

                            : null

                    }


                    {
                        cells.name.visible ?
                            <p style={{ width: "11%", justifyContent: "flex-start" }} className="flex">

                                <Tooltip placement="top" title={"Copiar"} className="flex j-between w-100" style={{ justifyContent: "center" }}>

                                    <p id={"name" + orderData.id} onClick={() => copyText("name" + orderData.id)} style={{ maxWidth: "120px", overflow: "hidden", whiteSpace: "nowrap" }}>
                                        {unescape((orderData.datos_usuario).name) + " " + unescape((orderData.datos_usuario).last_name)}
                                    </p>

                                    <CopyTwoTone style={{ marginLeft: "4px", fontSize: "12px" }} twoToneColor="#304ffe" />

                                </Tooltip>

                            </p> : null

                    }

                    {
                        cells.date_delivery.visible ?

                            <p style={{ width: "9%" }} className="flex">

                                <Tooltip placement="top" title={"Copiar"}>
                                    <span id={"date" + orderData.id} onClick={() => copyText("date" + orderData.id)}>
                                        {(orderData.datos_usuario).date}
                                    </span>
                                    <CopyTwoTone style={{ marginLeft: "4px", fontSize: "12px" }} twoToneColor="#304ffe" />
                                </Tooltip>

                            </p> : null
                    }

                    {
                        cells.ubication.visible ?
                            <p title={
                                (orderData.datos_usuario).commune !== undefined ?
                                    unescape((orderData.datos_usuario).city + "/" + (orderData.datos_usuario).neighborhood + "/" + (orderData.datos_usuario).commune) : unescape((orderData.datos_usuario).street)
                            } style={{ width: cells.ubication.width, whiteSpace: "nowrap", overflow: "hidden" }}>

                                <Tooltip placement="top" title={"Copiar"} className="flex j-between w-100" style={{ justifyContent: "center" }}>

                                    <p id={"address" + orderData.id} onClick={() => copyText("address" + orderData.id)} style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                                        {
                                            (orderData.datos_usuario).commune !== undefined ?
                                                unescape((orderData.datos_usuario.city)) + " - " + (unescape(orderData.datos_usuario.neighborhood)) + " - " + unescape((orderData.datos_usuario.commune)) :
                                                unescape((orderData.datos_usuario).street)
                                        }
                                    </p>

                                    <CopyTwoTone style={{ marginLeft: "4px", fontSize: "12px" }} twoToneColor="#304ffe" />

                                </Tooltip>
                            </p>
                            : null
                    }

                    {
                        cells.state.visible ?
                            <p style={{ width: cells.state.width }}>

                                <Popover content={

                                    <OrderSteps

                                        orderStatusProp={(orderData.datos_usuario).orderStatus === undefined ? 0 : Number(orderData.datos_usuario.orderStatus)}
                                        setOrderData={setOrderData}
                                        orderData={orderData}
                                        setNewOrderState={setNewOrderState}

                                    />

                                }

                                    onVisibleChange={
                                        (e) => {
                                            if (!e) {
                                                dispatch(fetchOrderFilter({
                                                    reload: !e
                                                }))
                                            }
                                        }
                                    }
                                    trigger="click"

                                    placement="bottom"

                                >
                                    <Button style={btnOrderStatusStlye(Number(orderData.datos_usuario.orderStatus === undefined ? 0 : orderData.datos_usuario.orderStatus))} className="btnOrderStatus">
                                        {
                                            orderData.datos_usuario.orderStatus === undefined || orderData.datos_usuario.orderStatus === 0 ? "Sin Confirmar" : checkStatus(Number(orderData.datos_usuario.orderStatus))
                                        }
                                    </Button>
                                </Popover>
                            </p>
                            : null
                    }

                    {

                        cells.dispatch_price.visible ?

                            <p style={{ width: "10%" }} className="flex">

                                {

                                    Number((orderData.pedido).delivery_price !== undefined ? (orderData.pedido).delivery_price : 0).toLocaleString(currencyCountry, styleCurrency)

                                }

                            </p>
                            : null

                    }

                    {
                        cells.delivery_man.visible ?

                            <p style={{ width: "10%" }} className="flex">

                                {

                                    <Input
                                        value={deliveryMan.name}
                                        id={"input_delivery_man" + orderData.id}
                                        disabled
                                        style={{ background: "white", color: "#333" }}
                                    ></Input>

                                }

                            </p>
                            : null
                    }

                    {
                        cells.total.visible ?
                            <p style={{ width: "11%", justifyContent: "flex-start" }} className="flex noclick">
                                <InputNumber
                                    defaultValue={orderData.total}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')}
                                    className={orderModified(newTotal) ? "InputModified" : ""}
                                    style={{ width: "100%" }}
                                    value={newTotal > 0 ? newTotal : orderData.total}
                                    onChange={(e) => {
                                        setNewTotal(e)
                                        if (cells.check.visible) turnOffReload()
                                    }}
                                    id={"inputTotal" + (!cells.check.visible ? "InList" : "") + orderData.id}
                                />
                                {/* <input placeholder={""} className="ListOrder_IntputTotal"  /> */}
                            </p>
                            : null
                    }

                    {
                        cells.payment_state.visible ?
                            <p style={{ width: cells.payment_state.width }}>

                                <Switch checkedChildren="Pagado" unCheckedChildren="No pagado" checked={orderData.datos_usuario.payment_state} id={"payment_state-" + orderData.id} style={{ marginLeft: "auto" }}
                                    onChange={(e) => {
                                        orderData.pedido.payment_state = e

                                        setOrderData({ ...orderData })
                                    }} />

                            </p>

                            : null
                    }



                </div>
                <div>
                    <ControlsRow />
                </div>

            </TableOrdersBodyItemRowContainer>

            <TableOrdersBodyControlsRowContainer>
                <div>
                    <Button type="primary" onClick={(e) => document.getElementById("link" + orderData.id).click()}>Ver</Button>
                </div>
            </TableOrdersBodyControlsRowContainer>
        </div>
    )
}