import React, { useEffect, useState } from 'react'
import { host, dir_api_name } from '../../../api_vars';
import { useParams, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { fetchBreadcumb, fetchOrderFilter, fetchOrderData, fetchLoadingStatus, fetchPageProps, fetchOrderListToUpdate, fetchOptionsListToUpdate } from '../../../Redux';


//Components Ant.desing
import { Input, notification, Pagination, Affix, Button, Drawer, message } from 'antd';
import {
    FileSyncOutlined
} from '@ant-design/icons';
import 'antd/lib/input/style/css'
import 'antd/lib/pagination/style/css'
import 'antd/lib/affix/style/css'
import 'antd/lib/drawer/style/css'

//Components
import TableOrdersBodyPrint from './List_orders/TableOrdersBody/TableOrdersBodyPrint';
import BreadcrumCmnt from '../../BreadcrumCmnt'
import { ListOrdersContainer, SearcherContainer, TableOrdersContainer, TableOrdersHeader, TableOrdersBody } from './List_orders/Style/Styles'
import Filter from './List_orders/filter';
import DrawerContainer from './List_orders/DrawerList/DrawerContainer';
import ViewToggle from './List_orders/ViewToggle';
import Reports from './List_orders/Reports';
import { ordersProps } from './List_orders/ordersProps';


const dispositive_type = window.screen.width > 600 ? "PC" : "MOBILE";



function List_orders({ fetchBreadcumb, fetchOrderFilter }) {

    const { page } = useParams()

    const { Search } = Input;

    const [orders, setOrders] = useState(ordersProps)
    const [totalOrders, setTotalOrders] = useState(50)

    const [pageState, setPageState] = useState(page)

    const [drawerVisiblility, setDrawerVisiblility] = useState(false)

    const filters = useSelector(state => state.orders_filter)

    const orders_filter = useSelector(state => state.orders_filter)

    const dispatch = useDispatch()

    const cellsProps = {
        check: {
            visible: dispositive_type === "PC",
            width: "2%"
        },
        id: {
            visible: true,
            width: dispositive_type === "PC" ? "5%" : "10%"
        },
        name: {
            visible: dispositive_type === "PC",
            width: "11%"
        },
        date_delivery: {
            visible: dispositive_type === "PC",
            width: "9%"
        },
        ubication: {
            visible: true,
            width: dispositive_type === "PC" ? "18%" : "45%"
        },
        state: {
            visible: true,
            width: dispositive_type === "PC" ? "11%" : "24%"
        },
        dispatch_price: {
            visible: false,
            width: "10%"
        },
        invoice_number: {
            visible: dispositive_type === "PC",
            width: "11%"
        },
        delivery_man: {
            visible: dispositive_type === "PC",
            width: "10%"
        },
        total: {
            visible: dispositive_type === "PC",
            width: "11%"
        },
        payment_state: {
            visible: true,
            width: "9%"
        }
    }

    const openNotification = (title, type) => {
        let message
        const notificationSucess = (message) => {
            notification.success({
                message: message,
                description: title,
                onClick: () => {
                },
                placement: "bottomRight",
                className: "notifications"
            });
        }

        const notificationDeleted = (message, type) => {
            notification.destroy()
            notification.error({
                message: message,
                description: title,
                onClick: () => {
                },
                placement: "bottomRight",
                className: "notifications"
            })
        }



        switch (type) {
            case "actualizando":
                message = "Actualizando pedido"
                notificationSucess(message)
                break;

            case "actualizado":
                message = "Actualizado!"
                notificationSucess(message)
                break;
            case "enviando":
                message = "Enviando email!"
                notificationSucess(message)
                break;
            case "enviado":
                message = "Email enviado !"
                notificationSucess(message)
                break;

            case "error":
                message = "Error!"
                notificationDeleted(message)
                break;

            default:
                return false;
        }


    };


    const fetchData = () => {

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);
        const miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };



        let limitInit = pageState === undefined ? 0 : (50 * (pageState)) - 50

        let limitEnd = pageState === undefined ? 50 : 50 * pageState


        return fetch(`${host}${dir_api_name}/api_consult_orders.php?type=orders&limitInit=${limitInit}&limitEnd=${limitEnd}&state=${filters.state}&date_delivery=${filters.date_delivery}&payment_method=${filters.type_payment}&keywords=${filters.keywords}
        `, miInit).then((response) => {

            if (response.ok) {
                return response.json();
            }

            else { throw response };

        })

    }

    const fetchTotalOrders = () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);
        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };

        return fetch(`${host}${dir_api_name}/api_consult_orders.php?type=totalOrders&state=${filters.state}&date_delivery=${filters.date_delivery}&payment_method=${filters.type_payment}&keywords=${filters.keywords}`, miInit).then((response) => {

            if (response.ok) {
                return response.text();
            }

            else { throw response };

        }).catch(err => {

            openNotification(`Error cargando datos ref: ${err}`, "error")

        })
    }

    const errorMessage = (text) => {
        message.error(text, 0);
    };

    useEffect(() => {

        fetchData().then((response) => setOrders([...response]))

        fetchTotalOrders().then((response) => setTotalOrders(response))

        document.getElementById("changeCurrentPage").click()

    }, [pageState])

    useEffect(() => {

        const breadCrumb = [
            { title: "Compras", link: "/admin/table_orders/1", disabled: true },
            { title: "Tabla de pedidos", link: "/admin/table_orders/1", disabled: true }
        ]

        fetchBreadcumb(breadCrumb)

        fetchOrderFilter({ page: page })

        dispatch(fetchOrderData({
            idDirection: "",
            city: "",
            neighborhood: "",
            commune: ""
        }))

    }, [])


    useEffect(() => {

        if (filters.reload && orders_filter.viewType === "last_added") {

            message.destroy()


            fetchTotalOrders().then((response) => {
                setTotalOrders(response)


                fetchData().then((response) => {

                    setOrders([...response])

                    dispatch(fetchLoadingStatus({ loading: true, progress: 100 }))

                    setTimeout(() => {
                        dispatch(fetchLoadingStatus({ loading: false, progress: 0 }))
                    }, 400)

                })

            })

            dispatch(fetchLoadingStatus({ loading: true, progress: 0 }))

        } else if (orders_filter.viewType === "last_added") {

            errorMessage(

                <span>

                    La actualización automática está detenida hasta completar la operación

                    <Button type="link"

                        onClick={() => {
                            dispatch(
                                fetchOrderFilter({ reload: true })
                            )
                        }} >

                        Reactivar

                    </Button>

                </span>
            )

        }

        let reloadDataInterval

        clearInterval(reloadDataInterval !== undefined ? reloadDataInterval : null)

        reloadDataInterval = setInterval(() => {

            if (filters.reload && orders_filter.viewType === "last_added") {


                fetchTotalOrders().then((response) => {


                    setTotalOrders(prevState => {

                        if (prevState !== response) {

                            fetchData().then((response) => {

                                setOrders([...response]);

                                dispatch(fetchLoadingStatus({ loading: true, progress: 100 }))

                                setTimeout(() => {
                                    dispatch(fetchLoadingStatus({ loading: false, progress: 0 }))
                                }, 200)

                            })
                        } else {
                            setTimeout(() => {
                                dispatch(fetchLoadingStatus({ loading: false, progress: 0 }))
                            }, 200)
                        }

                        return response
                    })

                })

                dispatch(fetchLoadingStatus({ loading: true, progress: 0 }))

            }

        }, 10000)


        return () => clearInterval(reloadDataInterval)

    }, [filters])

    //Reload data

    useEffect(() => {

        // dispatch(fetchLoadingStatus({}, "On"))



        // let query = `tableOrders/views/ordered/api_consult_orientations.php?date=${date_delivery}&state=${filters.state}&keywords=${filters.keywords}&payment_method=${filters.type_payment}`


        if (filters.reload && filters.viewType === "ordered_list") {

            message.destroy()

            fetchTotalOrders().then((response) => setTotalOrders(response))


            // dispatch(fetchLoadingStatus({}, "On"))

        }

        let reloadDataInterval

        clearInterval(reloadDataInterval !== undefined ? reloadDataInterval : null)

        reloadDataInterval = setInterval(() => {

            if (filters.reload && filters.viewType === "ordered_list") {

                fetchTotalOrders().then((response) => setTotalOrders(response))


                // dispatch(fetchLoadingStatus({}, "On"))

            }

        }, 10000)


        return () => clearInterval(reloadDataInterval)

    }, [filters])

    const changeFilter = (value, filter) => {

        dispatch(
            fetchOrderFilter(
                { [`${filter}`]: value }
            )
        )
    }

    useEffect(() => {

        dispatch(fetchPageProps({ titlePage: "Tabla de pedidos" }))

        dispatch(fetchOptionsListToUpdate({orders:[]}))

        const functionKeypress = (e) => {
            if (e.code === "Enter") {
                setDrawerVisiblility(true)
            }
        }
        window.addEventListener("keypress", functionKeypress)
        return () => {
            window.removeEventListener("keypress", functionKeypress)
            dispatch(fetchOrderFilter({
                reload: true
            }))
            message.destroy()
        }

    }, [])

    return (
        <ListOrdersContainer>

            <Affix offsetTop={90} style={{ zIndex: 1000, position: "fixed", left: "96.7%", top: "93vh" }}>
                <Button type="primary" onClick={() => setDrawerVisiblility(!drawerVisiblility)} style={{ backgroundColor: "#dd2c00", borderColor: "#dd2c00" }}>
                    <FileSyncOutlined />
                </Button>
            </Affix>

            <Drawer
                title={<span><FileSyncOutlined /> Pedidos Seleccionados</span>}
                placement="right"
                closable={false}
                onClose={() => { setDrawerVisiblility(!drawerVisiblility) }} 
                visible={drawerVisiblility}
                width={"90vw"}
                destroyOnClose={true}
            >
                <DrawerContainer />
            </Drawer>

            <BreadcrumCmnt />

            <Reports />

            <Filter />

            <SearcherContainer>

                <p style={{ margin: 0, marginRight: "auto" }}><b>Total</b>: {totalOrders}</p>

                <Search
                    placeholder="Buscar"
                    onChange={value => changeFilter(value.target.value, "keywords")}
                    style={{ width: 300 }}
                    title="Buscar"
                    value={filters.keywords}
                />

            </SearcherContainer>

            <ViewToggle />

            <TableOrdersContainer>

                <TableOrdersHeader className="flex">
                    {
                        cellsProps.check.visible ? <div style={{ width: "2%" }}>
                        </div>
                            : null
                    }
                    {
                        cellsProps.id.visible ?
                            <div style={{ width: cellsProps.id.width }}>
                                <p>ID</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.invoice_number.visible ?
                            <div style={{ width: cellsProps.invoice_number.width }}>
                                <p>N° Factura</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.name.visible ?
                            <div style={{ width: cellsProps.name.width }}>
                                <p>Nombre</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.date_delivery.visible ?
                            <div style={{ width: cellsProps.date_delivery.width }}>
                                <p>Fecha</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.ubication.visible ?
                            <div style={{ width: cellsProps.ubication.width }}>
                                <p>Municipio / Barrio / Comuna</p>
                            </div> :
                            null
                    }
                    {
                        cellsProps.state.visible ?
                            <div style={{ width: cellsProps.state.width }}>
                                <p>Estado</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.delivery_man.visible ?
                            <div style={{ width: cellsProps.delivery_man.width }}>
                                <p>Domiciliario</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.total.visible ?
                            <div style={{ width: cellsProps.total.width }}>
                                <p>Total</p>
                            </div>
                            : null
                    }
                    {
                        cellsProps.payment_state.visible ?
                            <div style={{ width: cellsProps.payment_state.width }}>
                                <p>Pago</p>
                            </div>
                            : null
                    }
                </TableOrdersHeader>

                <TableOrdersBody>

                    <TableOrdersBodyPrint orders={orders} cells={cellsProps} dataType={"text"} />

                </TableOrdersBody>

                {
                    orders_filter.viewType === "last_added" ?
                        <Pagination current={Number(pageState)} pageSize={50} total={totalOrders} showSizeChanger={false} onChange={(page) => { setPageState(page); fetchOrderFilter({ ...filters, page: page }); }} />
                        : null
                }

            </TableOrdersContainer>
            <Link to={"/admin/table_orders/" + pageState} id="changeCurrentPage"></Link>
            
        </ListOrdersContainer>
    )
}

const mapToDispatch = dispatch => {
    return {
        fetchBreadcumb: (breadCumb) => dispatch(fetchBreadcumb(breadCumb)),
        fetchOrderFilter: (orders_filter) => dispatch(fetchOrderFilter(orders_filter))
    }
}

export default connect(null, mapToDispatch)(List_orders)