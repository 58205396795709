import React, { useEffect } from 'react';
import Header from './Admin/header';
import Menu from './Menu'
import styled from 'styled-components';
import RoutesAdmin from './../Routes/RoutesAdmin'
import CheckVersion from './Util/checkVersion';
import { dir_api_name, host, testMode } from '../api_vars';
import { getCLS, getFID, getLCP, getTTFB } from 'web-vitals';

const AppContainer = styled.section`
  display:flex;
  align-items:flex-start;
`

const AppBody = styled.section`
  width: 88vw;
  text-align: center;
  padding-top: 17vh;
  margin-left: -10vw;
  @media(min-width: 1800px){
    margin-left: -8vw;
  }
  @media(max-width: 600px){
    width: 98vw;
  }
`;


function App() {
  useEffect(() => {
    if (localStorage.getItem("session")) {

      const headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('Origin', host);
      const miInit = {
        method: 'POST',
        headers: headers,
        mode: 'cors'
      };


      fetch(host + dir_api_name + `/users/Auth/sessionCheck.php`, miInit)

        .then((res) => {

          if (res.ok)

            return res.json()

          else

            throw res

        })
        .then((res) => {
          if (res.session === false) {

            if (localStorage.getItem("session")) {
              localStorage.removeItem("session")
            }
            if (!testMode) window.location.href = "/login/"

          }
        })
        .catch((err) => {
          if (!testMode) {
            if (localStorage.getItem("session")) {
              localStorage.removeItem("session")
            }
            window.location.href = "/login/"
          }
        })
    } else {
      if (!testMode) window.location.href = "/login/"
    }

    getCLS(console.log)
    getFID(console.log)
    getLCP(console.log)
    getTTFB(console.log)

  }, [])
  return (
    <div className="App">
      <CheckVersion />
      <Header />
      <AppContainer>
        <aside className="Aside_Menu">
          <Menu />
        </aside>
        <AppBody className="Body">
          <RoutesAdmin />
        </AppBody>
      </AppContainer>
    </div>
  );
}

export default App;
