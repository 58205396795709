import {
    SET_BREADCUMB,
    SET_ORDERS_FILTER,
    SET_CALENDAR,
    SET_ORDER_DATA,
    SET_ORDER_LIST_TO_UPDATE,
    DELETE_ORDER_LIST_TO_UPDATE,
    FETCH_LOADING_STATUS,
    SET_OPTIONS_LIST_TO_UPDATE,
    FETCH_PAGE_PROPS
} from "./uiTypes";


let initialState = {
    breadCumb: [
        { title: "Home", link: "/", disabled: true },
    ],
    loadingStatus: {
        loading: false,
        progress: 0
    },
    pageProps: {
        titlePage: "",
    },
    orders_filter: {
        date_delivery: "",
        type_payment: "",
        state: "",
        page: "",
        keywords: "",
        viewType: "last_added", //last_added -- list in table of orders by last added, ordered_list -- list in table of orders ordered
        reload: true,
        reloadTreeOrders: true
    },
    calendar: {
        month: "",
        date: "",
        loaded: false
    },
    orderData: {
        name: "",
        last_name: "",
        id: "",
        email: '',
        cell: "",
        tel: "",
        address: "",
        city: "",
        neighborhood: "",
        commune: "",
        idDirection: "",
        point_reference: "",
        date: "",
        hour: "",
        minute: "",
        am_pm: "",
        payment_method: ""
    },
    ordersToUpdate: {
        orders: [
        ],
        update: false,
        delivery_man_id: null,
        route_id: null,
        state: 0
    },
    error: null
}



const uiReducer = (state = initialState, action) => {

    // function DeleteElement(index, state) {

    //     console.log(state)

    //     const NewOrderList =

    //     return NewOrderList

    // }

    switch (action.type) {
        case SET_BREADCUMB:
            return {
                ...state,
                breadCumb: [...action.payload]
            }

        case SET_ORDERS_FILTER:

            return {
                ...state,
                orders_filter: {
                    ...state.orders_filter,
                    ...action.payload
                }
            }


        case SET_CALENDAR:
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    ...action.payload
                }
            }

        case SET_ORDER_DATA:
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    ...action.payload
                }
            }

        case SET_OPTIONS_LIST_TO_UPDATE:
            return {
                ...state,
                ordersToUpdate: {
                    ...state.ordersToUpdate,
                    ...action.payload
                }
            }

        case SET_ORDER_LIST_TO_UPDATE:
            return {
                ...state,
                ordersToUpdate: {
                    ...state.ordersToUpdate,
                    orders: [
                        ...state.ordersToUpdate.orders,
                        action.payload
                    ],
                }
            }

        case DELETE_ORDER_LIST_TO_UPDATE:

            return {
                ...state,
                ordersToUpdate: {
                    ...state.ordersToUpdate,
                    orders: [
                        ...action.payload
                    ]
                }
            }

        case FETCH_LOADING_STATUS:

            return {
                ...state,
                loadingStatus: {
                    ...action.payload
                }
            }
        case FETCH_PAGE_PROPS:

            return {
                ...state,
                pageProps: {
                    ...action.payload
                }
            }
        default:
            return state
    }
}

export default uiReducer