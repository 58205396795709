import { ResponsiveBar } from '@nivo/bar'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { fetchBreadcumb, fetchPageProps } from '../../../../Redux'
import BreadcrumCmnt from '../../../BreadcrumCmnt'
import { ProductStatsContainer } from '../../Products/ProductStats'
import FilterPurchasesUbicationChart from './Filter/FilterPurchasesUbicationsChart'

const ProductStatsUbications = styled.section`
    padding: 5vh 0px;
    .ant-breadcrumb{
        margin-bottom: 5vh;
    }
`


export default function PurchasesUbicationsChart() {
    const [data, setData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(fetchPageProps({ titlePage: "Estadísticas - Número de Compras por Ubicaciones" }))
        const breadCrumb = [
            { title: "Compras", link: "/admin/product_list/", disabled: true },
            { title: "Estadísticas - Número de Compras por Ubicaciones", link: "/admin/product_list/", disabled: true }
        ]

        dispatch(fetchBreadcumb(breadCrumb))
    }, [])

    return (
        <ProductStatsUbications>
            <BreadcrumCmnt />
            <FilterPurchasesUbicationChart setData={setData} />
            <ProductStatsContainer style={{ height: "700vh", marginLeft: "12vw" }}>


                <ResponsiveBar
                    data={data}
                    keys={['cantidad']}
                    indexBy="ubicacion"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'nivo' }}
                    layout="horizontal"
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'fries'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'sandwich'
                            },
                            id: 'lines'
                        }
                    ]}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 40,
                        legend: 'product',
                        legendPosition: 'middle',
                        legendOffset: 80
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Número',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
            </ProductStatsContainer>

        </ProductStatsUbications>
    )
}
