import styled from "styled-components"


const PresentationsContainer = styled.div`
    margin-left: 5px;
    #components-dropdown-demo-placement .ant-btn {
        margin-right: 8px;
        margin-bottom: 8px;
    }
    .ant-row-rtl #components-dropdown-demo-placement .ant-btn {
        margin-right: 0;
        margin-bottom: 8px;
        margin-left: 8px;
    }
`

const ProductsContainer = styled.section`
    width: 90vw;
    padding-top: 5vh;
    .Form-body{
        width: 90vw;
        background-color: white !important;
    }
    .dropdown-presentations{
        height: 100%;
    }
`

const ProductItemContainer = styled.section`
        border: 1px solid #7777772b;
    .Form-body-section1-food-body{
        display: flex;
        flex-direction: column;
    }
    .meat_section_options{
        display: flex;
        align-items: center;
        .ant-btn{
            margin-left: 10px;
            color: white;
        }
    }
    .order_controls{
        display:flex;
        flex-direction: column;
        margin-right: 10px;
        .ant-btn{
            width: 22px;
            height: 22px;
            display: flex;
            padding:2px;
            color: #0064b7;
        }
        .ant-btn:disabled{
            color: #707070;
        }
    }
`

const ProductItemOptionsContainer = styled.section`
    justify-content: flex-end;
    margin-top: 3px;
    margin-bottom: 15px;
    .btn_product_controls{
        border: none;
        border-radius: 2px;
        color: white;
        font-size: 14px;
        cursor: not-allowed;
        display:flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 600px) {
            width: 33vw;
            margin-top: 2vh;
            padding: 10px;
        }
    }
    .delete_product{
        background-color: #b71c1c;
        margin-right: 5px;
        width: 10.8vw;
    }
    .update_product {
        background-color: #9e9e9e;
        width: 11.8vw;

    }
    .actived {
        background-color: #0091ea;
        cursor: pointer;
        transition: all 0.5s;
    }
    .actived:hover {
        background-color: #0064b7;
    }
    .loading {
        background-color: #ffc400;
    }
    .loaded {
        background-color: #00c853;
    }
`

export { PresentationsContainer, ProductsContainer, ProductItemContainer, ProductItemOptionsContainer }
