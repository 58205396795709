import React, { useState } from 'react'
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bump
import { ResponsiveLine } from '@nivo/line'
import FilterPurchasesTimeline from './Filter/FilterPurchasesTimeline'
import styled from 'styled-components'
import BreadcrumCmnt from '../../../BreadcrumCmnt'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { fetchBreadcumb, fetchPageProps } from '../../../../Redux'

const PurchasesTimeLineFilterContainer = styled.section`
    padding: 5vh 0vw;
    .ant-breadcrumb{
        margin-bottom: 5vh;
    }
    svg{
        overflow: inherit;
    }

    text[transform="translate(0,10) rotate(70)"]{
        font-weight: 600;
        font-size: 16px;
    }
`

export default function PurchasesTimeLine() {

    const dispatch = useDispatch()
    const [data, setData] = useState([
        {
            "id": "Compras",
            "color": "hsl(327, 70%, 50%)",
            "data": [{"x":"example","y":0},{"x":"example2","y":1}]
        },
    ])

    useEffect(() => {

        dispatch(fetchPageProps({ titlePage: "Estadísticas - Número de Compras" }))
        const breadCrumb = [
          { title: "Compras", link: "/admin/date_availability/", disabled: true },
          { title: "Estadísticas - Número de Compras", link: "/admin/date_availability/", disabled: true }
        ]
        dispatch(fetchBreadcumb(breadCrumb))
    
      }, [])
    
    return (

        <PurchasesTimeLineFilterContainer>
        
            <BreadcrumCmnt />

            <FilterPurchasesTimeline setData={setData} />

            <div style={{ height: "50vh" }}>
                <ResponsiveLine
                    data={data}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 70,
                        legend: '',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Número de compras',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    colors={{ scheme: 'category10' }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>

        </PurchasesTimeLineFilterContainer>
    )

}