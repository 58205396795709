import React from 'react'

//Assets

import LogoDown from './../../../../Assets/LogoDown.svg'
import Presentations from './Presentations'

import { ProductItemContainer, ProductItemOptionsContainer } from '../Styles/Styles'

import { Select, Switch, Popconfirm, Button } from 'antd';
import { InteractionOutlined, DeleteOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import 'antd/lib/switch/style/css'
import 'antd/lib/button/style/css'
import 'antd/lib/popconfirm/style/css'

const { Option } = Select;

export default function ProductItem({
    meatSection,
    meatName,
    sectionTitle,
    setSectionsCollapseStatus,
    sectionsCollapseStatus,
    ChangeButtonStatus,
    UpdateProduct,
    UpdatePresentation,
    DeleteProduct,
    ChangeProductStatus,
    ChangeProductUnit,
    ChangeName,
    ChangeOfferStatus,
    UpdateItem,
    ChangeOrderInList,
    UpdateSection
}) {

    return (
        <ProductItemContainer className={"Form-body-section1-food " + meatName} style={{ marginTop: 3 + "vh" }}>

            <header title="Mostrar todo" className="Form-body-section1-food-header" onClick={() => setSectionsCollapseStatus({
                ...sectionsCollapseStatus,
                [`${meatName}`]: sectionsCollapseStatus[`${meatName}`] === "collapsed" ? "open" : 'collapsed'
            })}>

                <div className="meat_section_options">
                    <p>{sectionTitle}</p>
                    <Button type="ghost" onClick={(e) => UpdateSection(meatName)}>Actualizar Sección</Button>
                </div>


                <img title="Mostrar todo" className={"logo-collapse logo-" + sectionsCollapseStatus[`${meatName}`]} src={LogoDown} alt="Imagen no disponible"
                    onClick={() => setSectionsCollapseStatus({
                        ...sectionsCollapseStatus,
                        [`${meatName}`]: sectionsCollapseStatus[`${meatName}`] === "collapsed" ? "open" : 'collapsed'
                    })} />

            </header>

            <section className={"Form-body-section1-food-body " + sectionsCollapseStatus[`${meatName}`]} id={"section-" + meatName}>
                {/* <header className="Form-body-section1-food-body-header">
                    <p></p>
                    <p>Precio</p>
                    <p>Presentaciones</p>
                </header> */}
                {
                    meatSection.length === 0 ? "Cargando..." :
                        sectionsCollapseStatus[`${meatName}`] !== "collapsed" ?
                            meatSection.map((item, index) =>

                                <section style={{ order: item.order_list }}>
                                    <div className="Form-body-section1-food-body-item">
                                        <div className="item-section1 flex">

                                            <div className="order_controls">
                                                <Button icon={<CaretUpOutlined />} onClick={() => {
                                                    ChangeOrderInList(meatName, index, Number(item.order_list) - 1, "up")
                                                }}
                                                    disabled={item.order_list === meatSection[0].order_list}
                                                />
                                                <Button icon={<CaretDownOutlined />} onClick={() => {
                                                    ChangeOrderInList(meatName, index, Number(item.order_list) + 1, "down")
                                                }}
                                                    disabled={item.order_list === meatSection[meatSection.length - 1].order_list}
                                                />
                                            </div>


                                            <p>{item.plu + " -"} </p>

                                            <input type="text" className="input_style1" id={"name-" + meatName + item.id} placeholder={item.descripcion}
                                                onChange={(e) => {
                                                    ChangeButtonStatus(e.target.value, "Update-" + meatName + item.id)
                                                    ChangeName(e.target.value, meatName, index)
                                                }} />

                                            <p>
                                                &nbsp;- <b>$ &nbsp;</b>
                                            </p>
                                            <input type="number" className={"Quantities-" + meatName} id={"Quantity-" + meatName + "-" + item.id} min="0"
                                                onChange={
                                                    (e) => {
                                                        ChangeButtonStatus(e.target.value, "Update-" + meatName + item.id);
                                                        UpdateItem(e.target.value, meatName, index, "precio_publico")
                                                    }}
                                                placeholder={item.precio_publico} />

                                            <Select id={"unit-" + meatName + item.id} defaultValue={item.unidad_medida} style={{ width: 120, marginLeft: "5px" }}
                                                onChange={(e) => {
                                                    ChangeButtonStatus(e, "Update-" + meatName + item.id)
                                                    ChangeProductUnit(e, meatName, index)
                                                }}>
                                                <Option value="LIBRA">LIBRA</Option>
                                                <Option value="UNIDAD">UNIDAD</Option>
                                                <Option value="KILO">KILO</Option>
                                            </Select>

                                            <Presentations
                                                key={index}
                                                presentations={
                                                    item.presentacion !== "" ?
                                                        typeof item.presentacion === "string" ? JSON.parse(decodeURI(item.presentacion)) : item.presentacion
                                                        : null}
                                                UpdatePresentation={UpdatePresentation}
                                                itemId={item.id}
                                                meatName={meatName}
                                                indexProduct={index}
                                            />
                                            <input type="number" className={"Offer_price-" + meatName} id={"Offer_price-" + meatName + "-" + item.id} min="0"
                                                onChange={(e) => {
                                                    ChangeButtonStatus(e.target.value, "Update-" + meatName + item.id)
                                                    UpdateItem(e.target.value, meatName, index, "offer_price")
                                                }} placeholder={item.offer_price}

                                                style={{ display: item.offer === "1" ? "initial" : "none", marginLeft: "4px", borderColor: "#00c853" }}

                                            />
                                            <Switch checkedChildren="En oferta" unCheckedChildren="Oferta off" checked={item.offer === "1" ? true : false} id={"Offer-" + meatName + item.id} style={{ marginLeft: "auto" }}
                                                onChange={(e) => {
                                                    ChangeButtonStatus(e, "Update-" + meatName + item.id)
                                                    ChangeOfferStatus(e, meatName, index)
                                                }} />
                                            <Switch checkedChildren="Activo" unCheckedChildren="Desactivado" checked={item.formulario === "true" ? true : false} id={"Status-" + meatName + item.id} style={{ marginLeft: "auto" }}
                                                onChange={(e) => {
                                                    ChangeButtonStatus(e, "Update-" + meatName + item.id)
                                                    ChangeProductStatus(e, meatName, index)
                                                }} />

                                        </div>

                                        <div className="item-section2">

                                        </div>

                                    </div>
                                    <ProductItemOptionsContainer className="flex">
                                        <Popconfirm placement="bottom" title={"¿Desea eliminar el producto?"}
                                            onConfirm={(e) =>
                                                DeleteProduct(e, item.id)
                                            } okText="Si, eliminar" cancelText="Cancelar">
                                            <button id={"Delete-" + meatName + item.id} className="delete_product btn_product_controls" style={{ cursor: "pointer" }}>
                                                <DeleteOutlined style={{ fontSize: "16px" }} />
                                                Eliminar producto
                                            </button>
                                        </Popconfirm>
                                        <button id={"Update-" + meatName + item.id} className="update_product btn_product_controls" data-status="disabled"
                                            onClick={(e) => e.target.dataset.status === "disabled" ? null : UpdateProduct(e, item.id, "Quantity-" + meatName + "-" + item.id, meatName, index, item.precio_publico)}>
                                            <InteractionOutlined style={{ fontSize: "16px" }} />
                                            <span id={"Update_text-" + meatName + item.id}>Actualizar producto</span>
                                        </button>
                                    </ProductItemOptionsContainer>
                                </section>
                            )
                            : null

                }

            </section>
        </ProductItemContainer>
    )
}
