import React, { useEffect } from 'react'
import { Tooltip,  Button, message } from 'antd'



import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { StateBtn } from '../../DeliveryStyles/DeliveryStyles'

const TableOrdersBodyItemRowContainer = styled.div`
    justify-content: flex-start !important;
    padding: 10px 5px;
    margin-top: 8px;
    border: 1px solid #7070702b;
    background: white;
    cursor: pointer;
    transition: background 0.5s, margin 0.2s;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: black;
    .btnOrderStatus{
        height: 30px !important;
    }
    .position{
        font-size: 16px;
        font-weight: 600;
        font-style: italic;
    }
    .flex{
        /* justify-content: flex-start !important; */
        p{
        text-align:center;
        text-overflow: ellipsis;
        /* overflow: hidden; */
        color: #616161;
        margin:0;
        justify-content: center;
        }
    }
    .ListOrder_IntputTotal{
        border: none;
        border-bottom: 1px solid #7070702b;
        background-color: transparent;
    }
    .InputModified{
        box-shadow: 0px 0px 1px #64dd17;
        border: 2px solid #64dd17;
    }
    :nth-child(1n){
        background: #eceff1;
    }
    :nth-child(2n){
        background: white;
    }
    :hover{
        background: #babdbe;
    }
    @media(max-width: 600px){
        .ant-btn{
            padding: 2px !important;
        }
    }
`

const TableOrdersBodyControlsRowContainer = styled.section`
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    .ant-btn{
        padding: 0px 10px;
        height: 24px;
    }
`

export default function TableOrdersBodyItemRow({ route, cells, index, user_id }) {




    const update_order = (type) => {

        message.info("Actualizando...", "actualizando")

        

    }

    const clickToLink = (event, id) => {
        if (event.target.className === "flex" || event.target.className === "item") {
            document.getElementById(id).click()
        }
    }
    //Use Effects

    useEffect(() => {



    }, [route])

    // Set item data

    return (

        <div>

            <TableOrdersBodyItemRowContainer key={route.id} onClick={(e) => { clickToLink(e, "link" + route.id) }}>

                {/* <TableOrdersBodyItemRow key={route.id} > */}

                <div className="flex">

                    <Link to={"/admin/delivery_route/" + user_id + "/" + route.id + "/"} style={{ display: "none" }} id={"link" + route.id}></Link>


                    {
                        cells.id.visible ?
                            <p style={{ width: cells.id.width }} className="flex" id={"id" + route.id} >

                                <Tooltip placement="top" title={"Copiar"}>

                                    <b>{route.id}</b>

                                </Tooltip>

                            </p>
                            : null
                    }

                    {

                        cells.date_creation.visible ?

                            <p style={{ width: cells.date_creation.width }} className="flex">

                                {

                                    route.date_creation

                                }

                            </p>
                            : null

                    }
                    {

                        cells.date_delivered.visible ?

                            <p style={{ width: cells.date_delivered.width }} className="flex">

                                {

                                    route.date_delivered

                                }

                            </p>
                            : null

                    }
                    {
                        cells.state.visible ?
                            <p style={{ width: cells.state.width }} className="flex">
                                <StateBtn width={100} state={route.state}>
                                    {route.state}
                                </StateBtn>
                            </p>
                            : null
                    }
                </div>

            </TableOrdersBodyItemRowContainer>

            <TableOrdersBodyControlsRowContainer>
                <div>
                    <Button type="primary" onClick={(e) => document.getElementById("link" + route.id).click()}>Ver</Button>
                </div>
            </TableOrdersBodyControlsRowContainer>
        </div>
    )
}
